import { useEffect } from 'react'
import { POSTHOG_EVENTS } from '../../analytics/posthog/posthog-events.ts'
import { BigCard } from '../design-system/big-card.tsx'
import { ExerciseCard } from './exercise-card.tsx'
import { StreakCards } from './streak-cards'
import { Link } from 'react-router-dom'
import { ROUTE_PATHS } from '../../routing/route-paths.ts'
import { BookA, Dice5, FileText, Layers } from 'lucide-react'
import { StreakProgressBar } from './streak-progress-bar.tsx'
import { EXTERNAL_LINKS } from '../../constants/external-links.ts'
import { ExercisesList } from './exercises-list.tsx'
import { useSelector } from 'react-redux'
import { selectStudyLanguageOrEnglish } from '../../state/slices/account-slice.ts'
import { langCodeToLanguageName } from '../../constants/lang-code-utils.ts'
import { t } from '../../i18n/translate'

const DashboardDesktop = () => {
  const studyLanguage = useSelector(selectStudyLanguageOrEnglish)

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const NUMBER_OF_ROWS = 2
  const GAP_BETWEEN_ROWS = 6
  const EXERCISE_CARD_HEIGHT = 60
  const totalGapHeight = (NUMBER_OF_ROWS - 1) * GAP_BETWEEN_ROWS
  const totalCardHeight = NUMBER_OF_ROWS * EXERCISE_CARD_HEIGHT
  // We need to divide by 4 to go from tailwind units to rems
  const totalGridHeightInRems = (totalCardHeight + totalGapHeight) / 4

  return (
    <div className='hidden w-full flex-col items-center p-2 py-4 md:container lg:flex 3xl:py-12'>
      <BigCard className='flex flex-col items-start gap-2 md:gap-y-6'>
        <div className='grid w-full grid-cols-1 gap-6 md:grid-cols-[1fr,1fr,1px,1fr]'>
          <div className='col-span-2 md:col-span-3'>
            <h1 className='text-3xl text-[32px] font-semibold text-gray-800'>{t('dashboard.exercises.title')}</h1>
            <p className='mt-2 text-slate-400'>{t('dashboard.exercises.subtitle')}</p>
          </div>
          <div>
            <h2 className='text-3xl font-semibold text-gray-800'>{t('dashboard.streak.title')}</h2>
            <p className='mt-2 text-slate-400'>{t('dashboard.streak.subtitle')}</p>
          </div>
          {/* First Row */}
          <Link to={ROUTE_PATHS.BEGINNER_LEVEL_EXERCISE} className='w-full'>
            <ExerciseCard
              name={t('dashboard.exercises.beginner.title').replace(
                '{language}',
                langCodeToLanguageName(studyLanguage)
              )}
              description={t('dashboard.exercises.beginner.description')}
              icon={BookA}
              isMainExercise={true}
            />
          </Link>
          <Link to={ROUTE_PATHS.FREQUENCY_LIST_EXERCISE} className='w-full'>
            <ExerciseCard
              name={t('dashboard.exercises.levelBased.title')}
              description={t('dashboard.exercises.levelBased.description')}
              icon={Layers}
            />
          </Link>
          <div className='relative'>
            <VerticalDashedLine
              className='absolute right-0 top-0 text-slate-100'
              width={2}
              dashLength={16}
              gapLength={15}
              height={`${totalGridHeightInRems}rem`}
            />
          </div>
          <div className='row-span-2 flex flex-col'>
            <StreakCards />
            <StreakProgressBar />
            <HorizontalDashedLine className='mt-6 w-full text-slate-100' height={2} dashLength={15} gapLength={15} />
            <div className='flex flex-col gap-y-6 py-6'>
              <Link
                to={ROUTE_PATHS.PROGRESS_STATS_SAVED_WORDS}
                className='h-14 w-full rounded-2xl border p-4 text-center font-medium text-slate-800 hover:bg-slate-50'
              >
                {t('dashboard.savedWords.button')}
              </Link>
              <Link
                to={EXTERNAL_LINKS.BETA_VERSION_FEEDBACK_FORM_IN_ENGLISH}
                className='h-14 w-full rounded-2xl border p-4 text-center font-medium text-slate-800 hover:bg-slate-50'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('dashboard.feedback.button')}
              </Link>
            </div>
          </div>
          {/* Second Row */}
          <Link to={ROUTE_PATHS.RANDOM_EXERCISE} className='w-full'>
            <ExerciseCard
              name={t('dashboard.exercises.random.title')}
              description={t('dashboard.exercises.random.description')}
              icon={Dice5}
            />
          </Link>
          <Link to={ROUTE_PATHS.CUSTOM_EXERCISE} className='w-full'>
            <ExerciseCard
              name={t('dashboard.exercises.custom.title')}
              description={t('dashboard.exercises.custom.description')}
              icon={FileText}
            />
          </Link>
        </div>
      </BigCard>
    </div>
  )
}

const DashboardMobile = () => {
  return (
    <div className='flex w-full flex-col items-center gap-2 p-2 py-4 lg:hidden'>
      <BigCard className='flex flex-col items-start gap-1 rounded-2xl py-2'>
        <h1 className='mb-1 mt-2 w-full text-center text-base font-semibold text-gray-800'>
          {t('dashboard.mobile.exercises.title')}
        </h1>
        <div className={'max-h-[1000px] w-full overflow-hidden transition-all duration-300 ease-in-out'}>
          <ExercisesList />
        </div>
      </BigCard>
      <BigCard className='flex flex-col items-start gap-1 rounded-2xl py-2'>
        <h1 className='mb-1 mt-2 w-full text-center text-base font-semibold text-gray-800'>
          {t('dashboard.mobile.streak.title')}
        </h1>
        <StreakCards />
        <StreakProgressBar />
        <HorizontalDashedLine className='mb-1 mt-1 w-full text-slate-100' height={2} dashLength={15} gapLength={15} />
        <div className='flex w-full flex-row gap-1'>
          <Link
            to={ROUTE_PATHS.PROGRESS_STATS_SAVED_WORDS}
            className='flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white px-4 text-center text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50'
          >
            {t('dashboard.savedWords.button')}
          </Link>
          <Link
            to={EXTERNAL_LINKS.BETA_VERSION_FEEDBACK_FORM_IN_ENGLISH}
            className='flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white px-4 text-center text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('dashboard.feedback.button')}
          </Link>
        </div>
      </BigCard>
    </div>
  )
}

export const DashboardView = () => {
  return (
    <>
      <DashboardDesktop />
      <DashboardMobile />
    </>
  )
}

const HorizontalDashedLine = ({
  className = 'w-full',
  height = 1,
  dashLength = 5,
  gapLength = 3,
  color = 'currentColor',
}) => {
  const dashArray = `${dashLength} ${gapLength}`

  return (
    <div className={`${className} flex items-center`}>
      <svg width='100%' height={height} className='overflow-visible'>
        <line
          x1='0'
          y1={height / 2}
          x2='100%'
          y2={height / 2}
          stroke={color}
          strokeWidth={height}
          strokeDasharray={dashArray}
          strokeLinecap='round'
        />
      </svg>
    </div>
  )
}

const VerticalDashedLine = ({
  className = 'h-full',
  width = 1,
  dashLength = 5,
  gapLength = 3,
  color = 'currentColor',
  height = '100%',
}) => {
  const dashArray = `${dashLength} ${gapLength}`

  return (
    <div className={`${className} inline-flex justify-center`} style={{ height }}>
      <svg height='100%' width={width} className='overflow-visible'>
        <line
          x1={width / 2}
          y1='0'
          x2={width / 2}
          y2='100%'
          stroke={color}
          strokeWidth={width}
          strokeDasharray={dashArray}
          strokeLinecap='round'
        />
      </svg>
    </div>
  )
}
