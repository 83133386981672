import { AudioRecorderState, useAudioRecorder } from '../../../../hooks/use-audio-recorder.ts'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountActions,
  selectAccountAccessToken,
  selectHasVoice,
  selectMotherLanguageOrEnglish,
} from '../../../../state/slices/account-slice.ts'
import { modalActions } from '../../../../state/slices/modal-slice.ts'
import { useMutation } from '@tanstack/react-query'
import {
  AUDIO_TOO_SHORT_FOR_CLONING_MODAL_ID,
  AUDIO_WITH_NOT_ENOUGH_WORDS_FOR_CLONING_MODAL_ID,
  SOMETHING_WENT_WRONG_MODAL_ID,
} from '../../../modal/modal-ids.ts'
import { LANG_TO_TEXT_FOR_CLONING } from './lang-to-text-for-cloning.ts'
import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { AudioRecorder } from '../../../audio-recorder.tsx'
import { getBlobDuration } from '../../../exercises/exercise/audio-player/audio-player-utils.ts'
import { cloneVoice } from '../../../../transport/transport/our-backend/api/users/users.ts'
import { ApiError } from '../../../../transport/transport/utils.ts'
import { NavigationButton } from '../navigation-button.tsx'
import { TextGenerateEffect } from './text-generate-effect.tsx'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'
import { ROUTE_PATHS } from '../../../../routing/route-paths.ts'
import { useNavigate } from 'react-router-dom'
import { getConfig } from '../../../../config/environment-config.ts'
import { SquaresLoader } from '../../../loader/squares-loader.tsx'

const MIN_LENGTH_OF_AUDIO_FOR_CLONING_IN_SECONDS: number = 20

export const CloneVoiceView = () => {
  const dispatch = useDispatch()
  const accessToken: string = useSelector(selectAccountAccessToken)
  const motherLanguage: LangCode | null = useSelector(selectMotherLanguageOrEnglish)
  const hasVoice: boolean = useSelector(selectHasVoice)
  const navigate = useNavigate()
  const { isRecording, handleStartStopRecording, recordedAudioBlob, resetAudioRecorder }: AudioRecorderState =
    useAudioRecorder()
  const [duration, setDuration] = useState<number | null>(null)

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  useEffect(() => {
    if (recordedAudioBlob) {
      getBlobDuration(recordedAudioBlob).then((duration: number) => {
        setDuration(duration)
      })
    }
  }, [recordedAudioBlob])

  useEffect(() => {
    if (
      duration &&
      duration < MIN_LENGTH_OF_AUDIO_FOR_CLONING_IN_SECONDS &&
      !getConfig().shouldSkipVoiceCloningLengthCheck
    ) {
      dispatch(modalActions.openModal(AUDIO_TOO_SHORT_FOR_CLONING_MODAL_ID))
      resetAudioRecorder()
      setDuration(null)
    }
  }, [duration, dispatch, resetAudioRecorder])

  const cloneVoiceMutation = useMutation({
    mutationFn: () => cloneVoice(motherLanguage as LangCode, accessToken, recordedAudioBlob as Blob),
    onSuccess: (data) => {
      if (data?.data?.hasVoice) {
        dispatch(accountActions.setHasVoice())
        navigate(ROUTE_PATHS.ONBOARDING_SUCCESS)
      }
    },
    onError: (error: ApiError) => {
      if (error.status === 400) {
        resetAudioRecorder()
        setDuration(null)
        dispatch(modalActions.openModal(AUDIO_WITH_NOT_ENOUGH_WORDS_FOR_CLONING_MODAL_ID))
        // todo: this should not be handled here, we already have a generic method for this
      } else if (error.status === 403) {
        navigate(ROUTE_PATHS.PRICING)
      } else {
        dispatch(modalActions.openModal(SOMETHING_WENT_WRONG_MODAL_ID))
      }
    },
  })

  useEffect(() => {
    if (
      recordedAudioBlob !== null &&
      !!motherLanguage &&
      !hasVoice &&
      !!duration &&
      (getConfig().shouldSkipVoiceCloningLengthCheck || MIN_LENGTH_OF_AUDIO_FOR_CLONING_IN_SECONDS <= duration)
    ) {
      cloneVoiceMutation.mutate()
    }
  }, [recordedAudioBlob, motherLanguage, hasVoice, duration])

  const onPreviousClick = () => {
    resetAudioRecorder()
    dispatch(accountActions.setHasAcceptedTermsAndConditionsAndClickedNext(false))
    navigate(ROUTE_PATHS.ONBOARDING_TERMS_AND_CONDITIONS)
  }

  const textForCloning = LANG_TO_TEXT_FOR_CLONING[motherLanguage]

  const { firstWords, restOfWords } = useMemo(() => {
    const words = textForCloning.split(' ')
    // Thai uses spaces between phrases and sentences and not between words, so their "words" are very long
    const delimiter = motherLanguage === LangCode.THAI ? 2 : 5
    return {
      firstWords: words.slice(0, delimiter).join(' '),
      restOfWords: words.slice(delimiter),
    }
  }, [textForCloning])

  return (
    <div className='mb-16 mt-5 flex w-full flex-col items-center p-4 md:w-1/2 xl:w-1/3 2xl:w-1/4'>
      {cloneVoiceMutation.isPending ? (
        <div className='flex h-full w-full flex-col items-center justify-center gap-10 text-2xl'>
          <h1 className='text-center text-3xl font-bold text-gray-700'>Cloning your voice...</h1>
          <SquaresLoader />
        </div>
      ) : (
        <div className='flex flex-grow flex-col p-2'>
          <h1 className='mt-8 text-center text-4xl font-bold tracking-tighter'>Record the text slowly:</h1>
          <div>
            <AudioRecorder isRecording={isRecording} handleStartStopRecording={handleStartStopRecording} />
          </div>
          <div className='text-center text-2xl'>
            <span className='inline'>{firstWords} </span>
            {isRecording && <TextGenerateEffect words={restOfWords} className='inline' />}
          </div>
        </div>
      )}
      {!isRecording && !cloneVoiceMutation.isPending && (
        <div className='fixed bottom-0 flex w-full flex-col items-center bg-gray-50 p-4 md:max-w-2xl md:px-2 lg:max-w-xl'>
          <NavigationButton onClick={onPreviousClick}>Previous</NavigationButton>
        </div>
      )}
    </div>
  )
}
