import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '../../routing/route-paths.ts'
import { useEffect } from 'react'
import { selectIsSignedIn } from '../../state/slices/account-slice.ts'
import { useSelector } from 'react-redux'

export const AccountRemovedSuccessView = () => {
  const navigate = useNavigate()
  const isSignedIn: boolean = useSelector(selectIsSignedIn)

  useEffect(() => {
    if (isSignedIn) {
      navigate(ROUTE_PATHS.DASHBOARD)
    }
  }, [isSignedIn])

  const handleTakeToSignIn = () => {
    navigate(ROUTE_PATHS.SIGN_UP, { replace: true })
  }

  return (
    <div className='mb-40 flex h-full w-full flex-col items-center justify-center space-y-4 px-4 py-12 text-center sm:px-6 md:max-w-sm'>
      <div className='space-y-2'>
        <h1 className='text-3xl font-bold text-gray-800'>Account Removed</h1>
      </div>
      <p className='text-gray-600'>We're sorry to see you go. Your account has been successfully removed.</p>
      <div className='w-full space-y-4'>
        <div className='mt-4 text-sm text-gray-500'>
          If you'd like to create a new account, you can{' '}
          <a className='cursor-pointer underline' onClick={handleTakeToSignIn}>
            sign up here
          </a>
          .
        </div>
      </div>
    </div>
  )
}
