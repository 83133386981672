import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { QUERY_KEYS } from '../../../../transport/transport/our-backend/api/query-keys.ts'
import {
  selectAccountAccessToken,
  selectMotherLanguageOrEnglish,
  selectStudyLanguageOrEnglish,
} from '../../../../state/slices/account-slice.ts'
import { LangCode, SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { TranslateTextResponse } from '@shared/frontend-and-backend/body-types/translation/translation.types.ts'
import { getTextTranslation } from '../../../../transport/transport/our-backend/api/translation/translation.ts'
import { useApiErrorHandler } from '../../../../hooks/use-api-error-handler.ts'
import { TranslateTextModalContentCommon } from './translate-text-modal-content-common.tsx'

export const TranslateTextModalContent = () => {
  const queryClient = useQueryClient()
  const accessToken: string = useSelector(selectAccountAccessToken)
  const studyLanguage: SupportedStudyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const motherLanguage: LangCode = useSelector(selectMotherLanguageOrEnglish)

  const { data: currentExerciseText, isFetching: isFetchingExerciseText } = useQuery<string>({
    queryKey: [QUERY_KEYS.EXERCISE_TEXT],
    queryFn: () => queryClient.getQueryData<string>([QUERY_KEYS.EXERCISE_TEXT]) ?? '',
    enabled: true,
  })

  const expectedText = currentExerciseText ?? ''

  const {
    data: textTranslationData,
    isFetching: isFetchingTextTranslation,
    error: textTranslationError,
  } = useQuery<ResponseWrapper<TranslateTextResponse>>({
    // we do not need to include accessToken in the query key
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.TRANSLATE_TEXT, expectedText, motherLanguage, studyLanguage],
    queryFn: () => getTextTranslation(expectedText, studyLanguage, motherLanguage, accessToken),
    enabled: !!expectedText && !!accessToken,
  })

  useApiErrorHandler(
    textTranslationError,
    `Error translating text: ${expectedText}, ${motherLanguage}, ${studyLanguage}, ${textTranslationError}`
  )

  const translatedText: string = textTranslationData?.data?.translation ?? ''

  return (
    <TranslateTextModalContentCommon
      expectedText={expectedText}
      translatedText={translatedText}
      isFetchingExerciseText={isFetchingExerciseText}
      isFetchingTextTranslation={isFetchingTextTranslation}
    />
  )
}
