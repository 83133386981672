import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { POSTHOG_EVENTS } from '../../../analytics/posthog/posthog-events.ts'
import { useApiErrorHandler } from '../../../hooks/use-api-error-handler.ts'
import {
  selectAccountAccessToken,
  selectDialectOrDefaultDialectOrEnglishDefaultDialect,
  selectStudyLanguageOrEnglish,
} from '../../../state/slices/account-slice.ts'
import { getSentenceFromFrequencyList } from '../../../transport/transport/our-backend/api/pronunciation/pronunciation.ts'
import { QUERY_KEYS } from '../../../transport/transport/our-backend/api/query-keys.ts'
import { Exercise } from '../exercise/exercise.tsx'
import { TextForExercise } from '../exercise/text-for-exercise.tsx'
import { BigCard } from '../../design-system/big-card.tsx'
import { A1_UPPER_BOUND_EXCLUDED } from '../exercise/controls/atoms/cefr-level-selector-utils.ts'
import { BeginnerLevelExerciseControls } from '../exercise/controls/beginner-level-exercise-controls.tsx'
import { ExerciseSkeleton } from '../exercise/exercise-skeleton.tsx'
import { GetExerciseResponse } from '@shared/frontend-and-backend/body-types/exercise/exercise.types.ts'
import { t } from '../../../i18n/translate'

export const BeginnerLevelExerciseView = () => {
  const accessToken: string = useSelector(selectAccountAccessToken)
  const studyLanguage: LangCode = useSelector(selectStudyLanguageOrEnglish)
  const dialect = useSelector(selectDialectOrDefaultDialectOrEnglishDefaultDialect)
  const queryClient = useQueryClient()

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const {
    data: textData,
    isFetching: isFetchingText,
    refetch: refetchText,
    error: textError,
  }: UseQueryResult<ResponseWrapper<GetExerciseResponse>> = useQuery({
    queryKey: [QUERY_KEYS.EXERCISE_FREQUENCY_LIST, studyLanguage, accessToken, dialect],
    queryFn: () => getSentenceFromFrequencyList(studyLanguage, A1_UPPER_BOUND_EXCLUDED, accessToken),
    enabled: true,
    staleTime: 0,
  })

  const expectedText: string = textData?.data?.text ?? ''

  useEffect(() => {
    if (expectedText) {
      queryClient.setQueryData<string>([QUERY_KEYS.EXERCISE_TEXT], expectedText)
    }
  }, [expectedText, queryClient])

  const data = textData?.data
  if (data) {
    const savedWords: { word: string; language: LangCode }[] = data.wordsFromExerciseThatAreSaved
    savedWords.forEach(({ word, language }) => {
      if (word) {
        queryClient.setQueryData<boolean>([QUERY_KEYS.SAVED_WORDS, word, language], true)
      }
    })
  }

  useApiErrorHandler(
    textError,
    `Error generating text for beginner exercise: ${studyLanguage}, ${dialect}, ${textError}`
  )

  return (
    <div className='flex w-full flex-1 flex-col items-center pt-2 md:p-8 2xl:p-16'>
      <BigCard className='container flex flex-1 flex-col items-center'>
        <BeginnerLevelExerciseControls text={expectedText} />
        {isFetchingText ? (
          <ExerciseSkeleton />
        ) : (
          <Exercise
            expectedText={expectedText}
            onTryAnotherTextClick={() => {
              refetchText().then(() => {})
            }}
            textOnTryAnotherTextButton={t('exercise.button.nextSentence')}
          >
            <TextForExercise text={expectedText} studyLanguage={studyLanguage} />
          </Exercise>
        )}
      </BigCard>
    </div>
  )
}
