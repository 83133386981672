export const polishDictionary = {
  'onboarding.chooseMotherLanguage.title': 'Wybierz swój język ojczysty:',
  'onboarding.chooseMotherLanguage.subtitle': '(Zawsze możesz go zmienić później)',
  'onboarding.chooseMotherLanguage.searchPlaceholder': 'Wpisz swój język ojczysty',
  'onboarding.chooseMotherLanguage.other': 'Inny',
  'onboarding.chooseMotherLanguage.skip': 'Pomiń',
  'onboarding.chooseMotherLanguage.next': 'Dalej',
  'onboarding.chooseMotherLanguage.loading': 'Ładowanie...',

  'onboarding.chooseStudyLanguage.title': 'Wybierz język, którego chcesz się uczyć:',
  'onboarding.chooseStudyLanguage.subtitle': '(Zawsze możesz go zmienić później)',
  'onboarding.chooseStudyLanguage.previous': 'Wstecz',
  'onboarding.chooseStudyLanguage.next': 'Dalej',
  'onboarding.chooseStudyLanguage.loading': 'Ładowanie...',

  'onboarding.termsAndConditions.title': 'Przygotuj się',
  'onboarding.termsAndConditions.subtitle': 'do sklonowania głosu',
  'onboarding.termsAndConditions.removeVoiceInfo':
    'Zawsze możesz później usunąć swój sklonowany głos. Nie jest on używany przez nikogo poza aplikacją.',
  'onboarding.termsAndConditions.step1.title': 'Krok 1',
  'onboarding.termsAndConditions.step1.description':
    'W następnym kroku przeczytasz na głos krótki tekst. Dla najlepszych rezultatów upewnij się, że jesteś w cichym otoczeniu. Możesz popełniać błędy, nie wpłyną one na klonowanie głosu.',
  'onboarding.termsAndConditions.step2.title': 'Krok 2',
  'onboarding.termsAndConditions.step2.description':
    'Twój sklonowany głos zostanie zintegrowany z procesem nauki, dostosowując ćwiczenia specjalnie dla Ciebie.',
  'onboarding.termsAndConditions.consent':
    'Wyrażam zgodę na sklonowanie mojego głosu w celu spersonalizowanej nauki w ramach YourBestAccent.com i akceptuję',
  'onboarding.termsAndConditions.termsOfService': 'Regulamin',
  'onboarding.termsAndConditions.and': 'oraz',
  'onboarding.termsAndConditions.privacyPolicy': 'Politykę Prywatności',
  'onboarding.termsAndConditions.previous': 'Wstecz',
  'onboarding.termsAndConditions.next': 'Przejdź do klonowania',

  'onboarding.chooseDialect.title': 'Wybierz dialekt, którego chcesz się uczyć:',
  'onboarding.chooseDialect.subtitle': '(Zawsze możesz go zmienić później)',
  'onboarding.chooseDialect.previous': 'Wstecz',
  'onboarding.chooseDialect.next': 'Dalej',
  'onboarding.chooseDialect.loading': 'Ładowanie...',

  'auth.signIn.title': 'Zacznij doskonalić',
  'auth.signIn.subtitle': 'swoją wymowę',
  'auth.signIn.withEmail': 'Zaloguj się przez Email',
  'auth.signIn.withGoogle': 'Zaloguj się przez Google',
  'auth.signUp.withEmail': 'Zarejestruj się przez Email',
  'auth.signUp.withGoogle': 'Zarejestruj się przez Google',

  'auth.signInEmail.title': 'Zaloguj się przez Email',
  'auth.signUpEmail.title': 'Zarejestruj się przez Email',
  'auth.email.placeholder': 'Adres email',
  'auth.email.invalidError': 'Wprowadź poprawny adres email',
  'auth.email.sendingEmail': 'Wysyłanie emaila',
  'auth.email.or': 'Lub',
  'auth.email.noAccount': 'Nie masz konta?',
  'auth.email.haveAccount': 'Masz już konto?',
  'auth.email.signUp': 'Zarejestruj się',
  'auth.email.signIn': 'Zaloguj się',

  'auth.emailVerify.title': 'Zweryfikuj swój Email',
  'auth.emailVerify.verifying': 'Weryfikacja...',
  'auth.emailVerify.verify': 'Zweryfikuj',
  'auth.emailVerify.error.title': 'Link jest nieprawidłowy lub wygasł',
  'auth.emailVerify.error.returnToSignIn': 'Wróć do logowania',

  'auth.verificationSent.title': 'Email weryfikacyjny wysłany',
  'auth.verificationSent.sentTo': 'Wysłaliśmy email weryfikacyjny na adres:',
  'auth.verificationSent.checkInbox': 'Sprawdź swoją skrzynkę i kliknij w',
  'auth.verificationSent.linkToSignIn': 'link aby się zalogowa',
  'auth.verificationSent.linkToSignUp': 'link aby dokończyć rejestrację',

  'languages.english': 'angielski',
  'languages.spanish': 'hiszpański',
  'languages.german': 'niemiecki',
  'languages.french': 'francuski',
  'languages.italian': 'włoski',
  'languages.polish': 'polski',
  'languages.czech': 'czeski',
  'languages.ukrainian': 'ukraiński',
  'languages.russian': 'rosyjski',
  'languages.portuguese': 'portugalski',
  'languages.vietnamese': 'wietnamski',
  'languages.chinese': 'chiński',
  'languages.hindi': 'hindi',
  'languages.indonesian': 'indonezyjski',
  'languages.malay': 'malajski',
  'languages.japanese': 'japoński',
  'languages.korean': 'koreański',
  'languages.tamil': 'tamilski',
  'languages.turkish': 'turecki',
  'languages.romanian': 'rumuński',
  'languages.swedish': 'szwedzki',
  'languages.norwegian': 'norweski',
  'languages.danish': 'duński',
  'languages.slovak': 'słowacki',
  'languages.dutch': 'holenderski',
  'languages.thai': 'tajski',
  'languages.hungarian': 'węgierski',
  'languages.greek': 'grecki',
  'languages.finnish': 'fiński',
  'languages.bulgarian': 'bułgarski',
  'languages.catalan': 'kataloński',

  'dialects.americanEnglish': 'angielski amerykański',
  'dialects.britishEnglish': 'angielski brytyjski',
  'dialects.australianEnglish': 'angielski australijski',
  'dialects.scottishEnglish': 'angielski szkocki',
  'dialects.castilianSpanish': 'hiszpański kastylijski',
  'dialects.mexicanSpanish': 'hiszpański meksykański',
  'dialects.colombianSpanish': 'hiszpański kolumbijski',
  'dialects.argentinianSpanish': 'hiszpański argentyński',
  'dialects.peruvianSpanish': 'hiszpański peruwiański',
  'dialects.parisianFrench': 'francuski paryski',
  'dialects.belgianFrench': 'francuski belgijski',
  'dialects.canadianFrench': 'francuski kanadyjski',
  'dialects.standardGerman': 'niemiecki standardowy',
  'dialects.standardItalian': 'włoski standardowy',
  'dialects.standardPolish': 'polski standardowy',
  'dialects.brazilianPortuguese': 'portugalski brazylijski',
  'dialects.europeanPortuguese': 'portugalski europejski',
  'dialects.standardRussian': 'rosyjski standardowy',
  'dialects.standardUkrainian': 'ukraiński standardowy',
  'dialects.standardCzech': 'czeski standardowy',
  'dialects.standardDanish': 'duński standardowy',
  'dialects.standardDutch': 'holenderski standardowy',
  'dialects.standardFinnish': 'fiński standardowy',
  'dialects.standardIndonesian': 'indonezyjski standardowy',
  'dialects.standardMalay': 'malajski standardowy',
  'dialects.standardRomanian': 'rumuński standardowy',
  'dialects.standardSlovak': 'słowacki standardowy',
  'dialects.standardSwedish': 'szwedzki standardowy',
  'dialects.standardTurkish': 'turecki standardowy',
  'dialects.standardHungarian': 'węgierski standardowy',
  'dialects.standardNorwegian': 'norweski standardowy',

  'dashboard.exercises.title': 'Ćwiczenia',
  'dashboard.exercises.subtitle': 'Kontynuuj od ostatniego miejsca lub odkryj nowe ćwiczenia.',
  'dashboard.streak.title': 'Twoja seria nauki',
  'dashboard.streak.subtitle': 'Sprawdź swoje postępy',
  'dashboard.exercises.beginner.title': 'Początkujący {language}',
  'dashboard.exercises.beginner.description': 'Rozpocznij swoją podróż z podstawowymi słowami i wyrażeniami',
  'dashboard.exercises.levelBased.title': 'Poziom',
  'dashboard.exercises.levelBased.description': 'Popraw swoją wymowę ze słowami dopasowanymi do twojego poziomu',
  'dashboard.exercises.random.title': 'Losowe zdania',
  'dashboard.exercises.random.description': 'Ćwicz z losowo wybranymi zdaniami',
  'dashboard.exercises.custom.title': 'Własny tekst',
  'dashboard.exercises.custom.description': 'Użyj własnego tekstu do ukierunkowanej praktyki',
  'dashboard.savedWords.button': 'Przejdź do zapisanych słów',
  'dashboard.feedback.button': 'Podziel się opinią',
  'dashboard.mobile.exercises.title': 'Nasze ćwiczenia',
  'dashboard.mobile.streak.title': 'Twoja seria nauki',
  'dashboard.streak.popup.info':
    'Dzień jest liczony do twojej serii, jeśli nauczysz się co najmniej jednego słowa. Słowo jest uznawane za nauczone, gdy po raz pierwszy zostanie oznaczone na zielono w ocenie ćwiczenia.',
  'dashboard.streak.current.title': '{count}-dniowa seria',
  'dashboard.streak.current.subtitle': 'Pozostało {count} dni do zdobycia odznaki za {target}-dniową serię!',
  'dashboard.streak.longest.title': '{count}-dniowa seria',
  'dashboard.streak.longest.subtitle': 'Twoja najdłuższa seria',

  'exercise.record.instructions':
    'Kliknij przycisk nagrywania i przeczytaj powyższy tekst na głos. Gdy skończysz, kliknij stop, a my przeanalizujemy twoją wymowę.',
  'exercise.button.tryAgain': 'Spróbuj ponownie',
  'exercise.button.anotherExercise': 'Inne ćwiczenie',
  'exercise.button.nextSentence': 'Następne zdanie',
  'exercise.button.anotherText': 'Inny tekst',
  'exercise.button.differentText': 'Inny tekst',
  'exercise.error.audioGeneration': 'Błąd podczas generowania audio w komponencie ćwiczenia:',
  'exercise.error.audioTranscription': 'Błąd podczas transkrypcji audio w komponencie ćwiczenia:',

  'exercise.controls.translate': 'Przetłumacz',
  'exercise.controls.share': 'Udostępnij',
  'exercise.controls.settings': 'Ustawienia',
  'exercise.controls.searchLanguage': 'Szukaj języka...',
  'exercise.controls.noLanguageFound': 'Nie znaleziono języka.',

  'exercise.custom.startPracticing': 'Rozpocznij ćwiczenie',
  'exercise.custom.title': 'Wprowadź tekst do ćwiczeń',
  'exercise.custom.languageSelect.help':
    'Postaramy się wykryć język twojego tekstu automatycznie, ale lepiej jeśli wybierzesz go manualnie.',
  'exercise.custom.languageSelect.switchTo': 'przełącz na:',
  'exercise.custom.textInput.placeholder':
    'Wpisz tutaj tekst do ćwiczeń. Przykład: "W Szczebrzeszynie chrząszcz brzmi w trzcinie."',
  'exercise.custom.error.tooShort': 'Tekst do ćwiczeń musi mieć co najmniej 2 znaki',
  'exercise.custom.error.tooLong': 'Tekst do ćwiczeń musi być krótszy niż 750 znaków',
  'exercise.custom.success.languageUpdate': 'Język nauki i dialekt zostały zaktualizowane pomyślnie',
  'exercise.custom.error.languageUpdate': 'Nie udało się zaktualizować języka nauki',

  'exercise.skeleton.loading': 'Tworzenie ćwiczenia...',

  'exercise.cefr.title': 'Poziom CEFR',
  'exercise.cefr.info':
    'Europejski System Opisu Kształcenia Językowego (CEFR) definiuje biegłość językową w sześciu poziomach: A1 i A2 (Poziom podstawowy), B1 i B2 (Poziom samodzielności), C1 i C2 (Poziom biegłości). Każdy poziom reprezentuje rosnące umiejętności językowe, od początkującego do prawie natywnego. Te standardowe poziomy pomagają uczącym się, nauczycielom i pracodawcom zrozumieć i porównać umiejętności językowe w skali międzynarodowej.',
  'exercise.cefr.position': 'Pozycja:',
  'exercise.cefr.beginner': 'Początkujący',
  'exercise.cefr.advanced': 'Zaawansowany',
  'exercise.cefr.frequency.info':
    'Wygenerujemy zdania ze słowami znajdującymi się w okolicy tej pozycji na liście frekwencyjnej. Lista frekwencyjna pokazuje, które słowa są najczęściej używane w języku. Im niższa pozycja słowa na liście frekwencyjnej, tym częściej pojawia się w języku. Słowa na wyższych pozycjach są rzadsze, co często oznacza, że są trudniejsze do nauczenia.',

  'exercise.settings.ipaAndTransliteration': 'IPA i transliteracja',
  'exercise.settings.ipaInfo':
    'Międzynarodowy alfabet fonetyczny (IPA) i transliteracja pojawią się nad słowami do nauki',
  'exercise.settings.ipa.title': 'IPA',
  'exercise.settings.ipa.info':
    'Transkrypcja IPA (Międzynarodowy Alfabet Fonetyczny) jest funkcją eksperymentalną i może zawierać błędy.',

  'translate.modal.originalText': 'Tekst oryginalny',
  'translate.modal.translation': 'Tłumaczenie',

  'exercise.settings.title': 'Ustawienia ćwiczenia',
  'exercise.settings.level': 'Poziom:',

  'navbar.contactUs': 'Kontakt',
} as const
