import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { POSTHOG_EVENTS } from '../../../analytics/posthog/posthog-events.ts'
import { Card } from '../../design-system/card.tsx'
import { TitleWithGradient } from '../../design-system/typography/title-with-gradient.tsx'
import { t } from '../../../i18n/translate.ts'

export const SignInUpEmailVerificationSentView = ({ isSignIn }: { isSignIn: boolean }) => {
  const location = useLocation()
  const email = location.state?.email || 'your email address'

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  return (
    <div className='flex w-full flex-1 flex-col items-center justify-center px-2'>
      <Card className='gap-y-8'>
        <div className='flex flex-col items-center gap-y-4'>
          <div className='text-center'>
            <TitleWithGradient>{t('auth.verificationSent.title')}</TitleWithGradient>
          </div>
          <div className='space-y-2 text-center'>
            <p className='text-gray-700'>{t('auth.verificationSent.sentTo')}</p>
            <p className='font-medium text-gray-900'>{email}</p>
          </div>
          <div className='space-y-2 text-center'>
            <p className='text-gray-700'>
              {t('auth.verificationSent.checkInbox')}
              <br />
              {isSignIn ? t('auth.verificationSent.linkToSignIn') : t('auth.verificationSent.linkToSignUp')}.
            </p>
          </div>
        </div>
      </Card>
    </div>
  )
}
