import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountActions, selectStudyLanguageOrEnglish } from '../../../state/slices/account-slice.ts'
import { Logo } from '../../design-system/logo'
import { Checkbox } from '../../shadcn/checkbox.tsx'
import { Mic, UserCheck } from 'lucide-react'
import { EXTERNAL_LINKS } from '../../../constants/external-links.ts'
import { POSTHOG_EVENTS } from '../../../analytics/posthog/posthog-events.ts'
import { NavigationButton } from './navigation-button.tsx'
import { LANGUAGES_WITH_MULTIPLE_DIALECTS } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '../../../routing/route-paths.ts'
import { t } from '../../../i18n/translate.ts'

export const TermsAndConditionsView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isConsentChecked, setIsConsentChecked] = useState(false)
  const studyLanguage = useSelector(selectStudyLanguageOrEnglish)

  const handleStartCloning = () => {
    if (isConsentChecked) {
      dispatch(accountActions.setHasAcceptedTermsAndConditionsAndClickedNext(true))
      navigate(ROUTE_PATHS.ONBOARDING_CLONE_VOICE)
    }
  }

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const onPreviousClick = () => {
    if (studyLanguage && LANGUAGES_WITH_MULTIPLE_DIALECTS.includes(studyLanguage)) {
      dispatch(accountActions.setDialect(null))
      navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_DIALECT)
    } else {
      dispatch(accountActions.resetStudyLanguage())
      navigate(ROUTE_PATHS.ONBOARDING_CHOOSE_STUDY_LANGUAGE)
    }
  }

  return (
    <div className='flex flex-col'>
      <div className='md:mb-16'>
        <div className='mx-auto flex max-w-lg flex-col gap-y-4 px-4 py-2 md:gap-y-6 md:py-8'>
          <div className='hidden items-center space-x-4 md:flex'>
            <div className='flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600'>
              <Logo size={40} inverted={true} />
            </div>
            <h1 className='text-3xl font-bold leading-tight'>
              {t('onboarding.termsAndConditions.title')}
              <br />
              {t('onboarding.termsAndConditions.subtitle')}
            </h1>
          </div>

          <div className='flex w-full justify-center md:hidden'>
            <h1 className='text-center text-3xl font-bold leading-tight'>
              {t('onboarding.termsAndConditions.title')}
              <br />
              {t('onboarding.termsAndConditions.subtitle')}
            </h1>
          </div>

          <p className='text-gray-600'>{t('onboarding.termsAndConditions.removeVoiceInfo')}</p>

          <div className='space-y-4'>
            <div className='flex items-start space-x-3 rounded-lg bg-white p-4 shadow-sm'>
              <div className='flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100'>
                <Mic className='h-5 w-5 text-indigo-600' />
              </div>
              <div>
                <h2 className='text-lg font-semibold'>{t('onboarding.termsAndConditions.step1.title')}</h2>
                <p className='text-sm text-gray-600'>{t('onboarding.termsAndConditions.step1.description')}</p>
              </div>
            </div>

            <div className='flex items-start space-x-3 rounded-lg bg-white p-4 shadow-sm'>
              <div className='flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100'>
                <UserCheck className='h-5 w-5 text-indigo-600' />
              </div>
              <div>
                <h2 className='text-lg font-semibold'>{t('onboarding.termsAndConditions.step2.title')}</h2>
                <p className='text-sm text-gray-600'>{t('onboarding.termsAndConditions.step2.description')}</p>
              </div>
            </div>
          </div>

          <div className='flex items-start space-x-2'>
            <Checkbox
              id='consent'
              checked={isConsentChecked}
              onCheckedChange={(checked) => setIsConsentChecked(checked as boolean)}
            />
            <label htmlFor='consent' className='cursor-pointer text-sm text-gray-600'>
              {t('onboarding.termsAndConditions.consent')}{' '}
              <a
                href={EXTERNAL_LINKS.TERMS_OF_SERVICE_URL}
                className='text-indigo-600 hover:underline'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('onboarding.termsAndConditions.termsOfService')}
              </a>{' '}
              {t('onboarding.termsAndConditions.and')}{' '}
              <a
                href={EXTERNAL_LINKS.PRIVACY_POLICY_URL}
                className='text-indigo-600 hover:underline'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('onboarding.termsAndConditions.privacyPolicy')}
              </a>
              .
            </label>
          </div>
        </div>
      </div>
      <div className='fixed bottom-0 left-0 right-0 mx-auto max-w-lg bg-gray-50 p-4'>
        <div className='pointer-events-none absolute bottom-full left-0 right-0 h-16 bg-gradient-to-b from-transparent to-gray-50' />
        <div className='flex w-full flex-row items-center gap-x-4 md:gap-y-2 lg:max-w-xl'>
          <NavigationButton className='w-1/2' onClick={onPreviousClick}>
            {t('onboarding.termsAndConditions.previous')}
          </NavigationButton>
          <NavigationButton
            onClick={handleStartCloning}
            disabled={!isConsentChecked}
            className='w-1/2 bg-gradient-to-r from-indigo-500 to-indigo-600 text-white'
          >
            {t('onboarding.termsAndConditions.next')}
          </NavigationButton>
        </div>
      </div>
    </div>
  )
}
