import { getConfig } from '../../config/environment-config.ts'
import * as Sentry from '@sentry/browser'
import { SeverityLevel } from '@sentry/react'

export const logWithRollbar = (message: string, severityLevel: SeverityLevel = 'error') => {
  if (getConfig().shouldLogLocally) {
    console.log(message)
  }
  // eslint-disable-next-line
  // @ts-ignore
  if (window.Rollbar && getConfig().rollbarPostClientItemAccessToken) {
    // eslint-disable-next-line
    // @ts-ignore
    window.Rollbar.info(`${window.location.href}: ${message}`)
  }
  if (Sentry.isInitialized()) {
    Sentry.captureMessage(message, severityLevel)
  }
}
