import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { TranscriptionResponse } from '@shared/frontend-and-backend/body-types/pronunciation/transcribe.types.ts'
import { customFetch } from '../../../utils.ts'
import { transcribeUrl } from '../backend-api-urls.ts'

export const transcribeAudio = async (
  accessToken: string,
  langCode: LangCode,
  audioBlob: Blob
): Promise<ResponseWrapper<TranscriptionResponse>> => {
  const formData = new FormData()
  formData.append('audio', audioBlob, 'user-audio.mp3')

  const response: Response = await customFetch(transcribeUrl(langCode), {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  })
  const data = await response.json()
  return data
}
