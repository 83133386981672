import { useDispatch, useSelector } from 'react-redux'
import {
  accountActions,
  selectAccountAccessToken,
  selectAreLocalStorageUserDetailsLoaded,
  selectReferral,
} from '../../state/slices/account-slice.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getOrCreateUser } from '../../transport/transport/our-backend/api/users/users.ts'
import { useEffect } from 'react'
import { QUERY_KEYS } from '../../transport/transport/our-backend/api/query-keys.ts'
import { UserWordsData } from '../../transport/transport/our-backend/api/learned-words/words-hooks.ts'
import { UserSettings } from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types.ts'
import { useApiErrorHandler } from '../../hooks/use-api-error-handler.ts'
import { PutUserResponse } from '@shared/frontend-and-backend/body-types/users/users.types.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'

export const UserRetrieving = () => {
  const accessToken = useSelector(selectAccountAccessToken)
  const referral = useSelector(selectReferral)
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const areLocalStorageUserDetailsLoaded = useSelector(selectAreLocalStorageUserDetailsLoaded)

  const { mutate, error } = useMutation({
    mutationFn: () => getOrCreateUser(accessToken, referral),
    onSuccess: (body: ResponseWrapper<PutUserResponse>) => {
      const data = body.data as PutUserResponse
      queryClient.setQueryData<UserWordsData>([QUERY_KEYS.USER_WORDS], {
        counters: data.counters,
        learnedWordsByDay: data.learnedWordsByDay,
      })
      queryClient.setQueryData<UserSettings>([QUERY_KEYS.USER_SETTINGS], data.settings)
      queryClient.setQueryData([QUERY_KEYS.USER_NICKNAME], data.nickname)

      const { hasVoice, referral, motherLanguage, studyLanguage, studyDialect, nickname } = data
      dispatch(
        accountActions.setBackendUserInfo({
          referral: referral || '',
          hasVoice: hasVoice,
          motherLanguage: motherLanguage,
          studyLanguage: studyLanguage,
          studyDialect: studyDialect,
          nickname: nickname,
        })
      )
    },
  })

  useApiErrorHandler(error, 'Error when retrieving user')

  useEffect(() => {
    if (accessToken && areLocalStorageUserDetailsLoaded) {
      mutate()
    }
  }, [accessToken, mutate, areLocalStorageUserDetailsLoaded])

  return <></>
}
