import { getSupabaseClient } from '../../../transport/transport/third-party/supabase/supabase-client.ts'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectIsSignedIn } from '../../../state/slices/account-slice.ts'
import { useEffect } from 'react'
import { ROUTE_PATHS } from '../../../routing/route-paths.ts'
import { AuthError } from '@supabase/supabase-js'
import { toast } from 'sonner'
import googleSvg from '../../../images/svg/google.svg'
import { POSTHOG_EVENTS } from '../../../analytics/posthog/posthog-events.ts'
import { Button } from '../../design-system/button.tsx'
import { getConfig } from '../../../config/environment-config.ts'
import { Mail } from 'lucide-react'
import { Card } from '../../design-system/card.tsx'
import { TitleWithGradient } from '../../design-system/typography/title-with-gradient.tsx'
import { shouldShowSignInWithGoogle } from './sign-in-up-utils.ts'
import { t } from '../../../i18n/translate.ts'

export const SignInUp = ({ isSignIn = true }: { isSignIn?: boolean }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const routeFromWhichUserGotToSignInUpPage: string = location.state?.from?.pathname
  const isSignedIn: boolean = useSelector(selectIsSignedIn)

  useEffect(() => {
    if (isSignedIn) {
      navigate(routeFromWhichUserGotToSignInUpPage || ROUTE_PATHS.DASHBOARD)
    }
  }, [navigate, isSignedIn, routeFromWhichUserGotToSignInUpPage])

  const signIn = async () => {
    const { error }: { error: AuthError | null } = await getSupabaseClient().auth.signInWithOAuth({
      provider: 'google',
      options: {
        queryParams: {
          access_type: 'offline',
        },
        redirectTo: window.location.origin + (routeFromWhichUserGotToSignInUpPage || ROUTE_PATHS.DASHBOARD),
      },
    })
    if (error) {
      toast.error('Sign in failed')
    }
  }

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const handleSignInWithGoogleClick = () => {
    POSTHOG_EVENTS.click('sign_in_up_with_google_button')
    signIn().then(() => {})
  }

  const handleSignInWithEmailClick = () => {
    POSTHOG_EVENTS.click('sign_in_up_with_email_button')
    navigate(ROUTE_PATHS.SIGN_IN_EMAIL)
  }

  if (!isSignedIn) {
    return (
      <div className='flex w-full flex-1 flex-col items-center justify-center px-2'>
        <Card className='gap-y-8'>
          <div className='text-center'>
            <TitleWithGradient>
              {t('auth.signIn.title')}
              <br />
              {t('auth.signIn.subtitle')}
            </TitleWithGradient>
          </div>

          <div className='flex flex-col gap-y-4'>
            {getConfig().featureFlags.isSignInUpWithEmailEnabled() && (
              <Button
                onClick={handleSignInWithEmailClick}
                className='flex h-12 w-full items-center justify-center gap-x-4 rounded-md bg-white px-4 py-2 font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 transition-all hover:bg-gray-50'
              >
                <Mail height={20} width={20} />
                <span>{isSignIn ? t('auth.signIn.withEmail') : t('auth.signUp.withEmail')}</span>
              </Button>
            )}
            {shouldShowSignInWithGoogle() && (
              <Button
                onClick={handleSignInWithGoogleClick}
                className='flex h-12 w-full items-center justify-center gap-x-4 rounded-md bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm transition-all hover:bg-indigo-500'
              >
                <img src={googleSvg} alt='google' height={20} width={20} />
                <span>{isSignIn ? t('auth.signIn.withGoogle') : t('auth.signUp.withGoogle')}</span>
              </Button>
            )}
          </div>
        </Card>
      </div>
    )
  }
}
