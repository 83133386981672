import clsx from 'clsx'
import { Mic, Square } from 'lucide-react'
import { ShadcnTooltip } from './design-system/tooltip.tsx'

export type AudioRecorderProps = {
  isRecording: boolean
  handleStartStopRecording: () => void
  isDisabled?: boolean
}

export const AudioRecorder = ({ isRecording, handleStartStopRecording }: AudioRecorderProps) => {
  const tooltipContent = isRecording ? 'Stop Recording' : 'Start Recording'

  return (
    <div className='relative flex h-32 flex-col items-center justify-center'>
      <div
        className={`rounded-full shadow-lg transition-all duration-300 ease-in-out ${isRecording ? 'bg-red-400' : 'bg-gray-200'}`}
        style={{ height: 82, width: 82 }}
      />
      {isRecording && (
        <div className='absolute animate-ping rounded-full bg-red-500 opacity-75' style={{ height: 90, width: 90 }} />
      )}
      <ShadcnTooltip content={tooltipContent} side='top' sideOffset={15}>
        <button
          aria-pressed={isRecording}
          onClick={handleStartStopRecording}
          className={clsx(
            'absolute flex h-20 w-20 cursor-pointer items-center justify-center rounded-full text-white hover:shadow-xl active:scale-95',
            {
              'bg-indigo-600': !isRecording,
              'bg-red-500': isRecording,
            }
          )}
        >
          <div className='relative flex h-full w-full items-center justify-center'>
            <div className='absolute'>
              <Mic
                className={clsx('h-10 w-10 transition-opacity duration-300 ease-in-out', {
                  'opacity-0': isRecording,
                  'opacity-100': !isRecording,
                })}
              />
            </div>
            <div className='absolute'>
              <Square
                className={clsx('h-10 w-10 text-white transition-opacity duration-300 ease-in-out', {
                  'opacity-100': isRecording,
                  'opacity-0': !isRecording,
                })}
              />
            </div>
          </div>
        </button>
      </ShadcnTooltip>
    </div>
  )
}
