import { toast } from 'sonner'
import { Skeleton } from '../../shadcn/skeleton.tsx'

export const CopyableTranslation = ({ translation }: { translation: string | undefined }) => {
  return (
    <div className='flex w-full justify-center text-center'>
      {translation ? (
        <span
          className='cursor-pointer'
          onClick={() => {
            navigator.clipboard.writeText(translation).then(() => {
              toast.info(`"${translation}" copied to clipboard`)
            })
          }}
        >
          {translation}
        </span>
      ) : (
        'Loading...'
      )}
    </div>
  )
}

export const CopyableIpaWord = ({ text }: { text: string }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      toast.info(`"${text}" copied to clipboard`)
    })
  }

  return (
    <div
      onClick={handleCopy}
      className='flex h-6 cursor-pointer items-center justify-center rounded px-2 pb-0 pt-1 text-sm text-gray-400 transition-colors duration-100 hover:text-gray-800 md:pb-1'
    >
      {text}
    </div>
  )
}

export const CopyableTransliteratedWord = ({ text }: { text: string }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      toast.info(`"${text}" copied to clipboard`)
    })
  }

  return (
    <div
      onClick={handleCopy}
      className='flex h-6 cursor-pointer items-center justify-center rounded px-2 pb-0 pt-1 text-sm text-gray-400 transition-colors duration-100 hover:text-gray-800 md:pb-1'
    >
      {text}
    </div>
  )
}

export const NarrowSkeleton = () => {
  return (
    <div className='flex h-6 flex-col justify-center'>
      <Skeleton className='h-1' />
    </div>
  )
}
