import { RefObject, useState } from 'react'
import { MediaPlayerInstance } from '@vidstack/react'
import { useIsFetching } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../../../../transport/transport/our-backend/api/query-keys.ts'
import { RoundedLoader } from '../../../../loader/rounded-loader.tsx'
import { Button } from '../../../../design-system/button.tsx'
import { cn } from '../../../../design-system/style-utils.ts'
import { Play, Volume2 } from 'lucide-react'
import { AudioPlayer } from '../../audio-player/audio-player.tsx'
import { PLAYER_TYPE_FOR_ANALYTICS } from '../../../../../analytics/posthog/posthog-events.ts'

export const PlayExpectedWord = ({
  audioIndividualWord,
  expectedWordPlayerRef,
}: {
  audioIndividualWord: string | null
  expectedWordPlayerRef: RefObject<MediaPlayerInstance>
}) => {
  const isFetchingAudioIndividualWord = useIsFetching({
    queryKey: [QUERY_KEYS.AUDIO_INDIVIDUAL_WORD],
  })
  const [playing, setPlaying] = useState(false)
  const [audioKey, setAudioKey] = useState(0)
  const [shouldPlay, setShouldPlay] = useState(false)

  const onClick = () => {
    setPlaying(true)
    setShouldPlay(true)
    setAudioKey((prevKey) => prevKey + 1)
  }

  const handleCanPlay = () => {
    if (shouldPlay && expectedWordPlayerRef.current) {
      expectedWordPlayerRef.current.play().then()
      setShouldPlay(false)
      setTimeout(() => {
        setPlaying(false)
      }, expectedWordPlayerRef.current.duration * 1000)
    }
  }

  return (
    <div className='flex items-center'>
      <div className='relative h-10 w-10'>
        {isFetchingAudioIndividualWord ? (
          <RoundedLoader />
        ) : (
          <Button
            onClick={onClick}
            className={cn(
              'flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600 p-0',
              playing ? 'bg-indigo-500' : 'bg-indigo-600'
            )}
          >
            {playing ? (
              <Volume2 className='h-5 min-h-5 w-5 min-w-5 text-white' />
            ) : (
              <Play className='h-5 min-h-5 w-5 min-w-5 text-white' />
            )}
          </Button>
        )}
      </div>
      <AudioPlayer
        audioKey={audioKey}
        playerTypeForAnalytics={PLAYER_TYPE_FOR_ANALYTICS.EXPECTED_WORD_PRONUNCIATION}
        title={'Headless player for expected word'}
        audioSource={audioIndividualWord}
        sourceType='base64'
        isHeadless={true}
        playerRef={expectedWordPlayerRef}
        onCanPlay={handleCanPlay}
        playbackRate={0.9}
      />
    </div>
  )
}
