import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAccountAccessToken, selectReferral } from '../../../state/slices/account-slice.ts'
import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../../transport/transport/our-backend/api/query-keys.ts'
import { getSubscriptionDetails } from '../../../transport/transport/our-backend/api/payments/subscription-account.ts'
import { FullViewSquaresLoader } from '../../loader/full-view-squares-loader.tsx'
import { ROUTE_PATHS } from '../../../routing/route-paths.ts'
import { ALLOWED_REFERRALS } from '@shared/frontend-and-landing-and-backend/constants/referral-constants.ts'
import { getConfig } from '../../../config/environment-config.ts'

export const RequireCreditCardRoute = () => {
  const accessToken = useSelector(selectAccountAccessToken)
  const referral = useSelector(selectReferral)

  const { data: subscriptionData, isPending } = useQuery({
    queryKey: [QUERY_KEYS.SUBSCRIPTION_DETAILS, accessToken],
    queryFn: () => getSubscriptionDetails(accessToken),
    enabled: !!accessToken,
  })

  if (isPending) {
    return <FullViewSquaresLoader />
  } else if (
    (ALLOWED_REFERRALS.includes(referral) || !getConfig().featureFlags.isFreeTrialEnabled()) &&
    subscriptionData?.data?.details?.canPay
  ) {
    return <Navigate to={ROUTE_PATHS.PRICING} replace />
  }

  return <Outlet />
}
