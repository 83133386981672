import colors from 'tailwindcss/colors'

const indigo = colors.indigo

export const squareColorIntensities = [
  [
    [indigo[100], indigo[50]],
    [indigo[50], indigo[50]],
  ],
  [
    [indigo[100], indigo[200]],
    [indigo[50], indigo[50]],
  ],
  [
    [indigo[100], indigo[200]],
    [indigo[50], indigo[300]],
  ],
  [
    [indigo[100], indigo[200]],
    [indigo[400], indigo[300]],
  ],
  [
    [indigo[500], indigo[200]],
    [indigo[400], indigo[300]],
  ],
  [
    [indigo[500], indigo[600]],
    [indigo[400], indigo[300]],
  ],
  [
    [indigo[500], indigo[600]],
    [indigo[400], indigo[600]],
  ],
  [
    [indigo[500], indigo[600]],
    [indigo[600], indigo[600]],
  ],
  [
    [indigo[600], indigo[600]],
    [indigo[600], indigo[600]],
  ],
  [
    [indigo[600], indigo[500]],
    [indigo[600], indigo[600]],
  ],
  [
    [indigo[600], indigo[500]],
    [indigo[600], indigo[400]],
  ],
  [
    [indigo[600], indigo[500]],
    [indigo[300], indigo[400]],
  ],
  [
    [indigo[200], indigo[500]],
    [indigo[300], indigo[400]],
  ],
  [
    [indigo[200], indigo[100]],
    [indigo[300], indigo[400]],
  ],
  [
    [indigo[200], indigo[100]],
    [indigo[300], indigo[50]],
  ],
  [
    [indigo[200], indigo[100]],
    [indigo[50], indigo[50]],
  ],
  [
    [indigo[50], indigo[100]],
    [indigo[50], indigo[50]],
  ],
  [
    [indigo[50], indigo[50]],
    [indigo[50], indigo[50]],
  ],
]
