import { AudioPlayer } from '../../audio-player/audio-player.tsx'
import { PronunciationComparisonForOpenExerciseProps } from './types.ts'
import { PLAYER_TYPE_FOR_ANALYTICS } from '../../../../../analytics/posthog/posthog-events.ts'
import { AudioPlayerForBetterPronunciationForOpenExercise } from '../../audio-player/audio-player-for-better-pronunciaiton-for-open-exercise.tsx'

import { createFilename } from '../../audio-player/audio-player-utils.ts'

export const PronunciationComparisonForOpenExercise = ({
  recordedAudioBlob,
  recordedAudioPlayerRef,
  text,
}: PronunciationComparisonForOpenExerciseProps) => {
  return (
    <div className='flex w-full flex-col items-center gap-5 md:gap-2 xl:gap-12 xl:px-16'>
      <AudioPlayer
        playerTypeForAnalytics={PLAYER_TYPE_FOR_ANALYTICS.USER_PRONUNCIATION}
        title='Your pronunciation'
        audioSource={recordedAudioBlob}
        sourceType='blob'
        playerRef={recordedAudioPlayerRef}
        isHeadless={false}
        fileName={createFilename('your-pronunciation', text)}
      />
      <AudioPlayerForBetterPronunciationForOpenExercise
        title='Your better pronunciation'
        playerTypeForAnalytics={PLAYER_TYPE_FOR_ANALYTICS.USER_CLONED_PRONUNCIATION}
      />
    </div>
  )
}
