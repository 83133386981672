import { ExpectedWordProps } from '../types.ts'
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { BaseExpectedWord } from '../atoms/expected-word-base.tsx'

export const ExpectedWord = ({ wordPairWithAlignment, onClick, generatedAudioPlayerRef }: ExpectedWordProps) => {
  const { expectedStartTimeInMs, expectedEndTimeInMs } = wordPairWithAlignment
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (!generatedAudioPlayerRef) return

    // Subscribe directly to the player to avoid unnecessary re-renders
    const unsubscribe = generatedAudioPlayerRef.current!.subscribe(({ currentTime }) => {
      const isWordActive =
        expectedStartTimeInMs !== null &&
        expectedEndTimeInMs !== null &&
        currentTime * 1000 >= expectedStartTimeInMs &&
        currentTime * 1000 < expectedEndTimeInMs

      if (isWordActive !== isActive) {
        setIsActive(isWordActive)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [expectedStartTimeInMs, expectedEndTimeInMs, generatedAudioPlayerRef, isActive])

  const variants = {
    active: {
      scale: 1.2,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 20,
      },
    },
    inactive: {
      scale: 1,
    },
  }

  return (
    <motion.div
      initial='inactive'
      animate={isActive ? 'active' : 'inactive'}
      variants={variants}
      onClick={onClick}
      className='group relative flex h-8 items-center rounded-xl border-l border-r border-t border-gray-200 hover:bg-gray-100 active:bg-gray-200 md:h-10'
    >
      <BaseExpectedWord wordPair={wordPairWithAlignment} />
    </motion.div>
  )
}
