export type Discounts = {
  areActive: boolean
  monthly: {
    discountAsPercentage: number
    durationLimit: number | null // for example: 3 means the discount works for 3 months, null means it works forever as long as the user is subscribed
  }
  yearly: {
    discountAsPercentage: number
    durationLimit: number | null // for example: 2 means the discount works for 2 years, null means it works forever as long as the user is subscribed
  }
  lifetime?: {
    // the field is not required because during our first campaign with sound_like_a_russian we didn't even have the lifetime plan
    discountAsPercentage: number
  }
}

// the below values have to correspond to the coupons' ids in stripe here: https://dashboard.stripe.com/coupons
// do not remove discounts of past campaigns neither here nor on stripe, it's great for documentation
export const referralToDiscount: Record<string, Discounts> = {
  sound_like_a_russian: {
    areActive: false,
    monthly: {
      discountAsPercentage: 20,
      durationLimit: null,
    },
    yearly: {
      discountAsPercentage: 40,
      durationLimit: null,
    },
  },
  finnishwithheidi: {
    areActive: false,
    monthly: {
      discountAsPercentage: 20,
      durationLimit: 3,
    },
    yearly: {
      discountAsPercentage: 40,
      durationLimit: 1,
    },
  },
  plapla: {
    areActive: false,
    monthly: {
      discountAsPercentage: 20,
      durationLimit: 3,
    },
    yearly: {
      discountAsPercentage: 40,
      durationLimit: 1,
    },
  },
  davide: {
    areActive: false,
    monthly: {
      discountAsPercentage: 20,
      durationLimit: null,
    },
    yearly: {
      discountAsPercentage: 40,
      durationLimit: null,
    },
  },
  languageboost: {
    areActive: true,
    monthly: {
      discountAsPercentage: 20,
      durationLimit: 3,
    },
    yearly: {
      discountAsPercentage: 40,
      durationLimit: 1,
    },
  },
  yourbestfrench: {
    areActive: true,
    monthly: {
      discountAsPercentage: 20,
      durationLimit: 3,
    },
    yearly: {
      discountAsPercentage: 40,
      durationLimit: 1,
    },
  },
  expertlygerman: {
    areActive: true,
    monthly: {
      discountAsPercentage: 20,
      durationLimit: 3,
    },
    yearly: {
      discountAsPercentage: 40,
      durationLimit: 1,
    },
  },
  dutchiestobe: {
    areActive: true,
    monthly: {
      discountAsPercentage: 10,
      durationLimit: 3,
    },
    yearly: {
      discountAsPercentage: 20,
      durationLimit: 1,
    },
  },
  hnk: {
    areActive: true,
    monthly: {
      discountAsPercentage: 20,
      durationLimit: 3,
    },
    yearly: {
      discountAsPercentage: 40,
      durationLimit: 1,
    },
  },
  hnk2: {
    areActive: false,
    monthly: {
      discountAsPercentage: 15,
      durationLimit: 3,
    },
    yearly: {
      discountAsPercentage: 30,
      durationLimit: 1,
    },
  },
}

export const getDiscountsForReferral = (referral: string): Discounts => {
  const discount: Discounts | undefined = referralToDiscount[referral]
  if (!discount) {
    return {
      areActive: false,
      monthly: {
        discountAsPercentage: 0,
        durationLimit: null,
      },
      yearly: {
        discountAsPercentage: 0,
        durationLimit: null,
      },
    }
  }
  return discount
}

export const ALLOWED_REFERRALS = Object.keys(referralToDiscount) as string[]
