import {
  EXERCISE_TEXT_SEARCH_PARAM_NAME,
  STUDY_LANGUAGE_SEARCH_PARAM_NAME,
} from '@shared/frontend-and-landing-page/constants/search-params.ts'
import { SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { getConfig } from '../../config/environment-config.ts'

export const buildShareLink = (studyLanguage: SupportedStudyLanguage, exerciseText: string) => {
  const url = new URL(`${getConfig().landingPageUrl}/share`)
  url.searchParams.set(STUDY_LANGUAGE_SEARCH_PARAM_NAME, studyLanguage)
  url.searchParams.set(EXERCISE_TEXT_SEARCH_PARAM_NAME, exerciseText)
  return url.toString()
}
