import { customFetch } from '../../../utils.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import {
  CreateCustomerPortalSessionRequest,
  CreateCustomerPortalSessionResponse,
  GetSubscriptionInfoResponse,
} from '@shared/frontend-and-backend/body-types/payments/subscription-account.types.ts'
import { createCustomerPortalSessionUrl, subscriptionDetailsUrl } from '../backend-api-urls.ts'

export const getSubscriptionDetails = async (
  accessToken: string
): Promise<ResponseWrapper<GetSubscriptionInfoResponse>> => {
  const response: Response = await customFetch(subscriptionDetailsUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  return await response.json()
}

export const createCustomerPortalSession = async (
  accessToken: string,
  returnPath: string
): Promise<ResponseWrapper<CreateCustomerPortalSessionResponse>> => {
  const body: CreateCustomerPortalSessionRequest = { returnPath }
  const response: Response = await customFetch(createCustomerPortalSessionUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
    body: JSON.stringify(body),
  })

  return await response.json()
}
