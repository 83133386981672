import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import {
  AddUserPronunciationsRequest,
  AddUserPronunciationsResponse,
  GetCorrectUserPronunciationsRequestParams,
  GetCorrectUserPronunciationsResponse,
  GetLeaderboardResponse,
  UserPronunciation,
} from '@shared/frontend-and-backend/body-types/words/words.types.ts'
import { customFetch } from '../../../utils.ts'
import { getLearnedWordsUrl, leaderboardUrl, postUserPronunciationsUrl } from '../backend-api-urls.ts'
import { getConfig } from '../../../../../config/environment-config.ts'
import { SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'

export const getLearnedWords = async (
  accessToken: string,
  cursor?: string,
  languageFilter?: SupportedStudyLanguage
): Promise<ResponseWrapper<GetCorrectUserPronunciationsResponse>> => {
  const params: GetCorrectUserPronunciationsRequestParams = {
    cursor,
    limit: getConfig().paginationLimit,
    language: languageFilter,
  }

  const response: Response = await customFetch(getLearnedWordsUrl(params), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}

export const postUserPronunciations = async (
  accessToken: string,
  text: string,
  userPronunciations: UserPronunciation[],
  language: SupportedStudyLanguage,
  score: number
): Promise<ResponseWrapper<AddUserPronunciationsResponse>> => {
  const body: AddUserPronunciationsRequest = {
    text,
    userPronunciations,
    language,
    score,
  }
  const response: Response = await customFetch(postUserPronunciationsUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}

export const getLeaderboard = async (accessToken: string): Promise<ResponseWrapper<GetLeaderboardResponse>> => {
  const response: Response = await customFetch(leaderboardUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}
