import posthog from 'posthog-js'
import { PlanType } from '@shared/frontend-and-backend/body-types/payments/subscription-account.types.ts'

export enum PLAYER_TYPE_FOR_ANALYTICS {
  USER_PRONUNCIATION = 'user_pronunciation',
  USER_CLONED_PRONUNCIATION = 'native_pronunciation',
  USER_CLONED_VOICE_DEMO_ON_ONBOARDING = 'user_cloned_voice_demo_on_onboarding',
  EXPECTED_WORD_PRONUNCIATION = 'expected_word_pronunciation',
  ACTUAL_WORD_PRONUNCIATION = 'actual_word_pronunciation',
}

const defaultProperties = () => ({
  href: window.location.href,
  path_name: window.location.pathname,
  hash: window.location.hash,
  pathAndHash: window.location.pathname + window.location.hash,
  domain: window.location.hostname,
})
// use snake case for everything sent to posthog
export const POSTHOG_EVENTS = {
  click: (clickName: string) => {
    posthog.capture('click', { ...defaultProperties(), click_name: clickName })
  },
  clickPlan: (clickName: string, planType: PlanType) => {
    posthog.capture('click', { ...defaultProperties(), click_name: clickName, plan_type: planType })
  },
  playAudio: (playerType: PLAYER_TYPE_FOR_ANALYTICS) => {
    posthog.capture('play_audio', { ...defaultProperties(), player_type: playerType })
  },
  viewPage: () => {
    posthog.capture('page_view', { ...defaultProperties() })
  },
  viewModal: (modalId: string) => {
    posthog.capture('modal_view', { ...defaultProperties(), modal_id: modalId })
  },
  magicLinkFailureOrExpiration: () => {
    posthog.capture('magic_link_failure_or_expiration', { ...defaultProperties() })
  },
  showPaywallToUser: () => {
    posthog.capture('show_paywall_to_user', { ...defaultProperties() })
  },
  rateLimitUser: () => {
    posthog.capture('rate_limit_user', { ...defaultProperties() })
  },
}
