import { useAudioRecorder } from '../../../../hooks/use-audio-recorder.ts'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react'
import { AudioRecorder } from '../../../audio-recorder.tsx'
import { TranscriptionResponse } from '@shared/frontend-and-backend/body-types/pronunciation/transcribe.types.ts'
import { getTranscription, getWordsToInclude } from '../../exercise/exercise-utils.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { getAllPairs } from '../../exercise/evaluation/utils/evaluation-utils.ts'
import { ActualWordWithConfidenceAndAlignment, WordPair } from '../../exercise/evaluation/utils/types.ts'
import { ChevronRight, GraduationCap, RefreshCw } from 'lucide-react'
import { MediaPlayerInstance } from '@vidstack/react'
import { QUERY_KEYS } from '../../../../transport/transport/our-backend/api/query-keys.ts'
import { exerciseSliceActions } from '../../../../state/slices/exercise-slice.ts'
import { Button as DesignSystemButton } from '../../../design-system/button.tsx'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'
import { useApiErrorHandler } from '../../../../hooks/use-api-error-handler.ts'
import { ScoreSkeleton } from '../../exercise/evaluation/score-skeleton.tsx'
import { transcribeAudioViaOpenApi } from '../../../../transport/transport/our-backend/open-api/transcribe/transcribe.ts'
import { modalActions } from '../../../../state/slices/modal-slice.ts'
import { EvaluationForOpenExercise } from './evaluation-for-open-exercise.tsx'
import { PronunciationComparisonForOpenExercise } from '../../exercise/evaluation/organisms/pronunciation-comparison-for-open-exercise.tsx'

export type OpenExerciseProps = {
  expectedText: string
  children: ReactNode
  textOnTryAnotherTextButton: string
  studyLanguage: SupportedStudyLanguage
}

export const OpenExercise = ({
  expectedText,
  children,
  textOnTryAnotherTextButton,
  studyLanguage,
}: OpenExerciseProps) => {
  const dispatch = useDispatch()
  const { isRecording, isAudioRecorded, handleStartStopRecording, recordedAudioBlob, resetAudioRecorder } =
    useAudioRecorder()
  const recordedAudioPlayerRef = useRef<MediaPlayerInstance>(null)

  const {
    data: evaluationData,
    error: transcribeError,
    isFetching: isFetchingEvaluation,
  }: UseQueryResult<ResponseWrapper<TranscriptionResponse>, Error> = useQuery({
    queryKey: [QUERY_KEYS.TRANSCRIPTION_WITH_OPEN_API, recordedAudioBlob, studyLanguage],
    queryFn: () => transcribeAudioViaOpenApi(studyLanguage, recordedAudioBlob as Blob),
    enabled: recordedAudioBlob !== null,
    staleTime: 0,
    refetchOnWindowFocus: false,
    retry: 0,
  })

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const actualDirtyWords: ActualWordWithConfidenceAndAlignment[] = useMemo(
    () => (evaluationData?.data ? getTranscription(evaluationData?.data) : []),
    [evaluationData?.data]
  )

  const tryAnotherExercise = useCallback(() => {
    POSTHOG_EVENTS.click('try_another_exercise')
    dispatch(modalActions.openSignUpPromptModal('Sign up to try another exercise'))
  }, [resetAudioRecorder])

  const handleTryAnotherTextClick = useCallback(() => {
    POSTHOG_EVENTS.click('try_another_text')
    dispatch(modalActions.openSignUpPromptModal('Sign up to try another text'))
  }, [resetAudioRecorder])

  const handleTryAgainClick = () => {
    POSTHOG_EVENTS.click('try_again')
    resetAudioRecorder()
  }

  useApiErrorHandler(transcribeError, `Error transcribing audio in exercise component: ${transcribeError}`)

  const pairs: WordPair[] = useMemo(() => getAllPairs(expectedText, actualDirtyWords), [expectedText, actualDirtyWords])

  useEffect(() => {
    const wordsToInclude: string[] = getWordsToInclude(pairs, expectedText)
    dispatch(exerciseSliceActions.setWordsToInclude({ wordsToInclude }))
  }, [pairs, expectedText, dispatch])

  const isEvaluationLoading: boolean = isFetchingEvaluation && !!recordedAudioBlob
  return (
    <div className='flex w-full flex-1 flex-col items-center justify-between gap-y-4 p-1 text-center md:p-2'>
      <div className='flex w-full flex-col items-center lg:pb-0'>
        {isEvaluationLoading && (
          <div className='flex w-full flex-col items-center gap-2 md:max-w-4xl md:gap-4 lg:max-w-6xl'>
            <ScoreSkeleton />
          </div>
        )}
        <div className='mb-4 flex w-full flex-col items-center md:max-w-4xl lg:max-w-6xl'>
          {evaluationData && !isFetchingEvaluation ? (
            <EvaluationForOpenExercise
              wordPairs={pairs}
              text={expectedText}
              studyLanguage={studyLanguage}
              recordedAudioBlob={recordedAudioBlob}
            />
          ) : (
            <>{children}</>
          )}
        </div>
        {recordedAudioBlob && (
          <div className='w-full md:max-w-screen-xl'>
            <PronunciationComparisonForOpenExercise
              recordedAudioBlob={recordedAudioBlob}
              recordedAudioPlayerRef={recordedAudioPlayerRef}
              text={expectedText}
            />
          </div>
        )}
        {!isAudioRecorded && (
          <>
            <p className='hidden w-80 text-center text-xs text-gray-400 md:block'>
              Click the record button and read the above text aloud. When you're done, click stop and we'll analyze your
              pronunciation.
            </p>
            <AudioRecorder isRecording={isRecording} handleStartStopRecording={handleStartStopRecording} />
          </>
        )}
      </div>
      <div className='flex w-full flex-col gap-y-3 md:gap-2 lg:flex-row xl:max-w-screen-md'>
        {recordedAudioBlob && (
          <DesignSystemButton onClick={handleTryAgainClick} className='w-full border border-slate-300'>
            <RefreshCw className='mr-2 h-5' />
            <span>Try again</span>
          </DesignSystemButton>
        )}
        <DesignSystemButton onClick={tryAnotherExercise} className='w-full border border-slate-300'>
          <GraduationCap className='mr-1 h-5' />
          <span>Try a different exercise</span>
        </DesignSystemButton>
        <DesignSystemButton onClick={handleTryAnotherTextClick} className='w-full border border-slate-300'>
          <ChevronRight className='mr-1 h-5' />
          <span>{textOnTryAnotherTextButton}</span>
        </DesignSystemButton>
      </div>
    </div>
  )
}
