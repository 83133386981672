import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import {
  DetectStudyLanguageRequest,
  DetectStudyLanguageResponse,
} from '@shared/frontend-and-backend/body-types/language-detection/language-detection.types.ts'
import { languageDetectionUrl } from '../backend-api-urls.ts'
import { customFetch } from '../../../utils.ts'

export const getTextLanguage = async (
  text: string,
  accessToken: string
): Promise<ResponseWrapper<DetectStudyLanguageResponse>> => {
  const body: DetectStudyLanguageRequest = { text }
  const response: Response = await customFetch(languageDetectionUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}
