import {
  TranscriptionResponse,
  WordBase,
} from '@shared/frontend-and-backend/body-types/pronunciation/transcribe.types.ts'
import { ActualWordWithConfidenceAndAlignment, WordPair } from './evaluation/utils/types.ts'
import { getCleanWordsFromSentence } from '@shared/frontend-and-backend/utils/text-utils.ts'

export const getTranscription = (
  transcriptionResponse: TranscriptionResponse
): ActualWordWithConfidenceAndAlignment[] => {
  if (!transcriptionResponse?.results?.channels || transcriptionResponse?.results?.channels.length === 0) {
    return []
  }
  if (
    !transcriptionResponse?.results?.channels[0]?.alternatives ||
    !transcriptionResponse?.results?.channels[0]?.alternatives[0]?.words
  ) {
    return []
  }
  const result: ActualWordWithConfidenceAndAlignment[] =
    transcriptionResponse.results.channels[0].alternatives[0].words.map((word: WordBase) => ({
      actualWord: word.punctuated_word || word.word,
      confidence: word.confidence,
      actualStartTimeInSeconds: word.start,
      actualEndTimeInSeconds: word.end,
    }))
  return result
}

const shuffleArray = (words: string[]) => {
  const wordsCopy: string[] = [...words]
  for (let i: number = wordsCopy.length - 1; i > 0; i--) {
    const j: number = Math.floor(Math.random() * (i + 1))
    const temp: string = wordsCopy[i]
    wordsCopy[i] = wordsCopy[j]
    wordsCopy[j] = temp
  }
  return wordsCopy
}

export const getRandomWords = (words: string[], count: number): string[] => {
  if (words.length <= count) {
    return words
  }

  const shuffled: string[] = shuffleArray(words)
  return shuffled.slice(0, count)
}

export const getDifficultWords = (pairs: WordPair[]): string[] => {
  return pairs
    .filter((pair: WordPair) => !pair.actualWord)
    .map((pair: WordPair) => pair.expectedWord)
    .filter((word: string | null): word is string => word !== null)
}

export const getWordsToInclude = (pairs: WordPair[], expectedText: string): string[] => {
  const difficultWords: string[] = getDifficultWords(pairs)
  const expectedWords: string[] = getCleanWordsFromSentence(expectedText)
  const twoRandomWordsActualWords: string[] = getRandomWords(expectedWords, 2)
  const twoRandomDifficultWords: string[] = getRandomWords(difficultWords, 2)
  return twoRandomDifficultWords.length > 0 ? twoRandomDifficultWords : twoRandomWordsActualWords
}
