import React from 'react'
import { FAQItem } from '../FAQItem'
import { EXTERNAL_LINKS } from '../../constants/external-links'
import { useSelector } from 'react-redux'
import { selectHasReferral } from '../../state/slices/account-slice.ts'
import { getConfig } from '../../config/environment-config.ts'

import {
  NUMBER_OF_DAYS_IN_FREE_TRIAL,
  REFUND_PERIOD_IN_DAYS,
} from '@shared/frontend-and-landing-and-backend/constants/pricing-constants.ts'

export const PricingFaqItems: React.FC = () => {
  const hasReferral = useSelector(selectHasReferral)

  const pricingFaqItems = [
    {
      question: 'How do I get a free trial?',
      answer:
        getConfig().featureFlags.isFreeTrialEnabled() && !hasReferral
          ? `You can get a free trial by signing up to our Free Trial plan. No credit card is required, and the trial automatically cancels after ${NUMBER_OF_DAYS_IN_FREE_TRIAL} days.`
          : `You can get a ${NUMBER_OF_DAYS_IN_FREE_TRIAL}-day free trial by signing up and entering your credit card details. Don't worry, you won't be charged during the trial period, and you can cancel anytime before it ends. Even if you forget and get charged, we do have a ${REFUND_PERIOD_IN_DAYS}-day refund guarantee.`,
    },
    {
      question: 'Is the payment service secure?',
      answer:
        'Yes, our payment service is secure. We use Stripe for financial processing, and we do not store any information about your card. Stripe ensures bank-level security standards.',
    },
    {
      question: 'Can I upgrade or downgrade my subscription?',
      answer:
        "Yes, you can upgrade or downgrade your subscription at any time. Go to the top right of the app when you are logged in, click on your profile image, open the Account section in the menu and click Manage Subscription, you will be redirected to Stripe's billing portal where you can switch plans.",
    },
    {
      question: 'Can I cancel my subscription anytime?',
      answer:
        "You can cancel your subscription at any time. Go to the Account section when you are logged in and click on Manage Subscription, you will be redirected to Stripe's billing portal where you can download invoices, switch plans and cancel your subscription.",
    },
    {
      question: 'Can I get a refund?',
      answer: (
        <>
          Yes, you can get a refund within {REFUND_PERIOD_IN_DAYS} after you've been charged. We do not want to charge
          customers who are not happy about the product. Please read more about our refund policy here:{' '}
          <a
            href={EXTERNAL_LINKS.REFUND_POLICY}
            target='_blank'
            rel='noopener noreferrer'
            className='text-indigo-600 underline hover:text-indigo-800'
          >
            Refund Policy
          </a>
        </>
      ),
    },
  ]

  return (
    <>
      {pricingFaqItems.map((item, index) => (
        <FAQItem
          key={index}
          question={item.question}
          answer={item.answer}
          isLast={index === pricingFaqItems.length - 1}
        />
      ))}
    </>
  )
}
