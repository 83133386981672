import { useMemo } from 'react'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../shadcn/table.tsx'
import { CircleFlagLanguage } from 'react-circle-flags'
import { langCodeToLanguageName } from '../../../../../../constants/lang-code-utils.ts'
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { GetSavedWordsResponse } from '@shared/frontend-and-backend/body-types/saved-words/saved-words.types.ts'
import { Trash2 } from 'lucide-react'
import { deleteSavedWordByProvidingItsOrthographicForm } from '../../../../../../transport/transport/our-backend/api/saved-words/saved-words.ts'
import { useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../../../../../../state/slices/account-slice.ts'
import { QUERY_KEYS } from '../../../../../../transport/transport/our-backend/api/query-keys.ts'
import { SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { Button } from '../../../../../design-system/button.tsx'
import { toast } from 'sonner'
import { LanguageFilterValue } from '../learned-words/language-filter.tsx'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '../../../../../../routing/route-paths.ts'

type SavedWord = {
  word: string
  language: SupportedStudyLanguage
}

const useDeleteSavedWord = () => {
  const accessToken = useSelector(selectAccountAccessToken)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ orthographicForm, language }: { orthographicForm: string; language: SupportedStudyLanguage }) => {
      return deleteSavedWordByProvidingItsOrthographicForm(accessToken, orthographicForm, language)
    },
    onMutate: async ({ orthographicForm, language }) => {
      const previousData = queryClient.getQueryData<InfiniteData<ResponseWrapper<GetSavedWordsResponse>>>([
        QUERY_KEYS.SAVED_WORDS_LIST,
      ])
      queryClient.setQueryData<InfiniteData<ResponseWrapper<GetSavedWordsResponse>>>(
        [QUERY_KEYS.SAVED_WORDS_LIST],
        (old) => {
          if (!old) return old
          return {
            ...old,
            pages: old.pages.map((page) => ({
              ...page,
              data: page.data
                ? {
                    ...page.data,
                    savedWords: page.data.savedWords.filter(
                      (savedWord) => !(savedWord.word === orthographicForm && savedWord.language === language)
                    ),
                  }
                : page.data,
            })),
          }
        }
      )
      queryClient.setQueryData<boolean>([QUERY_KEYS.SAVED_WORDS, orthographicForm, language], false)
      return { previousData }
    },
    onError: (_, { orthographicForm, language }, context) => {
      queryClient.setQueryData([QUERY_KEYS.SAVED_WORDS_LIST], context?.previousData)
      queryClient.setQueryData<boolean>([QUERY_KEYS.SAVED_WORDS, orthographicForm, language], true)
      toast.error(`Failed to remove '${orthographicForm}' from saved words`)
    },
    onSuccess: (_, { orthographicForm }) => {
      toast.success(`'${orthographicForm}' removed from saved words`)
    },
  })
}

export const SavedWordsTable = ({
  data,
  languageFilter,
}: {
  data: InfiniteData<ResponseWrapper<GetSavedWordsResponse>>
  languageFilter: LanguageFilterValue
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(ROUTE_PATHS.DASHBOARD)
  }
  const deleteMutation = useDeleteSavedWord()

  const flatData = useMemo(() => {
    return (
      data?.pages
        .flatMap((group) => group.data?.savedWords ?? [])
        .filter((word) => languageFilter === undefined || word.language === languageFilter) ?? []
    )
  }, [data, languageFilter])

  const columnHelper = createColumnHelper<SavedWord>()

  const columns = [
    columnHelper.accessor('word', {
      header: 'Word',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('language', {
      header: 'Language',
      cell: (info) => (
        <div className='flex items-center justify-center space-x-2'>
          <CircleFlagLanguage languageCode={info.getValue()} className='h-5 w-5' />
          <span className='text-sm text-gray-700'>{langCodeToLanguageName(info.getValue())}</span>
        </div>
      ),
    }),
    columnHelper.display({
      id: 'action',
      header: 'Action',
      cell: (info) => {
        const row = info.row.original
        const handleDelete = () => {
          deleteMutation.mutate({
            orthographicForm: row.word,
            language: row.language,
          })
        }

        return (
          <Button onClick={handleDelete} disabled={deleteMutation.isPending} className=''>
            <Trash2 className='h-5 min-h-5 w-5 min-w-5 text-gray-500' />
          </Button>
        )
      },
    }),
  ]

  const table = useReactTable({
    data: flatData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className='w-full overflow-hidden rounded-lg border border-gray-200 shadow-sm'>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className='flex bg-gray-50'>
              {headerGroup.headers.map((header) => {
                const isActionColumn = header.column.id === 'action'
                return (
                  <TableHead
                    key={header.id}
                    className={
                      isActionColumn
                        ? 'w-20 px-4 py-3 text-center text-sm font-semibold text-gray-900'
                        : 'flex-1 px-4 py-3 text-left text-sm font-semibold text-gray-900'
                    }
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} className='flex hover:bg-gray-50'>
                {row.getVisibleCells().map((cell) => {
                  const isActionColumn = cell.column.id === 'action'
                  return (
                    <TableCell
                      key={cell.id}
                      className={
                        isActionColumn ? 'w-20 px-4 py-3' : 'flex flex-1 items-center px-4 py-3 text-sm text-gray-900'
                      }
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))
          ) : (
            <TableRow className='flex h-64'>
              <TableCell
                colSpan={columns.length}
                className='flex flex-1 flex-col items-center justify-center p-8 text-center'
              >
                <h2 className='mb-2 text-2xl font-semibold text-gray-800'>
                  {languageFilter === undefined
                    ? 'No saved words yet'
                    : `No saved words in ${langCodeToLanguageName(languageFilter)} yet`}
                </h2>
                <p className='mb-6 text-gray-600'>Start saving words during exercises to build your vocabulary</p>
                <Button onClick={handleClick} className='bg-indigo-500 text-white hover:bg-indigo-600'>
                  Take me to the exercises
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
