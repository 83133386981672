import { selectAccountAccessToken, selectIsSignedIn } from '../state/slices/account-slice.ts'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useCheckoutMutation } from '../hooks/use-checkout-mutation.ts'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE_PATHS } from '../routing/route-paths.ts'
import { FullViewSquaresLoader } from './loader/full-view-squares-loader.tsx'

// This component is reached after the route /from-landing.
// We want to redirect users who clicked on the premium button on the landing page to be directed to the checkout page
// right after signing up. We need this additional route because Supabase login will strip search params from the url,
// so we have to send the user to a new route that uses a query param in order to preserve the priceId.

export const RedirectToCheckOut = () => {
  const isSignedIn: boolean = useSelector(selectIsSignedIn)
  const accessToken = useSelector(selectAccountAccessToken)

  const navigate = useNavigate()
  const { priceId } = useParams<{ priceId: string }>()

  const { mutate } = useCheckoutMutation(accessToken)

  useEffect(() => {
    if (isSignedIn && priceId) {
      mutate({
        priceId,
        successPathAndHash: ROUTE_PATHS.CHECKOUT_SUCCESS,
        cancelPathAndHash: ROUTE_PATHS.DASHBOARD,
      })
    } else {
      navigate(ROUTE_PATHS.DASHBOARD)
    }
  }, [isSignedIn, priceId, mutate])

  return <FullViewSquaresLoader />
}
