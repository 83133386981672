import { getConfig } from '../../../../config/environment-config.ts'
import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'

export const openApiPrefix = '/api/v1/open'
const hostWithPrefix = `${getConfig().apiHost}${openApiPrefix}`

export const transcribeOpenUrl = (langCode: LangCode) => `${hostWithPrefix}/transcribe?langCode=${langCode}`

export const translateTextOpenUrl = `${hostWithPrefix}/translate-text`
export const translateWordOpenUrl = `${hostWithPrefix}/translate-word`
export const ipaTranscriptionOpenUrl = `${hostWithPrefix}/pronunciation/transcribe-to-ipa`
export const transliterationOpenUrl = `${hostWithPrefix}/transliterate`
export const convertAudioOpenUrl = `${hostWithPrefix}/audio/convert-to-mp3`
