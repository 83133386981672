import { ShadcnTooltip } from '../../../../design-system/tooltip.tsx'
import { Share2 } from 'lucide-react'
import { buildShareLink } from '../../../exercise-utils.ts'
import { toast } from 'sonner'
import { useSelector } from 'react-redux'
import { selectStudyLanguageOrEnglish } from '../../../../../state/slices/account-slice.ts'
import { POSTHOG_EVENTS } from '../../../../../analytics/posthog/posthog-events.ts'

export type ShareExerciseButtonProps = {
  text: string
}

export const ShareExerciseButton = ({ text }: ShareExerciseButtonProps) => {
  const studyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const handleShareClick = () => {
    const shareLink = buildShareLink(studyLanguage, text)
    POSTHOG_EVENTS.click('share_exercise_button')
    navigator.clipboard.writeText(shareLink).then(() => {
      toast.success('Share link copied to clipboard')
    })
  }

  return (
    <ShadcnTooltip content='Challenge your friends with the same exercise' side='top' sideOffset={5}>
      <div className='flex' onClick={handleShareClick}>
        <div className='cursor-pointer rounded p-1.5 text-gray-700 transition-colors duration-100 hover:bg-gray-600 hover:text-white active:bg-gray-700 active:text-white'>
          <Share2 className='h-5' />
        </div>
      </div>
    </ShadcnTooltip>
  )
}
