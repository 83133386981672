import { englishDictionary } from './dictionaries/english-dictionary'
import { polishDictionary } from './dictionaries/polish-dictionary'
import { ENGLISH_LOCALE, i18nConfig, type Locale, POLISH_LOCALE } from './i18n-config'

const dictionaries = {
  [ENGLISH_LOCALE]: englishDictionary,
  [POLISH_LOCALE]: polishDictionary,
} as const

export type DictionaryKey = keyof typeof englishDictionary

export type Dictionary = {
  [K in DictionaryKey]: string
}

const getBrowserLocale = (): Locale => {
  if (typeof navigator === 'undefined') {
    return ENGLISH_LOCALE
  }

  const browserLang = navigator.language.split('-')[0]

  if (browserLang === POLISH_LOCALE) {
    return POLISH_LOCALE
  }

  return ENGLISH_LOCALE
}

export const t = (dictionaryKey: DictionaryKey): string => {
  const locale: Locale = getBrowserLocale()
  const dictionary: Dictionary = dictionaries[locale]
  return dictionary[dictionaryKey] || dictionaries[i18nConfig.defaultLocale][dictionaryKey] || dictionaryKey
}
