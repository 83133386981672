import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { createCheckoutSession } from '../transport/transport/our-backend/api/payments/checkout.ts'
import { logWithRollbar } from '../analytics/rollbar/log-with-rollbar.ts'
import { modalActions } from '../state/slices/modal-slice.ts'

const SOMETHING_WENT_WRONG_MODAL_ID = 'your-modal-id'

export const useCheckoutMutation = (accessToken: string) => {
  const dispatch = useDispatch()

  return useMutation({
    mutationFn: ({
      priceId,
      successPathAndHash,
      cancelPathAndHash,
    }: {
      priceId: string
      successPathAndHash: string
      cancelPathAndHash: string
    }) => createCheckoutSession(priceId, accessToken, successPathAndHash, cancelPathAndHash),
    onSuccess: (data) => {
      window.location.href = data.data?.url ?? ''
    },
    onError: (error) => {
      logWithRollbar(`Error creating checkout session: ${error}`)
      dispatch(modalActions.openModal(SOMETHING_WENT_WRONG_MODAL_ID))
    },
  })
}
