import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import {
  DEFAULT_DIALECTS,
  DialectCode,
  LangCode,
  LANGUAGES_WITH_MULTIPLE_DIALECTS,
  SupportedMotherLanguage,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { ROUTE_PATHS } from '../../routing/route-paths.ts'
import {
  ALLOWED_REFERRALS,
  getDiscountsForReferral,
} from '@shared/frontend-and-landing-and-backend/constants/referral-constants.ts'

export interface AccountState {
  id: string
  accessToken: string
  email: string
  name: string
  fullName: string
  imageUrl: string
  isSupabaseSignInStateLoaded: boolean
  areLocalStorageUserDetailsLoaded: boolean
  isBackendUserInfoLoaded: boolean
  hasVoice: boolean
  motherLanguage: SupportedMotherLanguage | null
  hasChosenMotherLanguage: boolean
  studyLanguage: SupportedStudyLanguage | null
  dialect: DialectCode | null
  referral: string
  hasAcceptedTermsAndConditionsAndClickedNext: boolean
  isUserInfoLoaded: boolean
  hasJustClonedVoice: boolean
  nickname: string | null
}

const initialState: AccountState = {
  id: '',
  accessToken: '',
  email: '',
  name: '',
  fullName: '',
  imageUrl: '',
  isSupabaseSignInStateLoaded: false,
  areLocalStorageUserDetailsLoaded: false,
  isBackendUserInfoLoaded: false,
  hasVoice: false,
  motherLanguage: null,
  hasChosenMotherLanguage: false,
  studyLanguage: null,
  dialect: null,
  referral: '',
  hasAcceptedTermsAndConditionsAndClickedNext: false,
  isUserInfoLoaded: false,
  hasJustClonedVoice: false,
  nickname: null,
}

export type SetUserDetailsPayload = {
  id: string
  accessToken: string
  email: string
  name: string
  fullName: string
  imageUrl: string
}

export type SetLocalStorageUserDetailsPayload = {
  referral: string
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setSupabaseAuthUserDetails: (state, action: PayloadAction<SetUserDetailsPayload>) => {
      state.accessToken = action.payload.accessToken
      state.email = action.payload.email
      state.name = action.payload.name
      state.fullName = action.payload.fullName
      state.id = action.payload.id
      state.imageUrl = action.payload.imageUrl
      state.isSupabaseSignInStateLoaded = true
    },
    setLocalStorageUserDetails: (state, { payload }: PayloadAction<SetLocalStorageUserDetailsPayload>) => {
      state.areLocalStorageUserDetailsLoaded = true
      // if by this time we already have a referral retrieved from the backend, we don't want to override it
      if (state.referral === '') {
        state.referral = payload.referral
      }
    },
    setBackendUserInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        referral?: string
        hasVoice: boolean
        motherLanguage: LangCode | null
        studyLanguage: SupportedStudyLanguage | null
        studyDialect: DialectCode | null
        nickname: string | null
      }>
    ) => {
      state.isBackendUserInfoLoaded = true
      if (payload.referral) {
        state.referral = payload.referral
      }
      state.hasVoice = payload.hasVoice
      if (payload.motherLanguage) {
        state.hasChosenMotherLanguage = true
      }
      state.motherLanguage = payload.motherLanguage
      state.studyLanguage = payload.studyLanguage
      state.dialect = payload.studyDialect
      state.nickname = payload.nickname
    },
    setHasVoice: (state) => {
      state.hasVoice = true
      state.hasJustClonedVoice = true
    },
    setHasNoVoice: (state) => {
      state.hasVoice = false
    },
    setIsSupabaseSignInStateLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.isSupabaseSignInStateLoaded = payload
    },
    setMotherLanguage: (state, { payload }: PayloadAction<SupportedMotherLanguage | null>) => {
      state.motherLanguage = payload
      state.hasChosenMotherLanguage = true
    },
    resetMotherLanguage: (state) => {
      state.motherLanguage = null
      state.hasChosenMotherLanguage = false
    },
    resetStudyLanguage: (state) => {
      state.studyLanguage = null
    },
    setReferralIfNotAlreadySet: (state, { payload }: PayloadAction<string>) => {
      if (!!payload && state.areLocalStorageUserDetailsLoaded && state.referral === '') {
        state.referral = payload
      }
    },
    setHasChosenNoneOfPossibleMotherLanguages: (state) => {
      state.motherLanguage = null
      state.hasChosenMotherLanguage = true
    },
    setHasAcceptedTermsAndConditionsAndClickedNext: (state, { payload }: PayloadAction<boolean>) => {
      state.hasAcceptedTermsAndConditionsAndClickedNext = payload
    },
    setStudyLanguage: (state, { payload }: PayloadAction<SupportedStudyLanguage | null>) => {
      state.studyLanguage = payload
    },
    setStudyLanguageAndDefaultDialect: (state, { payload }: PayloadAction<SupportedStudyLanguage | null>) => {
      state.studyLanguage = payload
      const language = payload
      if (language) {
        state.dialect = DEFAULT_DIALECTS[language]
      }
    },
    setDialect: (state, { payload }: PayloadAction<DialectCode | null>) => {
      state.dialect = payload
    },
    setHasJustClonedVoice: (state, { payload }: PayloadAction<boolean>) => {
      state.hasJustClonedVoice = payload
    },
    clearUserDetails: () => ({
      ...initialState,
      isSupabaseSignInStateLoaded: true,
      isBackendUserInfoLoaded: false,
    }),
  },
})

export const selectIsSignedIn = (state: RootState): boolean => !!state.account.accessToken
export const selectImageUrl = (state: RootState): string => state.account.imageUrl

export const selectFullName = (state: RootState): string => state.account.fullName

export const selectInitials = (state: RootState): string => {
  const { fullName, name, email } = state.account

  const nameBase: string = fullName || name
  if (!nameBase) {
    return email.substring(0, 1).toUpperCase()
  }

  const nameBaseSplit: string[] = nameBase.split(/\s+/)
  if (nameBaseSplit.length > 1) {
    return `${nameBaseSplit[0].charAt(0)}${nameBaseSplit[1].charAt(0)}`.toUpperCase()
  }
  if (1 < nameBase.length) {
    return nameBase.substring(0, 2).toUpperCase()
  }
  return nameBase.charAt(0).toUpperCase()
}

export const selectEmail = (state: RootState): string => state.account.email
export const selectName = (state: RootState): string => state.account.name

export const selectIsSupabaseSignInStateLoading = (state: RootState): boolean =>
  !state.account.isSupabaseSignInStateLoaded
export const selectIsBackendUserInfoLoading = (state: RootState): boolean => !state.account.isBackendUserInfoLoaded
export const selectIsBackendUserInfoLoaded = (state: RootState): boolean => state.account.isBackendUserInfoLoaded
export const selectAccountAccessToken = (state: RootState): string => state.account.accessToken
export const selectHasVoice = (state: RootState): boolean => state.account.hasVoice
export const selectUserId = (state: RootState) => state.account.id
export const selectMotherLanguageOrEnglish = (state: RootState): SupportedMotherLanguage =>
  state.account.motherLanguage || LangCode.ENGLISH
export const selectStudyLanguageOrEnglish = (state: RootState): SupportedStudyLanguage =>
  state.account.studyLanguage || LangCode.ENGLISH
export const selectReferral = (state: RootState): string => {
  return state.account.referral
}
export const selectDialectOrDefaultDialectOrEnglishDefaultDialect = (state: RootState): DialectCode => {
  if (state.account.dialect) {
    return state.account.dialect
  }
  return DEFAULT_DIALECTS[selectStudyLanguageOrEnglish(state)]
}

export const selectAreLocalStorageUserDetailsLoaded = (state: RootState): boolean =>
  state.account.areLocalStorageUserDetailsLoaded

// This might look convoluted, but we have too many places for storing user data. Today it's the backend, Supabase API, and
// local storage. One day it could also be cookies and other APIs or microservices that we might have. We need to make sure
// that there is a single source of truth for user data, and we decided this source of truth to be the Redux store.
// Think about referral, for example. There are multiple cases:
// - It might exist only in the local storage before signup -> local storage should be the source of truth
// - It might exist only in the backend if the user signs in from a different device or not for the first time -> backend should be the source of truth
// - It might exist in both local storage and backend -> we need to wait for the backend to load the data
// In any case, we need to know when we can trust the referral to be loaded, and this is how the below selector can help us
export const selectIsUserInfoFromAllSourcesLoaded = (state: RootState): boolean => {
  // note that we do not have to check for supabase state being loaded, because it is assumed that the backend
  // could be called only after the supabase state is loaded
  return selectAreLocalStorageUserDetailsLoaded(state) && selectIsBackendUserInfoLoaded(state)
}

export const selectHasFinishedOnboarding = (state: RootState): boolean => selectMissingOnboardingStep(state) === null
export const selectMissingOnboardingStep = (
  state: RootState
):
  | typeof ROUTE_PATHS.ONBOARDING_CHOOSE_STUDY_LANGUAGE
  | typeof ROUTE_PATHS.ONBOARDING_CHOOSE_MOTHER_LANGUAGE
  | typeof ROUTE_PATHS.ONBOARDING_CHOOSE_DIALECT
  | typeof ROUTE_PATHS.ONBOARDING_TERMS_AND_CONDITIONS
  | typeof ROUTE_PATHS.ONBOARDING_CLONE_VOICE
  | typeof ROUTE_PATHS.ONBOARDING_SUCCESS
  | null => {
  const {
    // having motherLanguage set to null or hasChosenMotherLanguage set to false are not equivalent states
    // it's because the user can choose "other" as a mother language, and end up with motherLanguage set to null
    // but chosenMotherLanguage set to true
    hasChosenMotherLanguage,
    studyLanguage,
    hasAcceptedTermsAndConditionsAndClickedNext,
    hasVoice,
    hasJustClonedVoice,
  } = state.account
  if (!hasChosenMotherLanguage) {
    return ROUTE_PATHS.ONBOARDING_CHOOSE_MOTHER_LANGUAGE
  }
  if (!studyLanguage) {
    return ROUTE_PATHS.ONBOARDING_CHOOSE_STUDY_LANGUAGE
  }
  if (studyLanguage && LANGUAGES_WITH_MULTIPLE_DIALECTS.includes(studyLanguage) && !state.account.dialect) {
    return ROUTE_PATHS.ONBOARDING_CHOOSE_DIALECT
  }
  if (hasAcceptedTermsAndConditionsAndClickedNext && !hasVoice) {
    return ROUTE_PATHS.ONBOARDING_CLONE_VOICE
  }
  if (hasChosenMotherLanguage && !hasVoice) {
    return ROUTE_PATHS.ONBOARDING_TERMS_AND_CONDITIONS
  }
  if (hasChosenMotherLanguage && studyLanguage && hasVoice && hasJustClonedVoice) {
    return ROUTE_PATHS.ONBOARDING_SUCCESS
  }
  return null
}

export const selectHasReferral = (state: RootState): boolean => {
  return ALLOWED_REFERRALS.includes(state.account.referral)
}

export const selectCanSubscribeWithReferralDiscount = (state: RootState): boolean => {
  const referral = state.account.referral
  return ALLOWED_REFERRALS.includes(state.account.referral) && getDiscountsForReferral(referral).areActive
}

export const accountActions = accountSlice.actions

export default accountSlice.reducer
