import { NAME_OF_SECRET_HEADER_USED_FOR_AUTHENTICATING_FRONTEND } from '@shared/frontend-and-backend/key-generation/frontend-api-key-constants.ts'
import { generateFrontendApiKey } from '@shared/frontend-and-backend/key-generation/frontend-api-key-generator.ts'

export const customFetch = async (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response> => {
  const initWithFrontendKey: RequestInit = {
    ...init,
    headers: {
      ...init?.headers,
      [NAME_OF_SECRET_HEADER_USED_FOR_AUTHENTICATING_FRONTEND]: generateFrontendApiKey(Date.now(), 0),
    },
  }
  const response = await fetch(input, initWithFrontendKey)
  if (!response.ok) {
    throw new ApiError(`An error occurred: ${response.status}`, response.status, await response.json())
  }
  return response
}

export class ApiError extends Error {
  status: number
  body: unknown

  constructor(message: string, status: number, body: unknown) {
    super(message)
    this.status = status
    this.name = 'ApiError'
    this.body = body
  }
}
