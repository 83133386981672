import { SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../query-keys.ts'
import {
  DEFAULT_POSITION_IN_FREQUENCY_LIST,
  UserSettings,
} from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types.ts'

export const useUserSettings = () => {
  return useQuery<UserSettings>({
    queryKey: [QUERY_KEYS.USER_SETTINGS],
  })
}

export const useFrequencySliderPosition = (studyLanguage: SupportedStudyLanguage): number => {
  const { data } = useUserSettings()
  if (!data) {
    return DEFAULT_POSITION_IN_FREQUENCY_LIST
  }

  const position = data.preferences.exercises.frequencyList.position.byLanguage.find(
    (lang) => lang.language === studyLanguage
  )?.position

  return position ?? DEFAULT_POSITION_IN_FREQUENCY_LIST
}
