import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import clsx from 'clsx'
import { langCodeToLanguageName } from '../../../../constants/lang-code-utils.ts'
import { CircleFlagLanguage } from 'react-circle-flags'

type LanguageCardProps = {
  lang: LangCode
  handleClick: (lang: LangCode) => void
  isSelected: boolean
}

export const LanguageCard = ({ lang, handleClick, isSelected }: LanguageCardProps) => {
  return (
    <button
      className={clsx(
        'flex h-12 items-center justify-center rounded-xl border bg-white shadow focus:outline-none',
        { 'bg-gradient-to-r from-orange-300 to-yellow-300': isSelected },
        { 'hover:bg-gray-100': !isSelected }
      )}
      onClick={() => handleClick(lang)}
    >
      <div className='flex items-center'>
        <CircleFlagLanguage languageCode={lang} className={'h-5 bg-transparent'} />
        <span className={clsx('ml-2 text-xl', { 'text-gray-700': isSelected }, { 'text-gray-500': !isSelected })}>
          {langCodeToLanguageName(lang)}
        </span>
      </div>
    </button>
  )
}
