import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectIsUserInfoFromAllSourcesLoaded, selectMissingOnboardingStep } from '../../state/slices/account-slice.ts'
import { FullViewSquaresLoader } from '../loader/full-view-squares-loader.tsx'
import { ROUTE_PATHS } from '../../routing/route-paths.ts'

// this component is needed to prevent people from going back to onboarding if they have already completed it
// for example a user won't be able to paste an onboarding link into the search bar
export const AllowOnlyIfNotOnboarded = () => {
  const missingOnboardingStep: string | null = useSelector(selectMissingOnboardingStep)
  const isUserInfoFromAllSourcesLoaded = useSelector(selectIsUserInfoFromAllSourcesLoaded)

  if (!isUserInfoFromAllSourcesLoaded) {
    return <FullViewSquaresLoader />
  } else if (missingOnboardingStep) {
    return <Outlet />
  }
  return <Navigate to={ROUTE_PATHS.DASHBOARD} />
}
