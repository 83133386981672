import { Star } from 'lucide-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button } from '../../../../design-system/button.tsx'
import {
  deleteSavedWord,
  putSavedWord,
} from '../../../../../transport/transport/our-backend/api/saved-words/saved-words.ts'
import { QUERY_KEYS } from '../../../../../transport/transport/our-backend/api/query-keys.ts'
import { cn } from '../../../../../utils/shadcn-utils.ts'
import { toast } from 'sonner'
import { NavLink } from 'react-router-dom'
import { ROUTE_PATHS } from '../../../../../routing/route-paths.ts'
import { selectAccountAccessToken } from '../../../../../state/slices/account-slice.ts'
import { useSelector } from 'react-redux'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import {
  DeleteFromSavedWordsResponse,
  PutSavedWordResponse,
} from '@shared/frontend-and-backend/body-types/saved-words/saved-words.types.ts'
import { SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { POSTHOG_EVENTS } from '../../../../../analytics/posthog/posthog-events.ts'

interface AddOrDeleteFromSavedWordsButtonProps {
  language: SupportedStudyLanguage
  contextWords: string[]
  wordIndex: number
}

export const AddOrDeleteFromSavedWordsSection = ({
  language,
  contextWords,
  wordIndex,
}: AddOrDeleteFromSavedWordsButtonProps) => {
  const queryClient = useQueryClient()
  const accessToken: string = useSelector(selectAccountAccessToken)
  const word = contextWords[wordIndex]

  const { data: isSaved } = useQuery<boolean>({
    queryKey: [QUERY_KEYS.SAVED_WORDS, word, language],
    initialData: () => queryClient.getQueryData([QUERY_KEYS.SAVED_WORDS, word, language]) ?? false,
  })

  const { mutate: addSavedWord } = useMutation<ResponseWrapper<PutSavedWordResponse>>({
    mutationFn: () => putSavedWord(accessToken, language, contextWords, wordIndex),
    onMutate: async () => {
      queryClient.setQueryData<boolean>([QUERY_KEYS.SAVED_WORDS, word, language], true)
      toast.success(`'${word}' added to saved words`)
    },
    onError: () => {
      queryClient.setQueryData<boolean>([QUERY_KEYS.SAVED_WORDS, word, language], false)
      toast.error(`Failed to add '${word}' to saved words`)
    },
    onSettled: (data) => {
      const responseWrapper = data?.data
      if (data) {
        // if the user tried to save the word "The", we should put both "The" and "the" in the cache
        queryClient.setQueryData<boolean>([QUERY_KEYS.SAVED_WORDS, responseWrapper?.orthographicForm, language], true)
      }
    },
  })

  const { mutate: removeSavedWord } = useMutation<ResponseWrapper<DeleteFromSavedWordsResponse>>({
    mutationFn: () => deleteSavedWord(accessToken, language, contextWords, wordIndex),
    onMutate: async () => {
      queryClient.setQueryData<boolean>([QUERY_KEYS.SAVED_WORDS, word, language], false)
      toast.success(`'${word}' removed from saved words`)
    },
    onError: () => {
      queryClient.setQueryData<boolean>([QUERY_KEYS.SAVED_WORDS, word, language], true)
      toast.error(`Failed to remove '${word}' from saved words`)
    },
    onSettled: (data) => {
      const responseWrapper = data?.data
      if (data) {
        // if the user tried to remove the word "The", we should set both "The" and "the" as not saved
        queryClient.setQueryData<boolean>([QUERY_KEYS.SAVED_WORDS, responseWrapper?.orthographicForm, language], false)
      }
    },
  })

  const handleToggleSavedStatus = () => {
    if (isSaved) {
      POSTHOG_EVENTS.click('remove_saved_word')
      removeSavedWord()
    } else {
      POSTHOG_EVENTS.click('add_saved_word')
      addSavedWord()
    }
  }

  return (
    <div className='flex items-center gap-2'>
      <Button
        onClick={handleToggleSavedStatus}
        className='flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600 p-0'
      >
        <Star className={cn(`h-5 min-h-5 w-5 min-w-5 stroke-white ${isSaved ? 'fill-white' : 'fill-none'}`)} />
      </Button>
      <NavLink to={ROUTE_PATHS.PROGRESS_STATS_SAVED_WORDS} className='text-sm underline'>
        go to Saved Words
      </NavLink>
    </div>
  )
}
