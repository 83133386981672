import { useAudioRecorder } from '../../../hooks/use-audio-recorder.ts'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAccountAccessToken,
  selectDialectOrDefaultDialectOrEnglishDefaultDialect,
  selectHasVoice,
  selectStudyLanguageOrEnglish,
} from '../../../state/slices/account-slice.ts'
import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react'
import { AudioRecorder } from '../../audio-recorder.tsx'
import { Evaluation } from './evaluation/evaluation.tsx'
import { getGeneratedAudio } from '../../../transport/transport/our-backend/api/pronunciation/pronunciation.ts'
import { TranscriptionResponse } from '@shared/frontend-and-backend/body-types/pronunciation/transcribe.types.ts'
import { getTranscription, getWordsToInclude } from './exercise-utils.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { DialectCode, SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { PronunciationComparison } from './evaluation/organisms/pronunciation-comparison.tsx'
import {
  AlignmentData,
  GetAudioResponse,
} from '@shared/frontend-and-backend/body-types/pronunciation/get-audio.types.ts'
import { addTimeStampsOfExpectedWordsToPairs, getAllPairs } from './evaluation/utils/evaluation-utils.ts'
import { ActualWordWithConfidenceAndAlignment, WordPair, WordPairWithAlignment } from './evaluation/utils/types.ts'
import { ChevronRight, GraduationCap, RefreshCw } from 'lucide-react'
import { MediaPlayerInstance, TimeSliderInstance } from '@vidstack/react'
import { QUERY_KEYS } from '../../../transport/transport/our-backend/api/query-keys.ts'
import { exerciseSliceActions } from '../../../state/slices/exercise-slice.ts'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '../../../routing/route-paths.ts'
import { Button as DesignSystemButton } from '../../design-system/button.tsx'
import { POSTHOG_EVENTS } from '../../../analytics/posthog/posthog-events.ts'
import { useApiErrorHandler } from '../../../hooks/use-api-error-handler.ts'
import { ScoreSkeleton } from './evaluation/score-skeleton.tsx'
import { transcribeAudio } from '../../../transport/transport/our-backend/api/transcribe/transcribe.ts'
import { t } from '../../../i18n/translate'

export type ExerciseProps = {
  expectedText: string
  onTryAnotherTextClick: () => void
  children: ReactNode
  textOnTryAnotherTextButton: string
}

export const Exercise = ({
  expectedText,
  onTryAnotherTextClick,
  children,
  textOnTryAnotherTextButton,
}: ExerciseProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isRecording, isAudioRecorded, handleStartStopRecording, recordedAudioBlob, resetAudioRecorder } =
    useAudioRecorder()
  const hasVoice: boolean = useSelector(selectHasVoice)
  const accessToken: string = useSelector(selectAccountAccessToken)
  const studyLanguage: SupportedStudyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const dialect: DialectCode = useSelector(selectDialectOrDefaultDialectOrEnglishDefaultDialect)
  const generatedAudioPlayerRef = useRef<MediaPlayerInstance>(null)
  const recordedAudioPlayerRef = useRef<MediaPlayerInstance>(null)
  const timeSliderRef = useRef<TimeSliderInstance>(null)

  const {
    data: audioDataWithAlignment,
    error: audioDataWithAlignmentError,
    isFetching: isGeneratingAudio,
  }: UseQueryResult<ResponseWrapper<GetAudioResponse>> = useQuery({
    queryKey: [QUERY_KEYS.AUDIO_WITH_ALIGNMENT, hasVoice, expectedText, accessToken, studyLanguage, dialect],
    queryFn: () => getGeneratedAudio(expectedText, studyLanguage, dialect, accessToken),
    enabled: hasVoice && !!dialect,
  })

  const {
    data: evaluationData,
    error: transcribeError,
    isFetching: isFetchingEvaluation,
  }: UseQueryResult<ResponseWrapper<TranscriptionResponse>, Error> = useQuery({
    queryKey: [QUERY_KEYS.TRANSCRIPTION, accessToken, recordedAudioBlob, studyLanguage],
    queryFn: () => transcribeAudio(accessToken, studyLanguage, recordedAudioBlob as Blob),
    enabled: recordedAudioBlob !== null && hasVoice && !!studyLanguage,
    staleTime: 0,
    refetchOnWindowFocus: false,
    retry: 0,
  })

  const actualDirtyWords: ActualWordWithConfidenceAndAlignment[] = useMemo(
    () => (evaluationData?.data ? getTranscription(evaluationData?.data) : []),
    [evaluationData?.data]
  )

  const tryAnotherExercise = useCallback(() => {
    POSTHOG_EVENTS.click('try_another_exercise')
    resetAudioRecorder()
    navigate(ROUTE_PATHS.DASHBOARD)
  }, [resetAudioRecorder, onTryAnotherTextClick])

  const handleTryAnotherTextClick = useCallback(() => {
    POSTHOG_EVENTS.click('try_another_text')
    resetAudioRecorder()
    onTryAnotherTextClick()
  }, [resetAudioRecorder, onTryAnotherTextClick])

  const handleTryAgainClick = () => {
    POSTHOG_EVENTS.click('try_again')
    resetAudioRecorder()
  }

  useApiErrorHandler(
    audioDataWithAlignmentError,
    `${t('exercise.error.audioGeneration')} ${audioDataWithAlignmentError}`
  )
  useApiErrorHandler(transcribeError, `${t('exercise.error.audioTranscription')} ${transcribeError}`)

  const generatedAudio: string | null = audioDataWithAlignment?.data?.audio ?? null
  const audioAlignment: AlignmentData | null = audioDataWithAlignment?.data?.alignment ?? null
  const pairs: WordPair[] = useMemo(() => getAllPairs(expectedText, actualDirtyWords), [expectedText, actualDirtyWords])

  useEffect(() => {
    const wordsToInclude: string[] = getWordsToInclude(pairs, expectedText)
    dispatch(exerciseSliceActions.setWordsToInclude({ wordsToInclude }))
  }, [pairs, expectedText, dispatch])

  const wordPairsWithAlignment: WordPairWithAlignment[] | null = useMemo(() => {
    if (audioAlignment) {
      return addTimeStampsOfExpectedWordsToPairs(pairs, audioAlignment)
    } else {
      return null
    }
  }, [pairs, audioAlignment])

  // we cannot show evaluation properly until we have audio alignment data. On the other hand we don't want to show
  // the loader to the user if he didn'translate even record his voice yet
  const isEvaluationLoading: boolean = ((isFetchingEvaluation || isGeneratingAudio) && recordedAudioBlob) as boolean
  return (
    <div className='flex w-full flex-1 flex-col items-center justify-between gap-y-4 p-1 text-center md:p-2'>
      <div className='flex w-full flex-col items-center lg:pb-0'>
        {isEvaluationLoading && (
          <div className='flex w-full flex-col items-center gap-2 md:max-w-4xl md:gap-4 lg:max-w-6xl'>
            <ScoreSkeleton />
          </div>
        )}
        <div className='mb-4 flex w-full flex-col items-center md:max-w-4xl lg:max-w-6xl'>
          {generatedAudio &&
          wordPairsWithAlignment &&
          evaluationData &&
          !isFetchingEvaluation &&
          generatedAudioPlayerRef.current ? (
            <Evaluation
              wordPairsWithAlignment={wordPairsWithAlignment}
              generatedAudioPlayerRef={generatedAudioPlayerRef}
              timeSliderRef={timeSliderRef}
              text={expectedText}
              recordedAudioBlob={recordedAudioBlob}
            />
          ) : (
            <>{children}</>
          )}
        </div>
        {recordedAudioBlob && (
          <div className='w-full md:max-w-screen-xl'>
            <PronunciationComparison
              generatedAudio={generatedAudio}
              recordedAudioBlob={recordedAudioBlob}
              recordedAudioPlayerRef={recordedAudioPlayerRef}
              generatedAudioPlayerRef={generatedAudioPlayerRef}
              timeSliderRef={timeSliderRef}
              text={expectedText}
            />
          </div>
        )}
        {!isAudioRecorded && (
          <>
            <p className='hidden w-80 text-center text-xs text-gray-400 md:block'>
              {t('exercise.record.instructions')}
            </p>
            <AudioRecorder isRecording={isRecording} handleStartStopRecording={handleStartStopRecording} />
          </>
        )}
      </div>
      <div className='flex w-full flex-col gap-y-3 md:gap-2 lg:flex-row xl:max-w-screen-md'>
        {recordedAudioBlob && (
          <DesignSystemButton onClick={handleTryAgainClick} className='w-full border border-slate-300'>
            <RefreshCw className='mr-2 h-5' />
            <span>{t('exercise.button.tryAgain')}</span>
          </DesignSystemButton>
        )}
        <DesignSystemButton onClick={tryAnotherExercise} className='w-full border border-slate-300'>
          <GraduationCap className='mr-1 h-5' />
          <span>{t('exercise.button.anotherExercise')}</span>
        </DesignSystemButton>
        <DesignSystemButton onClick={handleTryAnotherTextClick} className='w-full border border-slate-300'>
          <ChevronRight className='mr-1 h-5' />
          <span>{textOnTryAnotherTextButton}</span>
        </DesignSystemButton>
      </div>
    </div>
  )
}
