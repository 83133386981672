import { z } from 'zod'
import { SUPPORTED_STUDY_LANGUAGES, LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'

export const DEFAULT_POSITION_IN_FREQUENCY_LIST = 5000

export const UserSettingsSchema = z.object({
  preferences: z.object({
    exercises: z.object({
      frequencyList: z.object({
        position: z.object({
          byLanguage: z
            .array(
              z.object({
                language: z.nativeEnum(LangCode),
                position: z.number().default(DEFAULT_POSITION_IN_FREQUENCY_LIST),
              })
            )
            .default(
              SUPPORTED_STUDY_LANGUAGES.map((lang) => ({
                language: lang,
                position: DEFAULT_POSITION_IN_FREQUENCY_LIST,
              }))
            ),
        }),
      }),
    }),
  }),
})

export type UserSettings = z.infer<typeof UserSettingsSchema>

export type GetUserSettingsResponse = UserSettings

export type UpdateUserSettingsRequest = UserSettings

export type UpdateUserSettingsResponse = UserSettings
