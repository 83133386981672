import { Reducer } from 'redux'
import { AppReducer } from './app-reducer'
import { AccountState } from './slices/account-slice'
import { ModalState } from './slices/modal-slice.ts'
import { ExerciseState } from './slices/exercise-slice.ts'
import { PreferencesState } from './slices/preferences-slice.ts'

export class ReducerBuilder {
  private account?: Reducer<AccountState>
  private modal?: Reducer<ModalState>
  private preferences?: Reducer<PreferencesState>
  private exercise?: Reducer<ExerciseState>

  setAccountReducer(account: Reducer<AccountState>) {
    this.account = account
    return this
  }

  setModalReducer(modal: Reducer<ModalState>) {
    this.modal = modal
    return this
  }

  setPreferencesReducer(preferences: Reducer<PreferencesState>) {
    this.preferences = preferences
    return this
  }

  setExerciseReducer(modal: Reducer<ExerciseState>) {
    this.exercise = modal
    return this
  }

  build() {
    const result: AppReducer = {}
    if (this.account) {
      result.account = this.account
    }
    if (this.modal) {
      result.modal = this.modal
    }
    if (this.exercise) {
      result.exercise = this.exercise
    }
    if (this.preferences) {
      result.preferences = this.preferences
    }
    return result
  }
}
