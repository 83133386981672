export enum QUERY_KEYS {
  TRANSCRIPTION = 'transcription',
  TRANSCRIPTION_WITH_OPEN_API = 'transcription-with-open-api',

  TRANSLATE_TEXT = 'translate-text',
  TRANSLATE_TEXT_WITH_OPEN_API = 'translate-text-with-open-api',

  IPA_TRANSCRIPTION = 'ipa-transcription',
  IPA_TRANSCRIPTION_WITH_OPEN_API = 'ipa-transcription-with-open-api',

  TRANSLITERATION = 'transliteration',
  TRANSLITERATION_WITH_OPEN_API = 'transliteration-with-open-api',

  AUDIO_WITH_ALIGNMENT = 'audio-with-alignment',
  AUDIO_INDIVIDUAL_WORD = 'audio-individual-word',
  EXERCISE_TEXT = 'exercise-text',
  EXERCISE_TEXT_WITH_OPEN_API = 'exercise-text-with-open-api',
  EXERCISE_RANDOM = 'exercise-random',
  EXERCISE_FREQUENCY_LIST = 'exercise-frequency-list',
  TRANSLATE_WORD = 'translate-word',
  GET_LEARNED_WORDS = 'get-learned-words',
  USER_WORDS = 'user-words',
  LANGUAGE_DETECTION = 'language-detection',
  SUBSCRIPTION_DETAILS = 'subscription-details',
  USER_SETTINGS = 'user-settings',
  SAVED_WORDS = 'saved-words',
  SAVED_WORDS_LIST = 'saved-words-list',
  USER_NICKNAME = 'user-nickname',
  LEADERBOARD = 'leaderboard',
}
