import { memo, useEffect } from 'react'
import { motion, stagger, useAnimate } from 'framer-motion'
import { cn } from '../../../../utils/shadcn-utils.ts'
import { selectMotherLanguageOrEnglish } from '../../../../state/slices/account-slice.ts'
import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { useSelector } from 'react-redux'

export const TextGenerateEffect = memo(({ words, className }: { words: string[]; className?: string }) => {
  const motherLanguage = useSelector(selectMotherLanguageOrEnglish)
  const [scope, animate] = useAnimate()
  useEffect(() => {
    // Thai uses spaces between phrases and sentences and not between words, so their "words" are very long
    const delay = motherLanguage === LangCode.THAI ? 2.1 : 0.7
    const duration = motherLanguage === LangCode.THAI ? 8 : 4
    animate(
      'span',
      {
        opacity: 1,
      },
      {
        duration: duration,
        delay: stagger(delay),
      }
    )
  }, [animate, scope.current])

  const renderWords = () => {
    return (
      <motion.span ref={scope} className='inline'>
        {words.map((word, idx) => {
          return (
            <motion.span key={word + idx} className='text-stone-900 opacity-0'>
              {word}{' '}
            </motion.span>
          )
        })}
      </motion.span>
    )
  }

  return <span className={cn('inline', className)}>{renderWords()}</span>
})
