import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { modalActions } from '../state/slices/modal-slice'
import { SOMETHING_WENT_WRONG_MODAL_ID } from '../components/modal/modal-ids.ts'
import { logWithRollbar } from '../analytics/rollbar/log-with-rollbar'
import { ApiError } from '../transport/transport/utils'
import { ROUTE_PATHS } from '../routing/route-paths.ts'
import { toast } from 'sonner'
import { POSTHOG_EVENTS } from '../analytics/posthog/posthog-events.ts'

export const useApiErrorHandler = (
  error: unknown,
  errorMessage: string,
  toastMessage?: string,
  rateLimitMessage?: string
) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (error) {
      const apiError = error as ApiError
      if (apiError.status === 403) {
        POSTHOG_EVENTS.showPaywallToUser()
        navigate(ROUTE_PATHS.PRICING)
      } else if (apiError.status === 429) {
        POSTHOG_EVENTS.rateLimitUser()
        if (rateLimitMessage) {
          toast.error(rateLimitMessage)
        }
      } else {
        logWithRollbar(
          `error message - ${errorMessage}, apiError.message - ${apiError.message}, apiError.status - ${apiError.status}, JSON.stringify(apiError.body) - ${JSON.stringify(apiError.body)}, JSON.stringify(apiError) - ${JSON.stringify(apiError)}`
        )
        dispatch(modalActions.openModal(SOMETHING_WENT_WRONG_MODAL_ID))
        if (toastMessage) {
          toast.error(toastMessage)
        }
      }
    }
  }, [error, errorMessage, dispatch, navigate])
}
