export const LOCAL_STORAGE_CONSTANTS = Object.freeze({
  REFERRAL: 'referral',
  SHOULD_SHOW_IPA: 'shouldShowIpa',
  SHOULD_SHOW_TRANSLITERATION: 'shouldShowTransliteration',

  FEATURE_FLAGS: {
    IS_POSTHOG_DEBUG_ENABLED: 'featureFlags.isPosthogDebugEnabled',
    IS_SIGN_IN_UP_WITH_EMAIL_ENABLED: 'featureFlags.isSignInUpWithEmailEnabled',
  },
})
