import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getLeaderboard } from '../../transport/transport/our-backend/api/learned-words/words'
import { BigCard } from '../design-system/big-card'
import { Trophy, TrendingUp } from 'lucide-react'
import { SUPPORTED_STUDY_LANGUAGES } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useState } from 'react'
import { selectAccountAccessToken } from '../../state/slices/account-slice.ts'
import {
  LanguageFilter,
  LanguageFilterValue,
} from '../progress/tabs/stats-tab/stats-subtabs/learned-words/language-filter'
import { GetLeaderboardResponse, LeaderboardEntry } from '@shared/frontend-and-backend/body-types/words/words.types'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { QUERY_KEYS } from '../../transport/transport/our-backend/api/query-keys.ts'
import { FullViewSquaresLoader } from '../loader/full-view-squares-loader.tsx'

const LeaderboardTable = ({ entries }: { entries: LeaderboardEntry[] }) => {
  const { data: nickname } = useQuery<string | null>({
    queryKey: [QUERY_KEYS.USER_NICKNAME],
  })

  const getTrophyForPosition = (position: number) => {
    switch (position) {
      case 0:
        return <Trophy className='h-5 w-5 text-yellow-500' />
      case 1:
        return <Trophy className='h-5 w-5 text-slate-400' />
      case 2:
        return <Trophy className='h-5 w-5 text-amber-700' />
      default:
        return null
    }
  }

  if (!entries || entries.length === 0) {
    return (
      <div className='py-8 text-center text-slate-400'>
        No entries yet. Be the first one to make it to the leaderboard!
      </div>
    )
  }

  return (
    <div className='space-y-3'>
      {entries.map((entry, index) => {
        const isCurrentUser = entry.nickname === nickname
        return (
          <div
            key={index}
            className={`flex items-center justify-between rounded-2xl border px-6 py-4 shadow-sm transition-colors ${isCurrentUser ? 'border-indigo-200 bg-indigo-50/50' : 'border-slate-100 bg-white'}`}
          >
            <div className='flex w-full items-center gap-4'>
              {index < 3 ? (
                <div className='min-w-[32px]'>{getTrophyForPosition(index)}</div>
              ) : (
                <span className='min-w-[32px] font-medium text-slate-400'>{index + 1}</span>
              )}
              <div className='flex flex-1 flex-row items-center'>
                <span className={`font-medium ${isCurrentUser ? 'text-indigo-700' : 'text-slate-700'}`}>
                  {entry.nickname || 'Anonymous User'}
                </span>
              </div>
              <div className='flex items-center gap-2'>
                <span className='font-semibold text-indigo-600'>{entry.numberOfLearnedWords}</span>
                <span className='text-slate-400'>words</span>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const UserPosition = ({ entries }: { entries: LeaderboardEntry[] }) => {
  const { data: nickname } = useQuery<string | null>({
    queryKey: [QUERY_KEYS.USER_NICKNAME],
  })
  const userPosition = entries.findIndex((entry) => entry.nickname === nickname)

  if (userPosition >= 0 && userPosition < 3) {
    return null
  }

  return (
    <div className='flex items-center gap-4 rounded-xl border border-slate-200 bg-white p-4 shadow-sm'>
      <div className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-lg bg-indigo-50'>
        {userPosition === -1 ? (
          <TrendingUp className='h-5 w-5 text-indigo-500' />
        ) : (
          <Trophy className='h-5 w-5 text-indigo-500' />
        )}
      </div>
      <div className='flex flex-col'>
        <span className='text-sm font-medium text-slate-600'>Your Position</span>
        <>
          {userPosition === -1 && (
            <span className='text-sm text-slate-500'>
              You're not on the leaderboard yet. Practice some exercises to start competing with others!
            </span>
          )}
          {userPosition !== 1 && (
            <div className='flex items-baseline gap-2'>
              <span className='text-lg font-bold text-slate-800'>#{userPosition + 1}</span>
              <span className='text-sm text-slate-500'>of {entries.length}</span>
            </div>
          )}
        </>
      </div>
    </div>
  )
}

export const LeaderboardView = () => {
  const accessToken = useSelector(selectAccountAccessToken)
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageFilterValue>(undefined)

  const { data, isLoading } = useQuery<ResponseWrapper<GetLeaderboardResponse>>({
    queryKey: [QUERY_KEYS.LEADERBOARD, accessToken],
    queryFn: () => getLeaderboard(accessToken),
  })

  const getEntriesForCurrentView = () => {
    if (!data?.data?.learnedWords) return []

    if (!selectedLanguage) {
      return data.data.learnedWords['allTime']
    }

    return data.data.learnedWords.byLanguage[selectedLanguage]?.['allTime'] || []
  }

  const handleLanguageChange = (value: LanguageFilterValue) => {
    setSelectedLanguage(value)
  }

  const entries = getEntriesForCurrentView()
  return (
    <div className='w-full flex-col items-center p-2 py-4 md:container lg:flex 3xl:py-12'>
      <BigCard className='flex flex-col items-start gap-6 p-6 sm:p-8'>
        <div className='flex flex-col gap-2'>
          <h1 className='text-3xl font-semibold text-gray-800'>Leaderboard</h1>
          <p className='text-slate-400'>See how you rank against other learners</p>
        </div>

        <div className='w-full space-y-3'>
          <div className='flex flex-col gap-6 sm:flex-row sm:items-center sm:justify-between'>
            <div className='w-full sm:w-48'>
              <LanguageFilter
                onLanguageSelect={handleLanguageChange}
                langCodes={[undefined, ...SUPPORTED_STUDY_LANGUAGES]}
                defaultValue={undefined}
              />
            </div>
          </div>

          {!isLoading && 0 < entries.length && <UserPosition entries={entries} />}

          {isLoading ? (
            <div className='flex justify-center py-8'>
              <FullViewSquaresLoader />
            </div>
          ) : (
            <LeaderboardTable entries={entries} />
          )}
        </div>
      </BigCard>
    </div>
  )
}
