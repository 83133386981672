import clsx from 'clsx'
import { cn } from './style-utils.ts'

export const Toggle = ({
  isToggled,
  onClick,
  size = 'sm',
}: {
  isToggled: boolean
  onClick: (isToggled: boolean) => void
  size?: 'sm' | 'lg'
}) => {
  const handleClick = () => {
    onClick(!isToggled)
  }

  const sizeClasses = {
    sm: {
      button: 'h-[18px] w-[34px]',
      toggle: 'h-[14px] w-[14px]',
      translateX: 'translate-x-4',
    },
    lg: {
      button: 'h-6 w-12',
      toggle: 'h-5 w-5',
      translateX: 'translate-x-6',
    },
  }

  const { button, toggle, translateX } = sizeClasses[size]

  return (
    <button className={cn(`relative rounded-full`, button)} onClick={handleClick}>
      <div
        className={clsx('h-full w-full rounded-full transition', {
          'bg-gray-200': !isToggled,
          'bg-indigo-500': isToggled,
        })}
      ></div>
      <div
        className={clsx(
          `absolute left-[2px] top-[2px] inline-flex ${toggle} transform items-center justify-center rounded-full bg-white shadow-sm transition-all duration-75 ease-in-out`,
          {
            'translate-x-0': !isToggled,
            [translateX]: isToggled,
          }
        )}
      />
    </button>
  )
}
