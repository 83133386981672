import { authenticationSendEmailVerification } from '../backend-api-urls.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { customFetch } from '../../../utils.ts'
import { SendEmailVerificationRequest } from '@shared/frontend-and-backend/body-types/authentication/authentication.ts'

export const sendEmailVerification = async (email: string, referral: string | null): Promise<ResponseWrapper<void>> => {
  const body: SendEmailVerificationRequest = { email, referral }
  const response: Response = await customFetch(authenticationSendEmailVerification, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const json = await response.json()
  return json
}
