import { useSelector } from 'react-redux'
import { selectStudyLanguageOrEnglish } from '../../../../state/slices/account-slice.ts'
import { useFrequencySliderPosition } from '../../../../transport/transport/our-backend/api/users/users-hooks.ts'
import { getCurrentLevel } from './atoms/cefr-level-selector-utils.ts'
import { ShareExerciseButton } from './atoms/share-exercise-button.tsx'
import { getConfig } from '../../../../config/environment-config.ts'
import { ExpectedTextTranslationButton } from './atoms/expected-text-translation-button.tsx'
import { FrequencyListExerciseSettingsContent } from './molecules/frequency-list-exercise-settings-content.tsx'
import { ExerciseControlsSettingsButton } from './molecules/exercise-controls-settings-button.tsx'

type FrequencyListExerciseControlsProps = {
  text: string
}

export const FrequencyListExerciseControls = ({ text }: FrequencyListExerciseControlsProps) => {
  const studyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const position = useFrequencySliderPosition(studyLanguage)
  const currentLevel = getCurrentLevel(position)

  return (
    <div className='mt-8 flex w-full flex-row justify-center gap-x-2 md:gap-x-4'>
      {getConfig().featureFlags.isPublicExerciseEnabled() && <ShareExerciseButton text={text} />}
      <ExpectedTextTranslationButton />
      <ExerciseControlsSettingsButton shouldShowLevel={true} currentLevel={currentLevel}>
        <FrequencyListExerciseSettingsContent />
      </ExerciseControlsSettingsButton>
    </div>
  )
}
