import { IpaToggle } from './ipa-toggle.tsx'
import { LANGUAGES_WITH_TRANSLITERATION } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { TransliterationToggle } from './transliteration-toggle.tsx'
import {
  preferencesActions,
  selectShouldShowIpa,
  selectShouldShowTransliteration,
} from '../../../../../state/slices/preferences-slice.ts'
import { useDispatch, useSelector } from 'react-redux'
import { selectStudyLanguageOrEnglish } from '../../../../../state/slices/account-slice.ts'
import { t } from '../../../../../i18n/translate'

export const ExerciseBasicSettingsContent = () => {
  const shouldShowIpa = useSelector(selectShouldShowIpa)
  const shouldShowTransliteration = useSelector(selectShouldShowTransliteration)
  const studyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const dispatch = useDispatch()

  const handleIpaClick = () => {
    dispatch(preferencesActions.setShouldShowIpa(!shouldShowIpa))
  }

  const handleTransliterationClick = () => {
    dispatch(preferencesActions.setShouldShowTransliteration(!shouldShowTransliteration))
  }

  return (
    <div className='space-y-4'>
      <h3 className='text-base font-semibold text-gray-800'>{t('exercise.settings.ipaAndTransliteration')}</h3>
      <div className='space-y-2'>
        <IpaToggle shouldShowIpa={shouldShowIpa} handleIpaClick={handleIpaClick} />
        {LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage) && (
          <TransliterationToggle
            shouldShowTransliteration={shouldShowTransliteration}
            handleTransliterationClick={handleTransliterationClick}
          />
        )}
      </div>
      <p className='text-sm text-gray-400'>{t('exercise.settings.ipaInfo')}</p>
    </div>
  )
}
