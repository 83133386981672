import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'
import { logWithRollbar } from '../analytics/rollbar/log-with-rollbar.ts'
import { getSupportedMimeType } from '../components/exercises/exercise/audio-player/audio-player-utils.ts'

export interface AudioRecorderState {
  isRecording: boolean
  isAudioRecorded: boolean
  handleStartStopRecording: () => void
  recordedAudioBlob: Blob | null
  resetAudioRecorder: () => void
}

export const useAudioRecorder = (): AudioRecorderState => {
  const [isRecording, setIsRecording] = useState<boolean>(false)
  const [isAudioRecorded, setIsAudioRecorded] = useState<boolean>(false)
  const [recordedAudioBlob, setRecordedAudioBlob] = useState<Blob | null>(null)

  const mediaRecorder = useRef<MediaRecorder | null>(null)
  const mediaStream = useRef<MediaStream | null>(null)
  const audioChunks = useRef<Blob[]>([])

  const cleanupAudio = useCallback(() => {
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => track.stop())
      mediaStream.current = null
    }
    if (mediaRecorder.current) {
      mediaRecorder.current = null
    }
    audioChunks.current = []
  }, [])

  const handleDataAvailable = useCallback((event: BlobEvent) => {
    if (event.data.size > 0) {
      audioChunks.current.push(event.data)
    }
  }, [])

  const handleRecordingStop = useCallback(() => {
    const mimeType = getSupportedMimeType()
    if (mimeType) {
      const audioBlob = new Blob(audioChunks.current, { type: mimeType })
      setRecordedAudioBlob(audioBlob)
      setIsAudioRecorded(true)
      setIsRecording(false)
      cleanupAudio()
    }
  }, [cleanupAudio])

  const handleStartStopRecording = useCallback(async () => {
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        mediaStream.current = stream

        const mimeType = getSupportedMimeType()
        if (!mimeType) {
          logWithRollbar('No supported MIME type found for audio recording')
          return
        }

        const recorder = new MediaRecorder(stream, {
          mimeType: mimeType,
        })

        recorder.addEventListener('dataavailable', handleDataAvailable)
        recorder.addEventListener('stop', handleRecordingStop)

        mediaRecorder.current = recorder
        recorder.start()
        setIsRecording(true)
      } catch (error: unknown) {
        if (error instanceof Error) {
          if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
            toast.error('Microphone access denied. Please allow microphone access in your browser settings.')
          } else if (error.name === 'NotFoundError') {
            toast.error('No microphone devices found.')
          } else {
            toast.error('Failed to access the microphone. Please check your microphone settings.')
            logWithRollbar(`Failed to access the microphone ${error.message}`)
          }
        } else {
          toast.error('An unknown error occurred')
          logWithRollbar('Unknown error occurred while accessing the microphone')
        }
      }
    } else {
      if (mediaRecorder.current) {
        mediaRecorder.current.stop()
      }
    }
  }, [isRecording, handleDataAvailable, handleRecordingStop])

  const resetAudioRecorder = useCallback(() => {
    setIsAudioRecorded(false)
    setRecordedAudioBlob(null)
  }, [])

  useEffect(() => {
    return () => {
      if (mediaRecorder.current) {
        mediaRecorder.current.removeEventListener('dataavailable', handleDataAvailable)
        mediaRecorder.current.removeEventListener('stop', handleRecordingStop)
      }
      cleanupAudio()
    }
  }, [cleanupAudio, handleDataAvailable, handleRecordingStop])

  return {
    isRecording,
    isAudioRecorded,
    handleStartStopRecording,
    recordedAudioBlob,
    resetAudioRecorder,
  }
}
