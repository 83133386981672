export const STREAK_BADGE_THRESHOLDS: number[] = [3, 5, 10, 20, 15, 30, 50, 75, 100, 150, 200, 300, 500]

export const STREAK_BADGE_TITLES: { [key: number]: string } = {
  3: 'Pronunciation Protégé',
  5: 'Lingo Loyalist',
  10: 'Dialect Devotee',
  15: 'Accent Archmage',
  20: 'Phoneme Phenomenon',
  30: 'Intonation Innovator',
  50: 'Vowel Virtuoso',
  75: 'Consonant Connoisseur',
  100: 'Diction Deity',
  150: 'Eloquence Emperor',
  200: 'Accent Ascendant',
  300: 'Pronunciation Prodigy',
  500: 'Linguistic Legend',
}

export const WORD_COUNT_BADGE_THRESHOLDS: number[] = [25, 50, 75, 100, 200, 300, 500, 750, 1000, 2000, 3000]

export const WORD_COUNT_BADGE_TITLES: { [key: number]: string } = {
  25: 'Language Dummy',
  50: 'Talkative Talent',
  75: 'Language Enthusiast',
  100: 'Accent Reductionist',
  200: 'Language Geek',
  300: 'Diction Dictator',
  500: 'Language Geek',
  750: 'Language Freak',
  1000: 'Walking Dictionary',
  2000: 'Native-like',
  3000: 'D1',
}

export const LANGUAGE_COUNT_BADGE_THRESHOLDS: number[] = [1, 2, 3, 4, 6, 10, 15]

export const LANGUAGE_COUNT_BADGE_TITLES: { [key: number]: string } = {
  1: 'Monolingual',
  2: 'Bilingual',
  3: 'Trilingual',
  4: 'Polyglot',
  6: 'Hyperpolyglot',
  10: 'Babel Breaker',
  15: 'Omniglot',
}
