import { ShareExerciseButton } from '../../../exercise/controls/atoms/share-exercise-button.tsx'
import { getConfig } from '../../../../../config/environment-config.ts'
import { ExpectedTextTranslationButton } from '../../../exercise/controls/atoms/expected-text-translation-button.tsx'
import { ExerciseControlsSettingsButton } from '../../../exercise/controls/molecules/exercise-controls-settings-button.tsx'
import { ExerciseBasicSettingsContent } from '../../../exercise/controls/atoms/exercise-basic-settings-content.tsx'

type FrequencyListExerciseControlsProps = {
  text: string
}

export const ControlsForOpenExercise = ({ text }: FrequencyListExerciseControlsProps) => {
  return (
    <div className='mt-8 flex w-full flex-row justify-center gap-x-2 md:gap-x-4'>
      {getConfig().featureFlags.isPublicExerciseEnabled() && <ShareExerciseButton text={text} />}
      <ExpectedTextTranslationButton isForOpenExercise={true} />
      <ExerciseControlsSettingsButton shouldShowLevel={false} currentLevel={null}>
        <ExerciseBasicSettingsContent />
      </ExerciseControlsSettingsButton>
    </div>
  )
}
