import { LOCAL_STORAGE_CONSTANTS } from './local-storage-constants.ts'

export const localStorageWrapper = {
  setReferral: (referral: string): void => {
    if (referral) {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.REFERRAL, referral)
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.REFERRAL)
    }
  },
  getReferral: (): string => {
    const referral: string | null = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.REFERRAL)
    if (!referral) {
      return ''
    }
    return referral
  },
  setShouldShowIpa: (shouldShowIpa: boolean): void => {
    if (shouldShowIpa) {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.SHOULD_SHOW_IPA, shouldShowIpa.toString())
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.SHOULD_SHOW_IPA)
    }
  },
  getShouldShowIpa: (): boolean => {
    const shouldShowIpa: string | null = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.SHOULD_SHOW_IPA)
    return !!shouldShowIpa
  },
  setShouldShowTransliteration: (shouldShowIpa: boolean): void => {
    if (shouldShowIpa) {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.SHOULD_SHOW_TRANSLITERATION, shouldShowIpa.toString())
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.SHOULD_SHOW_TRANSLITERATION)
    }
  },
  getShouldShowTransliteration: (): boolean => {
    const shouldShowTransliteration: string | null = localStorage.getItem(
      LOCAL_STORAGE_CONSTANTS.SHOULD_SHOW_TRANSLITERATION
    )
    return !!shouldShowTransliteration
  },
}
