import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface PreferencesState {
  areLocalStorageUserPreferencesLoaded: boolean
  shouldShowIpa: boolean
  shouldShowTransliteration: boolean
}

const initialPreferencesState: PreferencesState = {
  areLocalStorageUserPreferencesLoaded: false,
  shouldShowIpa: false,
  shouldShowTransliteration: false,
}

export type SetLocalStorageUserPreferencesPayload = {
  shouldShowIpa: boolean
  shouldShowTransliteration: boolean
}

const preferencesSlice = createSlice({
  name: 'modal',
  initialState: initialPreferencesState,
  reducers: {
    setShouldShowIpa: (state, action: PayloadAction<boolean>) => {
      state.shouldShowIpa = action.payload
    },
    setShouldShowTransliteration: (state, action: PayloadAction<boolean>) => {
      state.shouldShowTransliteration = action.payload
    },
    setLocalStorageUserPreferences: (state, { payload }: PayloadAction<SetLocalStorageUserPreferencesPayload>) => {
      state.shouldShowIpa = payload.shouldShowIpa
      state.shouldShowTransliteration = payload.shouldShowTransliteration
      state.areLocalStorageUserPreferencesLoaded = true
    },
  },
})
export const preferencesActions = preferencesSlice.actions

export const selectShouldShowIpa = (state: RootState): boolean => state.preferences.shouldShowIpa
export const selectShouldShowTransliteration = (state: RootState): boolean =>
  state.preferences.shouldShowTransliteration
export const selectAreLocalStorageUserPreferencesLoaded = (state: RootState): boolean =>
  state.preferences.areLocalStorageUserPreferencesLoaded
export default preferencesSlice.reducer
