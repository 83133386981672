import { DialectCode, LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { DictionaryKey, t } from '../i18n/translate.ts'

const langCodeToLanguageNameMap: Record<LangCode, DictionaryKey> = {
  [LangCode.ENGLISH]: 'languages.english',
  [LangCode.SPANISH]: 'languages.spanish',
  [LangCode.GERMAN]: 'languages.german',
  [LangCode.FRENCH]: 'languages.french',
  [LangCode.ITALIAN]: 'languages.italian',
  [LangCode.POLISH]: 'languages.polish',
  [LangCode.CZECH]: 'languages.czech',
  [LangCode.UKRAINIAN]: 'languages.ukrainian',
  [LangCode.RUSSIAN]: 'languages.russian',
  [LangCode.PORTUGUESE]: 'languages.portuguese',
  [LangCode.VIETNAMESE]: 'languages.vietnamese',
  [LangCode.CHINESE]: 'languages.chinese',
  [LangCode.HINDI]: 'languages.hindi',
  [LangCode.INDONESIAN]: 'languages.indonesian',
  [LangCode.MALAY]: 'languages.malay',
  [LangCode.JAPANESE]: 'languages.japanese',
  [LangCode.KOREAN]: 'languages.korean',
  [LangCode.TAMIL]: 'languages.tamil',
  [LangCode.TURKISH]: 'languages.turkish',
  [LangCode.ROMANIAN]: 'languages.romanian',
  [LangCode.SWEDISH]: 'languages.swedish',
  [LangCode.NORWEGIAN]: 'languages.norwegian',
  [LangCode.DANISH]: 'languages.danish',
  [LangCode.SLOVAK]: 'languages.slovak',
  [LangCode.DUTCH]: 'languages.dutch',
  [LangCode.THAI]: 'languages.thai',
  [LangCode.HUNGARIAN]: 'languages.hungarian',
  [LangCode.GREEK]: 'languages.greek',
  [LangCode.FINNISH]: 'languages.finnish',
  [LangCode.BULGARIAN]: 'languages.bulgarian',
  [LangCode.CATALAN]: 'languages.catalan',
}

export const langCodeToLanguageName = (langCode: LangCode): string => {
  const translationKey = langCodeToLanguageNameMap[langCode]
  return t(translationKey)
}

const langCodeToNativeLanguageNameMap: Record<LangCode, string> = {
  [LangCode.ENGLISH]: 'English',
  [LangCode.SPANISH]: 'español',
  [LangCode.GERMAN]: 'deutsch',
  [LangCode.FRENCH]: 'français',
  [LangCode.ITALIAN]: 'italiano',
  [LangCode.POLISH]: 'polski',
  [LangCode.CZECH]: 'čeština',
  [LangCode.UKRAINIAN]: 'українська',
  [LangCode.RUSSIAN]: 'русский',
  [LangCode.PORTUGUESE]: 'português',
  [LangCode.VIETNAMESE]: 'tiếng Việt',
  [LangCode.CHINESE]: '中文',
  [LangCode.HINDI]: 'हिन्दी',
  [LangCode.INDONESIAN]: 'Bahasa Indonesia',
  [LangCode.MALAY]: 'Bahasa Melayu',
  [LangCode.JAPANESE]: '日本語',
  [LangCode.KOREAN]: '한국어',
  [LangCode.TAMIL]: 'தமிழ்',
  [LangCode.TURKISH]: 'Türkçe',
  [LangCode.ROMANIAN]: 'Română',
  [LangCode.SWEDISH]: 'svenska',
  [LangCode.NORWEGIAN]: 'norsk',
  [LangCode.DANISH]: 'dansk',
  [LangCode.SLOVAK]: 'slovenčina',
  [LangCode.DUTCH]: 'Nederlands',
  [LangCode.THAI]: 'ไทย',
  [LangCode.HUNGARIAN]: 'magyar',
  [LangCode.GREEK]: 'ελληνικά',
  [LangCode.FINNISH]: 'suomi',
  [LangCode.BULGARIAN]: 'български',
  [LangCode.CATALAN]: 'català',
}

export const langCodeToNativeLanguageName = (langCode: LangCode): string => {
  return langCodeToNativeLanguageNameMap[langCode] || 'Unknown Language'
}

const langCodeToNativeLatinLanguageNameMap: Record<LangCode, string> = {
  [LangCode.ENGLISH]: 'English',
  [LangCode.SPANISH]: 'espanol',
  [LangCode.GERMAN]: 'deutsch',
  [LangCode.FRENCH]: 'francais',
  [LangCode.ITALIAN]: 'italiano',
  [LangCode.POLISH]: 'polski',
  [LangCode.CZECH]: 'cestina',
  [LangCode.UKRAINIAN]: 'ukrainska',
  [LangCode.RUSSIAN]: 'russkiy',
  [LangCode.PORTUGUESE]: 'portugues',
  [LangCode.VIETNAMESE]: 'tieng viet',
  [LangCode.CHINESE]: 'zhong wen',
  [LangCode.HINDI]: 'hindi',
  [LangCode.INDONESIAN]: 'Bahasa Indonesia',
  [LangCode.MALAY]: 'Bahasa Melayu',
  [LangCode.JAPANESE]: 'nihongo',
  [LangCode.KOREAN]: 'Hangugeo',
  [LangCode.TAMIL]: 'tamil',
  [LangCode.TURKISH]: 'turkce',
  [LangCode.ROMANIAN]: 'romana',
  [LangCode.SWEDISH]: 'svenska',
  [LangCode.NORWEGIAN]: 'norsk',
  [LangCode.DANISH]: 'dansk',
  [LangCode.SLOVAK]: 'slovencina',
  [LangCode.DUTCH]: 'Nederlands',
  [LangCode.THAI]: 'thiy',
  [LangCode.HUNGARIAN]: 'magyar',
  [LangCode.GREEK]: 'ellinika',
  [LangCode.FINNISH]: 'suomi',
  [LangCode.BULGARIAN]: 'bulgarski',
  [LangCode.CATALAN]: 'catala',
}

export const langCodeToNativeLatinLanguageName = (langCode: LangCode): string => {
  return langCodeToNativeLatinLanguageNameMap[langCode] || 'Unknown Language'
}

const dialectCodeToDialectNameMap: Record<DialectCode, DictionaryKey> = {
  [DialectCode.AMERICAN_ENGLISH]: 'dialects.americanEnglish',
  [DialectCode.BRITISH_ENGLISH]: 'dialects.britishEnglish',
  [DialectCode.AUSTRALIAN_ENGLISH]: 'dialects.australianEnglish',
  [DialectCode.SCOTTISH_ENGLISH]: 'dialects.scottishEnglish',
  [DialectCode.CASTILIAN_SPANISH]: 'dialects.castilianSpanish',
  [DialectCode.MEXICAN_SPANISH]: 'dialects.mexicanSpanish',
  [DialectCode.COLOMBIAN_SPANISH]: 'dialects.colombianSpanish',
  [DialectCode.ARGENTINIAN_SPANISH]: 'dialects.argentinianSpanish',
  [DialectCode.PERUVIAN_SPANISH]: 'dialects.peruvianSpanish',
  [DialectCode.PARISIAN_FRENCH]: 'dialects.parisianFrench',
  [DialectCode.BELGIAN_FRENCH]: 'dialects.belgianFrench',
  [DialectCode.CANADIAN_FRENCH]: 'dialects.canadianFrench',
  [DialectCode.STANDARD_GERMAN]: 'dialects.standardGerman',
  [DialectCode.STANDARD_ITALIAN]: 'dialects.standardItalian',
  [DialectCode.STANDARD_POLISH]: 'dialects.standardPolish',
  [DialectCode.BRAZILIAN_PORTUGUESE]: 'dialects.brazilianPortuguese',
  [DialectCode.EUROPEAN_PORTUGUESE]: 'dialects.europeanPortuguese',
  [DialectCode.STANDARD_RUSSIAN]: 'dialects.standardRussian',
  [DialectCode.STANDARD_UKRAINIAN]: 'dialects.standardUkrainian',
  [DialectCode.STANDARD_CZECH]: 'dialects.standardCzech',
  [DialectCode.STANDARD_DANISH]: 'dialects.standardDanish',
  [DialectCode.STANDARD_DUTCH]: 'dialects.standardDutch',
  [DialectCode.STANDARD_FINNISH]: 'dialects.standardFinnish',
  [DialectCode.STANDARD_INDONESIAN]: 'dialects.standardIndonesian',
  [DialectCode.STANDARD_MALAY]: 'dialects.standardMalay',
  [DialectCode.STANDARD_ROMANIAN]: 'dialects.standardRomanian',
  [DialectCode.STANDARD_SLOVAK]: 'dialects.standardSlovak',
  [DialectCode.STANDARD_SWEDISH]: 'dialects.standardSwedish',
  [DialectCode.STANDARD_TURKISH]: 'dialects.standardTurkish',
  [DialectCode.STANDARD_HUNGARIAN]: 'dialects.standardHungarian',
  [DialectCode.STANDARD_NORWEGIAN]: 'dialects.standardNorwegian',
}

export const dialectCodeToDialectName = (dialectCode: DialectCode): string => {
  const translationKey = dialectCodeToDialectNameMap[dialectCode]
  return t(translationKey)
}
