import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQueryClient } from '@tanstack/react-query'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { selectAccountAccessToken } from '../../../../../../state/slices/account-slice.ts'
import { useApiErrorHandler } from '../../../../../../hooks/use-api-error-handler.ts'
import { QUERY_KEYS } from '../../../../../../transport/transport/our-backend/api/query-keys.ts'
import { useLoadMoreOnScroll } from '../use-load-more-on-scroll.tsx'
import { useLocation } from 'react-router-dom'
import { POSTHOG_EVENTS } from '../../../../../../analytics/posthog/posthog-events.ts'
import { SquaresLoader } from '../../../../../loader/squares-loader.tsx'
import { SavedWordsTable } from './saved-words-table.tsx'
import { getSavedWords } from '../../../../../../transport/transport/our-backend/api/saved-words/saved-words.ts'
import { GetSavedWordsResponse } from '@shared/frontend-and-backend/body-types/saved-words/saved-words.types.ts'
import { LanguageFilter, LanguageFilterValue } from '../learned-words/language-filter.tsx'
import { SUPPORTED_STUDY_LANGUAGES } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'

export const SavedWordsSubTab = () => {
  const location = useLocation()
  const queryClient = useQueryClient()
  const accessToken = useSelector(selectAccountAccessToken)
  const [languageFilter, setLanguageFilter] = useState<LanguageFilterValue>(undefined)

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
  }: UseInfiniteQueryResult<InfiniteData<ResponseWrapper<GetSavedWordsResponse>>, Error> = useInfiniteQuery({
    queryKey: [QUERY_KEYS.SAVED_WORDS_LIST],
    queryFn: useCallback(
      async ({ pageParam }: { pageParam: string | undefined }) => {
        return await getSavedWords(accessToken, pageParam, languageFilter)
      },
      [accessToken, languageFilter]
    ),
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage.data?.nextCursor,
    refetchOnWindowFocus: 'always',
    refetchOnMount: 'always',
  })

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const { resetLoading } = useLoadMoreOnScroll({
    loadMore: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage().then(() => {})
      }
    },
    hasMore: hasNextPage,
  })

  useEffect(() => {
    const refetchData = async () => {
      await queryClient.resetQueries({ queryKey: [QUERY_KEYS.SAVED_WORDS_LIST] })
      refetch().then(() => {})
    }

    if (data?.pages && data?.pages.length > 0) {
      refetchData().then()
    }
  }, [location.pathname, queryClient, refetch])

  useEffect(() => {
    refetch().then()
  }, [languageFilter, refetch])

  useApiErrorHandler(error, `Error when retrieving words: ${error}`)

  useEffect(() => {
    if (!isFetchingNextPage) {
      resetLoading()
    }
  }, [isFetchingNextPage, resetLoading])

  const handleLanguageChange = (value: LanguageFilterValue) => {
    setLanguageFilter(value)
  }

  if (status === 'pending') {
    return (
      <div className='mt-8 flex w-full justify-center'>
        <SquaresLoader />
      </div>
    )
  }

  return (
    <div className='flex h-full w-full flex-col items-center py-4'>
      {data && (
        <div className='flex h-full w-full flex-col items-center justify-between gap-y-8 p-1 md:gap-y-32 md:p-2'>
          <div className='flex w-full flex-col gap-y-4 pb-4 md:max-w-4xl lg:max-w-6xl'>
            <div className='w-full lg:w-1/4'>
              <LanguageFilter
                onLanguageSelect={handleLanguageChange}
                langCodes={[undefined, ...SUPPORTED_STUDY_LANGUAGES]}
                defaultValue={undefined}
              />
            </div>
            <SavedWordsTable data={data} languageFilter={languageFilter} />
            {isFetching && (
              <div className='flex h-full w-full justify-center'>
                <SquaresLoader />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
