import { RefObject, useState } from 'react'
import { MediaPlayerInstance } from '@vidstack/react'
import { Button } from '../../../../design-system/button.tsx'
import { cn } from '../../../../design-system/style-utils.ts'
import { Play, Volume2 } from 'lucide-react'
import { AudioPlayer } from '../../audio-player/audio-player.tsx'
import { PLAYER_TYPE_FOR_ANALYTICS } from '../../../../../analytics/posthog/posthog-events.ts'
import { logWithRollbar } from '../../../../../analytics/rollbar/log-with-rollbar.ts'

export const PlayActualWord = ({
  recordedAudioBlob,
  startTimeInSeconds,
  endTimeInSeconds,
  actualWordPlayerRef,
}: {
  recordedAudioBlob: Blob | null
  startTimeInSeconds: number | null
  endTimeInSeconds: number | null
  actualWordPlayerRef: RefObject<MediaPlayerInstance>
}) => {
  const [playing, setPlaying] = useState(false)
  const [audioKey, setAudioKey] = useState(0)
  const [shouldPlay, setShouldPlay] = useState(false)

  const onClick = () => {
    setPlaying(true)
    setShouldPlay(true)
    setAudioKey((prevKey) => prevKey + 1)
  }

  const handleCanPlay = () => {
    if (shouldPlay && actualWordPlayerRef.current) {
      const player = actualWordPlayerRef.current

      // in practice some of the audio gets trimmed at the beginning or end. Maybe it's because:
      // - deepgram returns slightly shifted timestamps
      // - the time-update event is not triggered often enough
      // That's why we add this buffer, it's better to hear a part of the words before and after, than to risk not
      // skip parts of the actual word in question
      const ALIGNMENT_BUFFER_IN_SECONDS = 0.2

      if (startTimeInSeconds !== null) {
        player.currentTime = startTimeInSeconds - ALIGNMENT_BUFFER_IN_SECONDS
      }

      const timeUpdateHandler = () => {
        if (endTimeInSeconds !== null && endTimeInSeconds + ALIGNMENT_BUFFER_IN_SECONDS <= player.currentTime) {
          player.pause()
          player.removeEventListener('time-update', timeUpdateHandler)
          setPlaying(false)
        }
      }

      player.addEventListener('time-update', timeUpdateHandler)

      const endedHandler = () => {
        player.removeEventListener('time-update', timeUpdateHandler)
        player.removeEventListener('ended', endedHandler)
        setPlaying(false)
      }

      player.addEventListener('ended', endedHandler)

      player.play().catch((error) => {
        logWithRollbar(`Error playing audio: ${error}`)
        setPlaying(false)
      })

      setShouldPlay(false)
    }
  }

  return (
    <div className='flex items-center'>
      <div className='relative h-10 w-10'>
        <Button
          onClick={onClick}
          className={cn(
            'flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600 p-0',
            playing ? 'bg-indigo-500' : 'bg-indigo-600'
          )}
        >
          {playing ? (
            <Volume2 className='h-5 min-h-5 w-5 min-w-5 text-white' />
          ) : (
            <Play className='h-5 min-h-5 w-5 min-w-5 text-white' />
          )}
        </Button>
      </div>
      {recordedAudioBlob && (
        <AudioPlayer
          audioKey={audioKey}
          playerTypeForAnalytics={PLAYER_TYPE_FOR_ANALYTICS.ACTUAL_WORD_PRONUNCIATION}
          title={'Headless player for actual word'}
          audioSource={recordedAudioBlob}
          sourceType='blob'
          isHeadless={true}
          playerRef={actualWordPlayerRef}
          onCanPlay={handleCanPlay}
          playbackRate={0.8}
        />
      )}
    </div>
  )
}
