import { BadgeSection } from '../badges.tsx'
import { useNumberOfLearnedWords } from '../../../../../../transport/transport/our-backend/api/learned-words/words-hooks.ts'
import { createWordCountBadges } from '../badge-builders.ts'
import { BadgeData } from '../badge-card.tsx'

export const WordsBadgesSubTab = () => {
  const wordsLearned = useNumberOfLearnedWords()

  const wordCountBadges: BadgeData[] = createWordCountBadges(wordsLearned)
  return (
    <div className='flex w-full flex-col justify-center gap-x-8 gap-y-8 md:w-2/3 3xl:w-1/4'>
      <BadgeSection title='Word Count' badges={wordCountBadges} />
    </div>
  )
}
