import { DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../shadcn/dialog.tsx'
import { Button } from '../../../shadcn/button.tsx'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from '../../../../state/slices/modal-slice.ts'
import { selectMotherLanguageOrEnglish } from '../../../../state/slices/account-slice.ts'
import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'

export const AudioToShortForCloningModal = () => {
  const dispatch = useDispatch()
  const motherLanguage = useSelector(selectMotherLanguageOrEnglish)

  // Thai uses spaces between phrases and sentences and not between words, so their "words" are very long
  const minimumAudioLengthInSeconds = motherLanguage === LangCode.THAI ? 5 : 20

  return (
    <>
      <DialogContent className='w-11/12 rounded-xl bg-white p-8 sm:max-w-md'>
        <DialogHeader className='mb-5'>
          <DialogTitle>Hey!</DialogTitle>
        </DialogHeader>
        <p className='text-sm text-gray-500'>
          Your audio is too short, it has to be at least {minimumAudioLengthInSeconds} seconds long. Please record your
          voice again
        </p>
        <DialogFooter>
          <Button
            onClick={() => {
              dispatch(modalActions.closeModal())
            }}
            type='submit'
            className='bg-gray-200 shadow hover:bg-gray-300 md:w-1/3'
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </>
  )
}
