import { ExpectedTextTranslationButton } from './atoms/expected-text-translation-button.tsx'
import { ShareExerciseButton } from './atoms/share-exercise-button.tsx'
import { ExerciseControlsSettingsButton } from './molecules/exercise-controls-settings-button.tsx'
import { ExerciseBasicSettingsContent } from './atoms/exercise-basic-settings-content.tsx'

type Props = { text: string }

export const BeginnerLevelExerciseControls = ({ text }: Props) => {
  return (
    <div className='mt-8 flex w-full flex-row justify-center gap-x-2 md:gap-x-4'>
      <ShareExerciseButton text={text} />
      <ExpectedTextTranslationButton />
      <ExerciseControlsSettingsButton shouldShowLevel={false} currentLevel={null}>
        <ExerciseBasicSettingsContent />
      </ExerciseControlsSettingsButton>
    </div>
  )
}
