import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../../state/slices/account-slice.ts'
import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../transport/transport/our-backend/api/query-keys.ts'
import { FullViewSquaresLoader } from '../loader/full-view-squares-loader.tsx'
import { ROUTE_PATHS } from '../../routing/route-paths.ts'

export const RequireNicknameRoute = () => {
  const accessToken = useSelector(selectAccountAccessToken)

  const { data: nickname, isPending } = useQuery<string | null>({
    queryKey: [QUERY_KEYS.USER_NICKNAME],
    enabled: !!accessToken,
  })

  if (isPending) {
    return <FullViewSquaresLoader />
  } else if (!nickname) {
    return <Navigate to={ROUTE_PATHS.CHOOSE_NICKNAME} replace />
  }

  return <Outlet />
}
