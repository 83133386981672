import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../state/slices/account-slice'
import { checkNicknameAvailability, updateNickname } from '../transport/transport/our-backend/api/users/users'
import { QUERY_KEYS } from '../transport/transport/our-backend/api/query-keys'
import { useApiErrorHandler } from './use-api-error-handler'
import { logWithRollbar } from '../analytics/rollbar/log-with-rollbar'

export const useNicknameForm = (onSuccessCallback?: () => void) => {
  const accessToken = useSelector(selectAccountAccessToken)
  const queryClient = useQueryClient()

  const { data: currentNickname } = useQuery<string | null>({
    queryKey: [QUERY_KEYS.USER_NICKNAME],
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      nickname: '',
    },
  })

  const nickname = watch('nickname')

  useEffect(() => {
    if (currentNickname) {
      reset({ nickname: currentNickname })
    }
  }, [currentNickname, reset])

  const {
    data: nicknameAvailabilityData,
    isLoading: isCheckingNicknameAvailability,
    refetch: refetchNicknameAvailability,
    error: nicknameAvailabilityError,
  } = useQuery({
    queryKey: ['nickname-availability', nickname, accessToken, currentNickname],
    queryFn: async () => {
      if (nickname === currentNickname) {
        return {
          isAvailable: true,
          message: 'This is your current nickname',
        }
      }
      const response = await checkNicknameAvailability(accessToken, nickname)
      return response.data
    },
    enabled: false,
    retry: false,
  })

  useApiErrorHandler(nicknameAvailabilityError, 'Error when checking nickname availability')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (nickname) {
        refetchNicknameAvailability().then(() => {})
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [nickname, refetchNicknameAvailability])

  const { mutate: submitNickname, isPending: isSubmitting } = useMutation({
    mutationFn: () => updateNickname(accessToken, nickname),
    onSuccess: () => {
      queryClient.setQueryData([QUERY_KEYS.USER_NICKNAME], nickname)
      onSuccessCallback?.()
    },
    onError: (error: Error) => {
      logWithRollbar(`Failed to update nickname: ${JSON.stringify(error)}`)
      setError('nickname', {
        type: 'manual',
        message: 'Failed to update nickname. Please try again.',
      })
    },
  })

  const onSubmit = handleSubmit(() => {
    if (nickname === currentNickname) {
      onSuccessCallback?.()
      return
    }

    if (nicknameAvailabilityData?.isAvailable) {
      submitNickname()
    }
  })

  const isButtonEnabled =
    !!nickname &&
    // the button should be enabled when the backend returns an error that is not a validation error
    (!errors.nickname || errors.nickname.type === 'manual') &&
    !isCheckingNicknameAvailability &&
    (nickname === currentNickname || !!nicknameAvailabilityData?.isAvailable) &&
    !isSubmitting

  return {
    register,
    onSubmit,
    errors,
    nickname,
    currentNickname,
    isButtonEnabled,
    isSubmitting,
    nicknameAvailabilityData,
    isCheckingNicknameAvailability,
    reset,
  }
}
