import * as Sentry from '@sentry/react'
import { getConfig } from '../../config/environment-config'

export const initializeSentry = () => {
  Sentry.init({
    dsn: getConfig().sentryDsn,
    maxValueLength: 8192,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. We'll adjust it down to 0.1 later.
    replaysOnErrorSampleRate: 1.0, // 100% of sessions with errors are sampled
  })
}
