import { useDispatch, useSelector } from 'react-redux'
import { accountActions, selectAreLocalStorageUserDetailsLoaded } from '../../state/slices/account-slice.ts'
import { useEffect } from 'react'
import { Navigate, useSearchParams, useNavigate } from 'react-router-dom'
import { buildCheckOutRightAfterSignUpPath, ROUTE_PATHS } from '../../routing/route-paths.ts'
import { FullViewSquaresLoader } from '../loader/full-view-squares-loader.tsx'

// all the external links going to the frontend from the landing page are handled in this component
export const FromLanding = () => {
  const [searchParams] = useSearchParams()
  const priceId = searchParams.get('priceId')
  const partnerId = searchParams.get('partnerId')
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const areLocalStorageUserDetailsLoaded = useSelector(selectAreLocalStorageUserDetailsLoaded)

  // The referral is immediately set to the redux store here, which sets it in the local storage
  // through the local storage synchronizer.
  // If there is already a value in the redux store, it will not be overwritten.
  useEffect(() => {
    if (partnerId && areLocalStorageUserDetailsLoaded) {
      dispatch(accountActions.setReferralIfNotAlreadySet(partnerId))
    }
  }, [partnerId, areLocalStorageUserDetailsLoaded])

  // If there is a priceId in the URL, we want the user to be redirected to the checkout page
  // as soon as they sign up or sign in. The problem is that Supabase strips search params from the URL, so we need to
  // redirect the user to a new route that uses a query param in order to preserve the priceId.
  // The call to the backend is then handled in the component of this route.
  useEffect(() => {
    if (priceId && areLocalStorageUserDetailsLoaded) {
      navigate(buildCheckOutRightAfterSignUpPath(priceId))
    }
  }, [partnerId, areLocalStorageUserDetailsLoaded])

  // If there is no priceId in the url, we redirect to the home page. If the user is signed out, they
  // will be redirected to the login page because home is a protected route.
  if (!priceId && areLocalStorageUserDetailsLoaded) {
    return <Navigate to={ROUTE_PATHS.DASHBOARD} />
  } else {
    return <FullViewSquaresLoader />
  }
}
