import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { POSTHOG_EVENTS } from '../../../analytics/posthog/posthog-events.ts'
import { Button } from '../../design-system/button.tsx'
import { ROUTE_PATHS } from '../../../routing/route-paths.ts'
import { useMutation } from '@tanstack/react-query'
import { sendEmailVerification } from '../../../transport/transport/our-backend/api/authentication/authentication.ts'
import { useApiErrorHandler } from '../../../hooks/use-api-error-handler.ts'
import { Card } from '../../design-system/card.tsx'
import { TitleWithGradient } from '../../design-system/typography/title-with-gradient.tsx'
import { selectReferral } from '../../../state/slices/account-slice.ts'
import { useSelector } from 'react-redux'
import { t } from '../../../i18n/translate.ts'

export const SignInUpEmail = ({ isSignIn = true }: { isSignIn?: boolean }) => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const referral = useSelector(selectReferral)

  const currentOperation = isSignIn ? t('auth.email.signIn') : t('auth.email.signUp')

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return re.test(email)
  }

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const {
    mutate: sendVerificationEmail,
    error,
    isPending,
  } = useMutation({
    mutationFn: (email: string) => sendEmailVerification(email, referral || null),
    onSuccess: () => {
      navigate(ROUTE_PATHS.SIGN_IN_EMAIL_VERIFICATION_SENT, { state: { email } })
    },
  })

  const first4CharactersOfUsersEmail = email.substring(0, 4)
  useApiErrorHandler(
    error,
    `sendVerificationEmail error in SignInUpEmail component: first 4 chars of user's email - ${first4CharactersOfUsersEmail}, ${error}`,
    'There was an error sending the verification email. Please try again.',
    'you have sent too many verification emails. Please try again in 10 minutes'
  )

  const handleSignInUp = async () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address')
      return
    }

    POSTHOG_EVENTS.click('sign_in_up_with_email_button')
    sendVerificationEmail(email)
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    setEmailError('')
  }

  return (
    <div className='flex w-full flex-1 flex-col items-center justify-center px-2'>
      <Card className='gap-y-8'>
        <div className='text-center'>
          <TitleWithGradient>{isSignIn ? t('auth.signInEmail.title') : t('auth.signUpEmail.title')}</TitleWithGradient>
        </div>

        <div className='flex flex-col gap-y-4'>
          <div className='w-full space-y-1'>
            <input
              type='email'
              id='email'
              name='email'
              autoComplete='username email'
              placeholder={t('auth.email.placeholder')}
              value={email}
              onChange={handleEmailChange}
              className='h-12 w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500'
            />
            {emailError && <p className='h-4 text-xs text-red-500'>{t('auth.email.invalidError')}</p>}
          </div>
          <Button
            disabled={isPending}
            onClick={handleSignInUp}
            className='flex h-12 w-full items-center justify-center rounded-md border bg-gradient-to-r from-indigo-500 to-indigo-600 px-4 py-2 text-white transition-colors duration-200 hover:from-indigo-600 hover:to-indigo-700 active:from-indigo-700 active:to-indigo-800 disabled:bg-indigo-400'
          >
            <span className='font-medium'>{isPending ? t('auth.email.sendingEmail') : currentOperation}</span>
          </Button>
        </div>
        <div>
          <div className='relative'>
            <div className='absolute inset-0 flex items-center'>
              <div className='w-full border-t border-gray-300'></div>
            </div>
            <div className='relative flex justify-center'>
              <span className='bg-white px-2 text-gray-500'>{t('auth.email.or')}</span>
            </div>
          </div>

          <div className='mt-6 text-center text-gray-500'>
            {isSignIn ? t('auth.email.noAccount') : t('auth.email.haveAccount')}{' '}
            <a
              className='cursor-pointer font-medium text-indigo-600 hover:text-indigo-500'
              onClick={() => {
                navigate(isSignIn ? ROUTE_PATHS.SIGN_UP_EMAIL : ROUTE_PATHS.SIGN_IN_EMAIL)
              }}
            >
              {isSignIn ? t('auth.email.signUp') : t('auth.email.signIn')}
            </a>
          </div>
        </div>
      </Card>
    </div>
  )
}
