import { AlignmentData } from '@shared/frontend-and-backend/body-types/pronunciation/get-audio.types.ts'
import {
  ActualWordWithConfidenceAndAlignment,
  WordAndStartTime,
  WordIndicesPair,
  WordPair,
  WordPairWithAlignment,
  WordPairWithAlignmentAndIpaAndTransliteration,
  WordPairWithIpaAndTransliteration,
} from './types.ts'
import {
  getCleanWordsFromSentence,
  removePunctuationFromBeginningAndEnd,
  splitByWhiteSpace,
} from '@shared/frontend-and-backend/utils/text-utils.ts'
import { CONFIDENCE_THRESHOLDS } from '../score/constants.ts'

// todo consider passing language param too once we have more ambiguous cases
export const areWordsEqual = (word1: string, word2: string): boolean => {
  const RUSSIAN_E_WITH_DOTS = 'ё' // don't confuse with French e with dots, example: noël
  const RUSSIAN_E = 'е'
  // during wykop3 campaign a user reported an error related to this
  // more on https://www.notion.so/grammarians/Sanitize-the-apostrophes-da96822ef8324641842ea1c4c6174514?d=37075464b7ef4ab1b545f786364fa689
  const APOSTROPHE = "'"
  const WEIRD_APOSTROPHE = '’'
  return (
    removePunctuationFromBeginningAndEnd(
      word1.replace(RUSSIAN_E_WITH_DOTS, RUSSIAN_E).replace(APOSTROPHE, WEIRD_APOSTROPHE).toLowerCase()
    ) ===
    removePunctuationFromBeginningAndEnd(
      word2.replace(RUSSIAN_E_WITH_DOTS, RUSSIAN_E).replace(APOSTROPHE, WEIRD_APOSTROPHE).toLowerCase()
    )
  )
}

export const _getMatchedIndicesPairs = (words1: string[], words2: string[]): WordIndicesPair[] => {
  const dp: number[][] = Array.from({ length: words1.length + 1 }, () => Array(words2.length + 1).fill(0))
  const parent: (WordIndicesPair | null)[][] = Array.from({ length: words1.length + 1 }, () =>
    Array(words2.length + 1).fill(null)
  )

  // a dynamic programming approach
  for (let i: number = 1; i <= words1.length; i++) {
    for (let j: number = 1; j <= words2.length; j++) {
      if (areWordsEqual(words1[i - 1], words2[j - 1])) {
        dp[i][j] = dp[i - 1][j - 1] + 1
        parent[i][j] = [i - 1, j - 1]
      } else if (dp[i - 1][j] > dp[i][j - 1]) {
        dp[i][j] = dp[i - 1][j]
        parent[i][j] = [i - 1, j]
      } else {
        dp[i][j] = dp[i][j - 1]
        parent[i][j] = [i, j - 1]
      }
    }
  }

  let i: number = words1.length
  let j: number = words2.length
  const mappings: WordIndicesPair[] = []

  while (i > 0 && j > 0) {
    const [prevI, prevJ]: [number, number] = parent[i][j] as WordIndicesPair
    if (prevI !== i && prevJ !== j) {
      mappings.push([prevI, prevJ])
    }
    i = prevI
    j = prevJ
  }

  return mappings.reverse()
}

export const getAllPairs = (
  expectedDirtyWords: string,
  actualDirtyWordsWithConfidenceAndAlignments: ActualWordWithConfidenceAndAlignment[]
): WordPair[] => {
  const expectedWords = splitByWhiteSpace(expectedDirtyWords)
  const actualWords = actualDirtyWordsWithConfidenceAndAlignments.map(
    (actualWordWithConfidence) => actualWordWithConfidence.actualWord
  )
  const mappings: WordIndicesPair[] = _getMatchedIndicesPairs(expectedWords, actualWords)

  const result: WordPair[] = []
  let i: number = 0,
    j: number = 0
  for (let m: number = 0; m < mappings.length; m++) {
    const mapping: WordIndicesPair = mappings[m]
    for (; i < mapping[0] && j < mapping[1]; i++, j++) {
      result.push({
        expectedWord: expectedWords[i],
        actualWord: actualWords[j],
        actualStartTimeInSeconds: actualDirtyWordsWithConfidenceAndAlignments[j].actualStartTimeInSeconds,
        actualEndTimeInSeconds: actualDirtyWordsWithConfidenceAndAlignments[j].actualEndTimeInSeconds,
        confidence: null,
      })
    }
    for (; i < mapping[0]; i++) {
      result.push({
        expectedWord: expectedWords[i],
        actualWord: null,
        actualStartTimeInSeconds: null,
        actualEndTimeInSeconds: null,
        confidence: null,
      })
    }
    for (; j < mapping[1]; j++) {
      result.push({
        expectedWord: null,
        actualWord: actualWords[j],
        actualStartTimeInSeconds: actualDirtyWordsWithConfidenceAndAlignments[j].actualStartTimeInSeconds,
        actualEndTimeInSeconds: actualDirtyWordsWithConfidenceAndAlignments[j].actualEndTimeInSeconds,
        confidence: null,
      })
    }
    result.push({
      expectedWord: expectedWords[mapping[0]],
      actualWord: actualWords[mapping[1]],
      actualStartTimeInSeconds: actualDirtyWordsWithConfidenceAndAlignments[mapping[1]].actualStartTimeInSeconds,
      actualEndTimeInSeconds: actualDirtyWordsWithConfidenceAndAlignments[mapping[1]].actualEndTimeInSeconds,
      confidence: actualDirtyWordsWithConfidenceAndAlignments[j].confidence,
    })
    i++
    j++
  }
  for (; i < expectedWords.length && j < actualWords.length; i++, j++) {
    result.push({
      expectedWord: expectedWords[i],
      actualWord: actualWords[j],
      actualStartTimeInSeconds: actualDirtyWordsWithConfidenceAndAlignments[j].actualStartTimeInSeconds,
      actualEndTimeInSeconds: actualDirtyWordsWithConfidenceAndAlignments[j].actualEndTimeInSeconds,
      confidence: null,
    })
  }
  for (; i < expectedWords.length; i++) {
    result.push({
      expectedWord: expectedWords[i],
      actualWord: null,
      actualStartTimeInSeconds: null,
      actualEndTimeInSeconds: null,
      confidence: null,
    })
  }
  for (; j < actualWords.length; j++) {
    result.push({
      expectedWord: null,
      actualWord: actualWords[j],
      actualStartTimeInSeconds: actualDirtyWordsWithConfidenceAndAlignments[j].actualStartTimeInSeconds,
      actualEndTimeInSeconds: actualDirtyWordsWithConfidenceAndAlignments[j].actualEndTimeInSeconds,
      confidence: null,
    })
  }
  return result
}

export const getWordStartTimes = (alignmentData: AlignmentData): WordAndStartTime[] => {
  const { chars, charStartTimesMs } = alignmentData
  const text = chars.join('').toLowerCase()
  const tokens = getCleanWordsFromSentence(text).filter((word) => word.toLowerCase())

  const wordStartTimes: WordAndStartTime[] = []
  let currentIndex = 0

  for (const token of tokens) {
    const startIndex = text.indexOf(token, currentIndex)
    if (startIndex !== -1) {
      const tokenStartTime = charStartTimesMs[startIndex]
      wordStartTimes.push({ word: token, startTime: tokenStartTime })
      currentIndex = startIndex + token.length
    }
  }

  const totalDuration = charStartTimesMs[charStartTimesMs.length - 1]
  wordStartTimes.push({ word: '', startTime: totalDuration })

  return wordStartTimes
}

export const addTimeStampsOfExpectedWordsToPairs = (
  pairs: WordPair[],
  alignmentData: AlignmentData
): WordPairWithAlignment[] => {
  const result: WordPairWithAlignment[] = []
  const wordStartTimes: WordAndStartTime[] = getWordStartTimes(alignmentData)

  let wordIndex: number = 0
  for (const pair of pairs) {
    if (pair.expectedWord) {
      const { startTime } = wordStartTimes[wordIndex]
      const endTime: number = wordStartTimes[wordIndex + 1]?.startTime ?? Infinity
      result.push({
        expectedWord: pair.expectedWord,
        actualWord: pair.actualWord,
        actualStartTimeInSeconds: pair.actualStartTimeInSeconds,
        actualEndTimeInSeconds: pair.actualEndTimeInSeconds,
        expectedStartTimeInMs: startTime,
        expectedEndTimeInMs: endTime,
        confidence: pair.confidence,
      })
      wordIndex++
    } else {
      result.push({
        expectedWord: null,
        actualWord: pair.actualWord,
        actualStartTimeInSeconds: pair.actualStartTimeInSeconds,
        actualEndTimeInSeconds: pair.actualEndTimeInSeconds,
        expectedStartTimeInMs: null,
        expectedEndTimeInMs: null,
        confidence: pair.confidence,
      })
    }
  }

  return result
}

export const getEvaluationScoreInPercentage = (wordPairs: WordPair[]): number => {
  const calculateScoreForSinglePair = (pair: WordPair): number => {
    const confidence = pair.confidence ? pair.confidence * 100 : null
    if (!confidence) {
      return 0
    } else if (CONFIDENCE_THRESHOLDS.EXCELLENT_CONFIDENCE <= confidence) {
      return 1
    } else if (
      CONFIDENCE_THRESHOLDS.MEDIOCRE_CONFIDENCE <= confidence &&
      confidence < CONFIDENCE_THRESHOLDS.EXCELLENT_CONFIDENCE
    ) {
      return 0.6666666
    } else {
      return 0.3333333
    }
  }
  const totalWords: number = wordPairs.length
  const sum: number = wordPairs.reduce((acc: number, pair: WordPair) => acc + calculateScoreForSinglePair(pair), 0)
  const score: number = (sum / totalWords) * 100
  return parseFloat(score.toFixed(2))
}

export const addIpaAndTransliterationToPairsWithAlignmentThatHaveExpectedWords = (
  wordPairsWithAlignment: WordPairWithAlignment[],
  ipaWords: string[],
  transliteratedWords: string[]
): WordPairWithAlignmentAndIpaAndTransliteration[] => {
  const result: WordPairWithAlignmentAndIpaAndTransliteration[] = []
  let ipaCounter = 0
  let transliterationCounter = 0
  for (let i = 0; i < wordPairsWithAlignment.length; i++) {
    const w: WordPairWithAlignmentAndIpaAndTransliteration = wordPairsWithAlignment[i]
    if (w.expectedWord && ipaCounter < ipaWords.length) {
      w.ipa = ipaWords[ipaCounter++]
    }
    if (w.expectedWord && transliterationCounter < transliteratedWords.length) {
      w.transliteration = transliteratedWords[transliterationCounter++]
    }
    result.push(w)
  }
  return result
}

export const addIpaAndTransliterationToPairsThatHaveExpectedWords = (
  wordPairsWithAlignment: WordPair[],
  ipaWords: string[],
  transliteratedWords: string[]
): WordPairWithIpaAndTransliteration[] => {
  const result: WordPairWithIpaAndTransliteration[] = []
  let ipaCounter = 0
  let transliterationCounter = 0
  for (let i = 0; i < wordPairsWithAlignment.length; i++) {
    const w: WordPairWithIpaAndTransliteration = wordPairsWithAlignment[i]
    if (w.expectedWord && ipaCounter < ipaWords.length) {
      w.ipa = ipaWords[ipaCounter++]
    }
    if (w.expectedWord && transliterationCounter < transliteratedWords.length) {
      w.transliteration = transliteratedWords[transliterationCounter++]
    }
    result.push(w)
  }
  return result
}
