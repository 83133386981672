import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import {
  TransliterationRequest,
  TransliterationResponse,
} from '@shared/frontend-and-backend/body-types/transliteration/transliteration.types.ts'
import { customFetch } from '../../../utils.ts'
import { transliterationOpenUrl } from '../backend-open-api-urls.ts'

export const getTransliterationViaOpenApi = async (
  text: string,
  language: SupportedStudyLanguage
): Promise<ResponseWrapper<TransliterationResponse>> => {
  const body: TransliterationRequest = { text, language }
  const response: Response = await customFetch(transliterationOpenUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const json = await response.json()
  return json
}
