import { translateTextUrl, translateWordUrl } from '../backend-api-urls.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { LangCode, SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import {
  TranslateTextRequest,
  TranslateTextResponse,
  TranslateWordRequest,
  TranslateWordResponse,
} from '@shared/frontend-and-backend/body-types/translation/translation.types.ts'
import { customFetch } from '../../../utils.ts'

export const getWordTranslation = async (
  text: string,
  sourceLanguage: SupportedStudyLanguage,
  targetLanguage: LangCode,
  contextWords: string[],
  selectedWordIndex: number,
  accessToken: string
): Promise<ResponseWrapper<TranslateWordResponse>> => {
  const body: TranslateWordRequest = { text, sourceLanguage, targetLanguage, contextWords, selectedWordIndex }
  const response: Response = await customFetch(translateWordUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}

export const getTextTranslation = async (
  text: string,
  sourceLanguage: SupportedStudyLanguage,
  targetLanguage: LangCode,
  accessToken: string
): Promise<ResponseWrapper<TranslateTextResponse>> => {
  const body: TranslateTextRequest = { text, sourceLanguage, targetLanguage }
  const response: Response = await customFetch(translateTextUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}
