import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountActions,
  selectAreLocalStorageUserDetailsLoaded,
  SetLocalStorageUserDetailsPayload,
} from '../../../state/slices/account-slice.ts'
import { localStorageWrapper } from '../../../local-storage/local-storage-wrapper.ts'
import {
  preferencesActions,
  selectAreLocalStorageUserPreferencesLoaded,
  SetLocalStorageUserPreferencesPayload,
} from '../../../state/slices/preferences-slice.ts'

// usually we prefer redux as our single source of truth over the local storage.
// Except of short time when the application is first loaded, be it through refresh or a redirect from the landing page
// in the user browser. In this case, we treat local storage as the source of truth, and we load its state into redux
export const LocalStorageToStateSynchronizer = () => {
  const dispatch = useDispatch()
  const areLocalStorageUserDetailsLoaded = useSelector(selectAreLocalStorageUserDetailsLoaded)
  const areLocalStorageUserPreferencesLoaded = useSelector(selectAreLocalStorageUserPreferencesLoaded)

  useEffect(() => {
    if (!areLocalStorageUserDetailsLoaded) {
      const setLocalStorageUserDetails: SetLocalStorageUserDetailsPayload = {
        referral: localStorageWrapper.getReferral(),
      }
      dispatch(accountActions.setLocalStorageUserDetails(setLocalStorageUserDetails))
    }
  }, [areLocalStorageUserDetailsLoaded, dispatch])

  useEffect(() => {
    if (!areLocalStorageUserPreferencesLoaded) {
      const setLocalStorageUserPreferences: SetLocalStorageUserPreferencesPayload = {
        shouldShowIpa: localStorageWrapper.getShouldShowIpa(),
        shouldShowTransliteration: localStorageWrapper.getShouldShowTransliteration(),
      }
      dispatch(preferencesActions.setLocalStorageUserPreferences(setLocalStorageUserPreferences))
    }
  }, [areLocalStorageUserPreferencesLoaded, dispatch])

  return <></>
}
