import { z } from 'zod'
import { getModeName, isDevelopment, isDevelopmentForMobile, isProduction, isTest } from './environment-utils.ts'
import { environmentConfigSchema } from './environment-config-schema.ts'
import { featureFlagsLocalStorageWrapper } from '../local-storage/feature-flags-local-storage-wrapper.ts'
import { parseHashedEmails } from './environment-config-utils.ts'

type EnvironmentConfig = z.infer<typeof environmentConfigSchema>

const getProductionConfig = (): EnvironmentConfig => ({
  environmentName: 'production',
  apiHost: import.meta.env.VITE_API_HOST,
  landingPageUrl: import.meta.env.VITE_LANDING_PAGE_URL,
  supabaseProjectUrl: import.meta.env.VITE_SUPABASE_PROJECT_URL,
  supabaseProjectKey: import.meta.env.VITE_SUPABASE_PROJECT_KEY,
  rollbarPostClientItemAccessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  fullstoryOrganizationId: import.meta.env.VITE_FULLSTORY_ORGANIZATION_ID,
  amplitudeApiKey: import.meta.env.VITE_AMPLITUDE_API_KEY,
  posthogToken: import.meta.env.VITE_POSTHOG_TOKEN,
  areReduxDevToolsEnabled: true,
  stripeMonthlyPriceId: import.meta.env.VITE_MONTHLY_PRICE_ID,
  stripeYearlyPriceId: import.meta.env.VITE_YEARLY_PRICE_ID,
  stripeLifetimePriceId: import.meta.env.VITE_LIFETIME_PRICE_ID,
  shouldSkipVoiceCloningLengthCheck: false,
  shouldLogLocally: false,
  paginationLimit: 500,
  hashedEmailsOfTestUsers: parseHashedEmails(import.meta.env.VITE_HASHED_EMAILS_OF_TEST_USERS || ''),
  featureFlags: {
    isLifetimePricingEnabled: () => false,
    isPosthogDebugEnabled: () => featureFlagsLocalStorageWrapper.getIsPosthogDebugEnabledFeatureFlag(),
    isSignInUpWithEmailEnabled: () => true,
    isFreeTrialEnabled: () => true,
    isLeaderboardEnabled: () => true,
    isPublicExerciseEnabled: () => true,
  },
})

const getDevelopmentConfig = (): EnvironmentConfig => ({
  environmentName: 'development',
  apiHost: 'http://localhost:4002',
  landingPageUrl: 'http://localhost:3000',
  // shown by `supabase start` command
  supabaseProjectUrl: 'http://127.0.0.1:54321',
  // shown by `supabase start` command
  supabaseProjectKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0',
  rollbarPostClientItemAccessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  // we cannot afford having another fullstory organization id for development
  fullstoryOrganizationId: 'dummyFullstoryOrganizationId',
  amplitudeApiKey: 'aaa3f1b4782483e5eab2e7631d4def6b',
  posthogToken: '',
  areReduxDevToolsEnabled: true,
  stripeMonthlyPriceId: import.meta.env.VITE_PERSONAL_MONTHLY_PRICE_ID,
  stripeYearlyPriceId: import.meta.env.VITE_PERSONAL_YEARLY_PRICE_ID,
  stripeLifetimePriceId: import.meta.env.VITE_PERSONAL_LIFETIME_PRICE_ID,
  shouldSkipVoiceCloningLengthCheck: true,
  shouldLogLocally: true,
  paginationLimit: 30,
  hashedEmailsOfTestUsers: parseHashedEmails(import.meta.env.VITE_HASHED_EMAILS_OF_TEST_USERS || ''),
  featureFlags: {
    isLifetimePricingEnabled: () => false,
    isPosthogDebugEnabled: () => true,
    isSignInUpWithEmailEnabled: () => true,
    isFreeTrialEnabled: () => true,
    isLeaderboardEnabled: () => true,
    isPublicExerciseEnabled: () => true,
  },
})

const getDevelopmentForMobileConfig = (): EnvironmentConfig => ({
  ...getDevelopmentConfig(),
  environmentName: 'development-for-mobile',
  apiHost: import.meta.env.VITE_PERSONAL_BACKEND_URL_MOBILE,
  landingPageUrl: import.meta.env.VITE_LANDING_PAGE_URL,
  supabaseProjectUrl: import.meta.env.VITE_PERSONAL_DATABASE_URL_MOBILE,
})

const getTestConfig = (): EnvironmentConfig => ({
  environmentName: 'test',
  apiHost: 'no-host-because-it-is-a-test',
  landingPageUrl: 'some-landing-page-url',
  supabaseProjectUrl: 'dummy-supabase-project-url',
  supabaseProjectKey: 'dummy-supabase-project-key',
  rollbarPostClientItemAccessToken: 'dummyRollbarPostClientItemAccessToken',
  sentryDsn: 'dummySentryDsn',
  fullstoryOrganizationId: 'dummyFullstoryOrganizationId',
  amplitudeApiKey: 'dummyAmplitudeApiKey',
  posthogToken: '',
  areReduxDevToolsEnabled: true,
  stripeMonthlyPriceId: 'dummyStripeMonthlyPriceId',
  stripeYearlyPriceId: 'dummyStripeYearlyPriceId',
  stripeLifetimePriceId: 'dummyStripeLifetimePriceId',
  shouldSkipVoiceCloningLengthCheck: false,
  shouldLogLocally: true,
  paginationLimit: 30,
  hashedEmailsOfTestUsers: [],
  featureFlags: {
    isLifetimePricingEnabled: () => false,
    isPosthogDebugEnabled: () => false,
    isSignInUpWithEmailEnabled: () => true,
    isFreeTrialEnabled: () => true,
    isLeaderboardEnabled: () => true,
    isPublicExerciseEnabled: () => false,
  },
})

let config: EnvironmentConfig | null = null

export const getConfig = (): EnvironmentConfig => {
  if (!config) {
    if (isProduction()) {
      config = getProductionConfig()
    } else if (isDevelopment()) {
      config = getDevelopmentConfig()
    } else if (isDevelopmentForMobile()) {
      config = getDevelopmentForMobileConfig()
    } else if (isTest()) {
      config = getTestConfig()
    } else {
      throw Error(`There is no config for environment: ${getModeName()}`)
    }
  }
  return config
}
