import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectAreLocalStorageUserDetailsLoaded, selectReferral } from '../../../state/slices/account-slice.ts'
import { localStorageWrapper } from '../../../local-storage/local-storage-wrapper.ts'
import {
  selectAreLocalStorageUserPreferencesLoaded,
  selectShouldShowIpa,
  selectShouldShowTransliteration,
} from '../../../state/slices/preferences-slice.ts'

// every time the state changes, we update the local storage, as redux is the single source of truth, unless
// the application is still starting
export const StateToLocalStorageSynchronizer = () => {
  const areLocalStorageUserDetailsLoaded = useSelector(selectAreLocalStorageUserDetailsLoaded)
  const referral = useSelector(selectReferral)

  const areLocalStorageUserPreferencesLoaded = useSelector(selectAreLocalStorageUserPreferencesLoaded)
  const shouldShowIpa = useSelector(selectShouldShowIpa)
  const shouldShowTransliteration = useSelector(selectShouldShowTransliteration)

  useEffect(() => {
    if (areLocalStorageUserDetailsLoaded) {
      localStorageWrapper.setReferral(referral)
    }
  }, [areLocalStorageUserDetailsLoaded, referral])

  useEffect(() => {
    if (areLocalStorageUserPreferencesLoaded) {
      localStorageWrapper.setShouldShowIpa(shouldShowIpa)
      localStorageWrapper.setShouldShowTransliteration(shouldShowTransliteration)
    }
  }, [areLocalStorageUserPreferencesLoaded, shouldShowIpa, shouldShowTransliteration])

  return <></>
}
