import { Button } from '../../../shadcn/button.tsx'

export const RefreshButton = () => {
  return (
    <Button
      onClick={() => {
        location.reload()
      }}
      type='submit'
      className='bg-gray-200 shadow hover:bg-gray-300 md:w-1/3'
    >
      Refresh
    </Button>
  )
}
