import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { QUERY_KEYS } from '../../../../transport/transport/our-backend/api/query-keys.ts'
import { selectStudyLanguageOrEnglish } from '../../../../state/slices/account-slice.ts'
import { LangCode, SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { TranslateTextResponse } from '@shared/frontend-and-backend/body-types/translation/translation.types.ts'
import { useApiErrorHandler } from '../../../../hooks/use-api-error-handler.ts'
import { TranslateTextModalContentCommon } from './translate-text-modal-content-common.tsx'
import { getTextTranslationViaOpenApi } from '../../../../transport/transport/our-backend/open-api/translation/translation.ts'
import { getBrowserLanguageAsLangCodeOrEnglish } from '../../../../utils/language-detection-utils.ts'

export const TranslateTextForOpenExerciseModalContent = () => {
  const queryClient = useQueryClient()
  const studyLanguage: SupportedStudyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const motherLanguage: LangCode = getBrowserLanguageAsLangCodeOrEnglish()

  const { data: currentExerciseText } = useQuery<string>({
    queryKey: [QUERY_KEYS.EXERCISE_TEXT_WITH_OPEN_API],
    queryFn: () => queryClient.getQueryData<string>([QUERY_KEYS.EXERCISE_TEXT_WITH_OPEN_API]) ?? '',
    enabled: true,
  })

  const expectedText = currentExerciseText ?? ''

  const {
    data: textTranslationData,
    isFetching: isFetchingTextTranslation,
    error: textTranslationError,
  } = useQuery<ResponseWrapper<TranslateTextResponse>>({
    queryKey: [QUERY_KEYS.TRANSLATE_TEXT_WITH_OPEN_API, expectedText, motherLanguage, studyLanguage],
    queryFn: () => getTextTranslationViaOpenApi(expectedText, studyLanguage, motherLanguage),
    enabled: !!expectedText,
  })

  useApiErrorHandler(
    textTranslationError,
    `Error translating text: ${expectedText}, ${motherLanguage}, ${studyLanguage}, ${textTranslationError}`
  )

  const translatedText: string = textTranslationData?.data?.translation ?? ''

  return (
    <TranslateTextModalContentCommon
      expectedText={expectedText}
      translatedText={translatedText}
      isFetchingExerciseText={false}
      isFetchingTextTranslation={isFetchingTextTranslation}
    />
  )
}
