import {
  AddUserPronunciationsResponse,
  LearnedWordsInADay,
  WordsInLanguageCounter,
} from '@shared/frontend-and-backend/body-types/words/words.types.ts'
import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../query-keys.ts'
import { STREAK_BADGE_THRESHOLDS } from './badges-constants.tsx'
import { useMemo } from 'react'

export type UserWordsData = AddUserPronunciationsResponse

export const __calculateNumberOfLearnedWords = (data: UserWordsData | undefined): number => {
  if (!data) {
    return 0
  }
  return data.counters.reduce((acc, counter) => acc + counter.wordsPronouncedCorrectlyCount, 0)
}

export const __getWordsPronouncedCorrectlyCounters = (data: UserWordsData | undefined): WordsInLanguageCounter[] => {
  if (!data) {
    return []
  }
  return data.counters.filter((counter) => counter.wordsPronouncedCorrectlyCount > 0)
}

export const __calculateCurrentStreak = (data: UserWordsData | undefined): number => {
  if (!data || !data.learnedWordsByDay.length) {
    return 0
  }

  const sortedDays = [...data.learnedWordsByDay].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  let streak = 0
  let currentDate = new Date()

  for (const day of sortedDays) {
    const dayDate = new Date(day.date)
    const diffDays = Math.floor((currentDate.getTime() - dayDate.getTime()) / (1000 * 3600 * 24))

    if (diffDays <= 1) {
      streak++
      currentDate = dayDate
    } else {
      break
    }
  }

  return streak
}

export const __calculateTotalDaysLearned = (data: UserWordsData | undefined): number => {
  if (!data) {
    return 0
  }
  return data.learnedWordsByDay.length
}

export const __calculateLanguagesLearned = (data: UserWordsData | undefined): number => {
  if (!data) {
    return 0
  }
  return data.counters.filter((counter: WordsInLanguageCounter) => counter.wordsPronouncedCorrectlyCount > 0).length
}

export const __calculateWordsLearnedToday = (data: UserWordsData | undefined): number => {
  if (!data || !data.learnedWordsByDay.length) {
    return 0
  }
  const today = new Date().toISOString().split('T')[0]
  const todayData = data.learnedWordsByDay.find((day: LearnedWordsInADay) => day.date === today)
  return todayData ? todayData.learnedWordsCount : 0
}

export const __calculateLongestStreak = (data: UserWordsData | undefined): number => {
  if (!data || !data.learnedWordsByDay.length) {
    return 0
  }

  const sortedDays = [...data.learnedWordsByDay].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
  let longestStreak = 0
  let currentStreak = 0
  let previousDate: Date | null = null

  for (const day of sortedDays) {
    const currentDate = new Date(day.date)
    if (previousDate) {
      const diffDays = Math.floor((currentDate.getTime() - previousDate.getTime()) / (1000 * 3600 * 24))
      if (diffDays === 1) {
        currentStreak++
      } else {
        longestStreak = Math.max(longestStreak, currentStreak)
        currentStreak = 1
      }
    } else {
      currentStreak = 1
    }
    previousDate = currentDate
  }

  return Math.max(longestStreak, currentStreak)
}

export const __calculateNumberOfAchievedStreakBadges = (data: UserWordsData | undefined): number => {
  if (!data) {
    return 0
  }

  const longestStreak = __calculateLongestStreak(data)
  return STREAK_BADGE_THRESHOLDS.filter((threshold) => longestStreak >= threshold).length
}

export const __getNumberOfDaysOfNextStreakBadge = (data: UserWordsData | undefined): number => {
  if (!data) {
    return STREAK_BADGE_THRESHOLDS[0]
  }

  const longestStreak = __calculateLongestStreak(data)
  const nextThreshold = STREAK_BADGE_THRESHOLDS.find((threshold) => threshold > longestStreak)

  return nextThreshold ? nextThreshold : 0
}

const useUserWordsData = () => {
  return useQuery<UserWordsData>({
    queryKey: [QUERY_KEYS.USER_WORDS],
  })
}

export const useNumberOfLearnedWords = (): number => {
  const { data } = useUserWordsData()
  return useMemo(() => __calculateNumberOfLearnedWords(data), [data])
}

export const useGetWordsPronouncedCorrectlyCounters = (): WordsInLanguageCounter[] => {
  const { data } = useUserWordsData()
  return useMemo(() => __getWordsPronouncedCorrectlyCounters(data), [data])
}

export const useCurrentStreak = (): number => {
  const { data } = useUserWordsData()
  return useMemo(() => __calculateCurrentStreak(data), [data])
}

export const useTotalDaysLearned = (): number => {
  const { data } = useUserWordsData()
  return useMemo(() => __calculateTotalDaysLearned(data), [data])
}

export const useGetNumberOfLanguagesLearned = (): number => {
  const { data } = useUserWordsData()
  return useMemo(() => __calculateLanguagesLearned(data), [data])
}

export const useLongestStreak = (): number => {
  const { data } = useUserWordsData()
  return useMemo(() => __calculateLongestStreak(data), [data])
}

export const useNumberOfAchievedStreakBadges = (): number => {
  const { data } = useUserWordsData()
  return useMemo(() => __calculateNumberOfAchievedStreakBadges(data), [data])
}

export const useGetNumberOfDaysOfNextStreakBadge = (): number => {
  const { data } = useUserWordsData()
  return useMemo(() => __getNumberOfDaysOfNextStreakBadge(data), [data])
}

export const useWordsLearnedToday = (): number => {
  const { data } = useUserWordsData()
  return useMemo(() => __calculateWordsLearnedToday(data), [data])
}
