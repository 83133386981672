import { getConfig } from '../../config/environment-config.ts'
import {
  PlanType,
  UserPricingDetails,
} from '@shared/frontend-and-backend/body-types/payments/subscription-account.types.ts'
import {
  MONTHLY_PRICE,
  NUMBER_OF_DAYS_IN_FREE_TRIAL,
  YEARLY_PRICE,
} from '@shared/frontend-and-landing-and-backend/constants/pricing-constants.ts'

export type PlanOption = {
  label: string
  value: PlanType
  priceMessage: string
  discountMessage: string | null
  additionalMessage?: string
  billedYearly?: string
}

export type PricingViewConfig = {
  plans: PlanOption[]
  subscribeButton: {
    isDisabled: boolean
    text: string
  }
  startButton: {
    shouldBeShown: boolean
    text: string
  }
}

export const getPricingViewConfig = ({
  isPendingMutation,
  clickedPlan,
  pricingDetails,
  isFreeTrialFeatureFlagEnabled,
  canPay,
  hasReferral,
  hasFinishedOnboarding,
  currentActivePlan,
}: {
  isPendingMutation: boolean
  clickedPlan: PlanType
  pricingDetails: UserPricingDetails
  isFreeTrialFeatureFlagEnabled: boolean
  canPay: boolean
  hasReferral: boolean
  hasFinishedOnboarding: boolean
  currentActivePlan: PlanType | null
}): PricingViewConfig => {
  const planOptions: PlanOption[] = []

  const isSubscribedToFreeTrial = currentActivePlan === 'free_trial'
  const isSubscribedToMonthlyPlan = currentActivePlan === 'month'
  const isSubscribedToYearlyPlan = currentActivePlan === 'year'
  const hasIntroducedCardDetails = !canPay
  const canSubscribeWithReferralDiscount = pricingDetails?.currentlyAvailableDiscounts && canPay

  const yearlyPrice = getYearlyPrice(pricingDetails, isSubscribedToYearlyPlan)
  const monthlyPrice = getMonthlyPrice(pricingDetails, isSubscribedToMonthlyPlan)

  planOptions.push(
    {
      label: `Yearly${isSubscribedToYearlyPlan ? ' (Current)' : ''}`,
      value: 'year',
      priceMessage: `€${(yearlyPrice / 12).toFixed(2)}/month`,
      discountMessage: getYearlyDiscountString(pricingDetails, isSubscribedToYearlyPlan),
      additionalMessage: 'best value',
      billedYearly: `Billed yearly at €${yearlyPrice.toFixed(2)}`,
    },
    {
      label: `Monthly${isSubscribedToMonthlyPlan ? ' (Current)' : ''}`,
      value: 'month',
      priceMessage: `€${monthlyPrice.toFixed(2)}/month`,
      discountMessage: getMonthlyDiscountString(pricingDetails, isSubscribedToMonthlyPlan),
    }
  )

  if (!hasReferral && isFreeTrialFeatureFlagEnabled) {
    planOptions.push({
      label: `Free Trial${isSubscribedToFreeTrial ? ' (Current)' : ' (Expired)'}`,
      value: 'free_trial',
      discountMessage: null,
      priceMessage: `Free for ${NUMBER_OF_DAYS_IN_FREE_TRIAL} days`,
      additionalMessage: '',
    })
  }

  const getButtonText = () => {
    if (clickedPlan === 'free_trial') {
      if (canSubscribeWithReferralDiscount) {
        // in this case we consider the user to be on free trial according to our database model,
        // but we don't want him to know that, as we want him to introduce his card details before he gets free trial
        // more about it here
        // https://www.notion.so/grammarians/Why-are-the-plans-in-the-frontend-defined-like-this-628ff0b8fa66470f9eddecdd258ad44f?pvs=4
        return `START ${NUMBER_OF_DAYS_IN_FREE_TRIAL}-DAY FREE TRIAL`
      } else {
        if (isSubscribedToFreeTrial) {
          return 'Your current plan'
        } else {
          return 'You used your free trial'
        }
      }
    }
    if (hasIntroducedCardDetails) {
      if (isPendingMutation) {
        return 'Loading...'
      } else {
        return 'Manage Subscription'
      }
    }
    if (isPendingMutation) {
      return 'Loading...'
    }
    if (currentActivePlan === 'free_trial') {
      return `START ${NUMBER_OF_DAYS_IN_FREE_TRIAL}-DAY FREE TRIAL`
    } else {
      return 'SUBSCRIBE NOW'
    }
  }

  const shouldShowStartButton = () => {
    if (hasReferral) {
      if (isSubscribedToMonthlyPlan || isSubscribedToYearlyPlan) {
        return true
      }
    } else {
      if (
        isSubscribedToMonthlyPlan ||
        isSubscribedToYearlyPlan ||
        (isSubscribedToFreeTrial && clickedPlan === 'free_trial' && getConfig().featureFlags.isFreeTrialEnabled())
      ) {
        return true
      }
    }
    return false
  }

  return {
    plans: planOptions,
    subscribeButton: {
      isDisabled: clickedPlan === 'free_trial',
      text: getButtonText(),
    },
    startButton: {
      shouldBeShown: shouldShowStartButton(),
      text: hasFinishedOnboarding ? 'GO PRACTICE NOW' : 'START',
    },
  }
}

export const getMonthlyPrice = (pricingDetails: UserPricingDetails, isSubscribedToMonthlyPlan: boolean): number => {
  if (pricingDetails.hasSubscribedWithADiscount && isSubscribedToMonthlyPlan) {
    return MONTHLY_PRICE * (1 - pricingDetails.currentDiscountInPercentage / 100)
  }
  if (!pricingDetails.currentlyAvailableDiscounts) {
    return MONTHLY_PRICE
  }
  return ((100 - pricingDetails.currentlyAvailableDiscounts.monthly.discountAsPercentage) / 100) * MONTHLY_PRICE
}

export const getYearlyPrice = (pricingDetails: UserPricingDetails, isSubscribedToYearlyPlan: boolean): number => {
  if (pricingDetails.hasSubscribedWithADiscount && isSubscribedToYearlyPlan) {
    return YEARLY_PRICE * (1 - pricingDetails.currentDiscountInPercentage / 100)
  }
  if (!pricingDetails.currentlyAvailableDiscounts) {
    return YEARLY_PRICE
  }
  return ((100 - pricingDetails.currentlyAvailableDiscounts.yearly.discountAsPercentage) / 100) * YEARLY_PRICE
}

export const getMonthlyDiscountString = (
  pricingDetails: UserPricingDetails,
  isSubscribedToMonthlyPlan: boolean
): string | null => {
  if (pricingDetails.hasSubscribedWithADiscount && isSubscribedToMonthlyPlan) {
    return `${pricingDetails.currentDiscountInPercentage}% off thanks to your past referral!`
  }
  if (!pricingDetails.currentlyAvailableDiscounts) {
    return null
  }
  const durationLimit = pricingDetails.currentlyAvailableDiscounts.monthly.durationLimit
  const discount = pricingDetails.currentlyAvailableDiscounts.monthly.discountAsPercentage
  if (!durationLimit) {
    return `${discount}% off with your referral forever!`
  }
  const pluralSuffix = durationLimit > 1 ? 's' : ''
  return `${discount}% off with your referral for ${durationLimit} month${pluralSuffix}!`
}

export const getYearlyDiscountString = (
  pricingDetails: UserPricingDetails,
  isSubscribedToYearlyPlan: boolean
): string | null => {
  if (pricingDetails.hasSubscribedWithADiscount && isSubscribedToYearlyPlan) {
    return `${pricingDetails.currentDiscountInPercentage}% off thanks to your past referral!`
  }
  if (!pricingDetails.currentlyAvailableDiscounts) {
    return null
  }
  const durationLimit = pricingDetails.currentlyAvailableDiscounts.yearly.durationLimit
  const discount = pricingDetails.currentlyAvailableDiscounts.yearly.discountAsPercentage
  if (!durationLimit) {
    return `${discount}% off with your referral forever!`
  }

  if (durationLimit === 1) {
    return `${discount}% off with your referral for 1 year!`
  }

  return `${discount}% off with your referral for ${durationLimit} years!`
}
