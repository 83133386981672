import { DialectCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import clsx from 'clsx'
import { dialectCodeToDialectName } from '../../../../constants/lang-code-utils.ts'
import { CircleFlag } from 'react-circle-flags'

type Props = {
  dialect: DialectCode
  handleClick: (dialect: DialectCode) => void
  isSelected: boolean
}

const getCountryCode = (dialect: DialectCode): string => {
  const index = dialect.indexOf('-')
  return dialect.slice(index + 1)
}

export const DialectCard = ({ dialect, handleClick, isSelected }: Props) => {
  return (
    <button
      className={clsx(
        'flex h-12 items-center justify-center rounded-xl border bg-white shadow focus:outline-none',
        { 'bg-gradient-to-r from-orange-300 to-yellow-300': isSelected },
        { 'hover:bg-gray-100': !isSelected }
      )}
      onClick={() => handleClick(dialect)}
    >
      <div className='flex items-center'>
        <CircleFlag countryCode={getCountryCode(dialect).toLowerCase()} className={'h-5 bg-transparent'} />
        <span className={clsx('ml-2 text-xl', { 'text-gray-700': isSelected }, { 'text-gray-500': !isSelected })}>
          {dialectCodeToDialectName(dialect)}
        </span>
      </div>
    </button>
  )
}
