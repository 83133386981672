import { LocalStorageToStateSynchronizer } from './local-storage-to-state-synchronizer.tsx'
import { StateToLocalStorageSynchronizer } from './state-to-local-storage-synchronizer.tsx'

export const StateAndLocalStorageSynchronizer = () => {
  return (
    <>
      <LocalStorageToStateSynchronizer />
      <StateToLocalStorageSynchronizer />
    </>
  )
}
