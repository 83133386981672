import { useDispatch, useSelector } from 'react-redux'
import { modalActions, selectIsOpen, selectModalId } from '../../state/slices/modal-slice'
import {
  ABOUT_MODAL_ID,
  ACCOUNT_MODAL_ID,
  AUDIO_TOO_SHORT_FOR_CLONING_MODAL_ID,
  AUDIO_WITH_NOT_ENOUGH_WORDS_FOR_CLONING_MODAL_ID,
  CONTACT_MODAL_ID,
  CONTACT_US_MODAL_ID,
  DANGER_ZONE_MODAL_ID,
  PROFILE_SETTINGS_MODAL_ID,
  PROMPT_SIGN_IN_MODAL_ID,
  SOMETHING_WENT_WRONG_MODAL_ID,
  TRANSLATE_TEXT_FOR_OPEN_EXERCISE_MODAL_ID,
  TRANSLATE_TEXT_MODAL_ID,
  VOICE_REMOVAL_SUCCESS_MODAL_ID,
} from './modal-ids.ts'
import { SomethingWentWrongModalContent } from './modal-contents/something-went-wrong/something-went-wrong-modal-content.tsx'
import { ProfileSettingsModalContent } from './modal-contents/profile-settings-modal-content.tsx'
import { Dialog } from '../shadcn/dialog.tsx'
import { AudioToShortForCloningModal } from './modal-contents/audio-to-short-for-cloning/audio-to-short-for-cloning-modal.tsx'
import { AudioWithNotEnoughWordsForCloningModalId } from './modal-contents/audio-with-not-enough-words-for-cloning-modal-id.tsx'
import { ContactModalContent } from './modal-contents/contact/contact-modal-content.tsx'
import { AccountModalContent } from './modal-contents/account-modal/account-modal-content.tsx'
import { AboutModalContent } from './modal-contents/about-modal-content.tsx'
import { TranslateTextModalContent } from './modal-contents/translate-text-modals/translate-text-modal-content.tsx'
import { selectIsSignedIn } from '../../state/slices/account-slice.ts'
import { VoiceRemovalSuccessModalContent } from './modal-contents/voice-removal-success-modal-content.tsx'
import { useEffect } from 'react'
import { POSTHOG_EVENTS } from '../../analytics/posthog/posthog-events.ts'
import { DangerZoneModalContent } from './modal-contents/danger-zone-modal/danger-zone-modal-content.tsx'
import { PromptToSignInModalContent } from './modal-contents/prompt-to-sign-in-modal-content.tsx'
import { isHashEnabledModalId } from './modal-utils.ts'
import { TranslateTextForOpenExerciseModalContent } from './modal-contents/translate-text-modals/translate-text-for-open-exercise-modal-content.tsx'
import { ContactUsModalContent } from './modal-contents/contact-us-modal-content.tsx'

export const Modal = () => {
  const isSignedIn: boolean = useSelector(selectIsSignedIn)
  const isOpen: boolean = useSelector(selectIsOpen)
  const dialogId: string = useSelector(selectModalId)
  const dispatch = useDispatch()

  const modalId = useSelector(selectModalId)

  useEffect(() => {
    if (modalId && isOpen) {
      POSTHOG_EVENTS.viewModal(modalId)
    }
  }, [modalId, isOpen])

  const handleCloseModal = () => dispatch(modalActions.closeModal())
  return (
    <Dialog
      // hash enabled modals require sign in
      open={isOpen && (isSignedIn || !isHashEnabledModalId(modalId))}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          handleCloseModal()
        }
      }}
    >
      {dialogId === SOMETHING_WENT_WRONG_MODAL_ID && <SomethingWentWrongModalContent />}
      {dialogId === ACCOUNT_MODAL_ID && <AccountModalContent />}
      {dialogId === DANGER_ZONE_MODAL_ID && <DangerZoneModalContent />}
      {dialogId === PROFILE_SETTINGS_MODAL_ID && <ProfileSettingsModalContent />}
      {dialogId === ABOUT_MODAL_ID && <AboutModalContent />}
      {dialogId === CONTACT_MODAL_ID && <ContactModalContent />}
      {dialogId === AUDIO_TOO_SHORT_FOR_CLONING_MODAL_ID && <AudioToShortForCloningModal />}
      {dialogId === AUDIO_WITH_NOT_ENOUGH_WORDS_FOR_CLONING_MODAL_ID && <AudioWithNotEnoughWordsForCloningModalId />}
      {dialogId === TRANSLATE_TEXT_MODAL_ID && <TranslateTextModalContent />}
      {dialogId === TRANSLATE_TEXT_FOR_OPEN_EXERCISE_MODAL_ID && <TranslateTextForOpenExerciseModalContent />}
      {dialogId === VOICE_REMOVAL_SUCCESS_MODAL_ID && <VoiceRemovalSuccessModalContent />}
      {dialogId === PROMPT_SIGN_IN_MODAL_ID && <PromptToSignInModalContent />}
      {dialogId === CONTACT_US_MODAL_ID && <ContactUsModalContent />}
    </Dialog>
  )
}
