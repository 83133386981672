import { useState, useEffect } from 'react'
import { Checkbox } from '../shadcn/checkbox.tsx'
import { Label } from '../shadcn/label.tsx'
import { featureFlagsLocalStorageWrapper } from '../../local-storage/feature-flags-local-storage-wrapper.ts'

export const AdminSettings = () => {
  const [isPosthogDebugEnabled, setIsPosthogDebugEnabled] = useState(false)
  const [isSignInUpWithEmailEnabled, setIsSignInUpWithEmailEnabled] = useState(false)

  useEffect(() => {
    setIsPosthogDebugEnabled(featureFlagsLocalStorageWrapper.getIsPosthogDebugEnabledFeatureFlag())
    setIsSignInUpWithEmailEnabled(featureFlagsLocalStorageWrapper.getIsSignInUpWithEmailEnabledFeatureFlag())
  }, [])

  const handlePosthogDebugToggle = (checked: boolean) => {
    setIsPosthogDebugEnabled(checked)
    featureFlagsLocalStorageWrapper.setIsPosthogDebugEnabledFeatureFlag(checked)
  }

  const handleSignInUpWithEmailToggle = (checked: boolean) => {
    setIsSignInUpWithEmailEnabled(checked)
    featureFlagsLocalStorageWrapper.setIsSignInUpWithEmailEnabledFeatureFlag(checked)
  }

  return (
    <div className='flex h-full w-full flex-col items-center justify-between p-1 md:p-2'>
      <div className='w-full pb-4 md:max-w-4xl lg:max-w-6xl lg:pb-0'>
        <h1 className='mb-12 mt-12 text-center text-4xl font-bold tracking-tighter text-stone-900 md:text-7xl'>
          Admin settings
        </h1>
        <div className='mb-12 flex w-full flex-col items-center gap-y-2 px-2'>
          <div className='flex items-center space-x-2'>
            <Checkbox id='posthog-debug' checked={isPosthogDebugEnabled} onCheckedChange={handlePosthogDebugToggle} />
            <Label htmlFor='posthog-debug'>Enable Posthog Debug</Label>
          </div>
          <div className='flex items-center space-x-2'>
            <Checkbox
              id='sign-in-up-with-email'
              checked={isSignInUpWithEmailEnabled}
              onCheckedChange={handleSignInUpWithEmailToggle}
            />
            <Label htmlFor='sign-in-up-with-email'>Enable sign in with email</Label>
          </div>
        </div>
      </div>
    </div>
  )
}
