import { LangCode, SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import { POSTHOG_EVENTS } from '../../../analytics/posthog/posthog-events.ts'
import { QUERY_KEYS } from '../../../transport/transport/our-backend/api/query-keys.ts'
import { BigCard } from '../../design-system/big-card.tsx'
import { OpenExercise } from './open-exercise/open-exercise.tsx'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TextForOpenExercise } from './text-for-open-exercise.tsx'
import {
  EXERCISE_TEXT_SEARCH_PARAM_NAME,
  STUDY_LANGUAGE_SEARCH_PARAM_NAME,
  SHOULD_SCROLL_SEARCH_PARAM_NAME,
} from '@shared/frontend-and-landing-page/constants/search-params.ts'
import { ControlsForOpenExercise } from './open-exercise/controls/controls-for-open-exercise.tsx'
import { ROUTE_PATHS } from '../../../routing/route-paths.ts'

export const DemoExerciseView = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const studyLanguage = (searchParams.get(STUDY_LANGUAGE_SEARCH_PARAM_NAME) ??
    LangCode.ENGLISH) as SupportedStudyLanguage
  const exerciseText = searchParams.get(EXERCISE_TEXT_SEARCH_PARAM_NAME) ?? ''
  const shouldScroll = searchParams.get(SHOULD_SCROLL_SEARCH_PARAM_NAME) === 'true'

  const queryClient = useQueryClient()

  const bigCardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  useEffect(() => {
    if (exerciseText) {
      queryClient.setQueryData<string>([QUERY_KEYS.EXERCISE_TEXT_WITH_OPEN_API], exerciseText)
    } else {
      navigate(ROUTE_PATHS.DASHBOARD)
    }
  }, [exerciseText, queryClient])

  useEffect(() => {
    if (exerciseText) {
      queryClient.setQueryData<string>([QUERY_KEYS.EXERCISE_TEXT_WITH_OPEN_API], exerciseText)
    }
  }, [exerciseText, queryClient])

  useEffect(() => {
    if (bigCardRef.current && shouldScroll) {
      const offsetTop = bigCardRef.current.offsetTop - 8
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      })
    }
  }, [shouldScroll])

  return (
    <div className='flex w-full flex-1 flex-col items-center pt-2 md:p-8 2xl:p-16'>
      <BigCard ref={bigCardRef} className='container flex flex-1 flex-col items-center'>
        <ControlsForOpenExercise text={exerciseText} />
        <OpenExercise
          expectedText={exerciseText}
          textOnTryAnotherTextButton='Next sentence'
          studyLanguage={studyLanguage}
        >
          <TextForOpenExercise text={exerciseText} studyLanguage={studyLanguage} />
        </OpenExercise>
      </BigCard>
    </div>
  )
}
