import { z } from 'zod'

export type ContactEmailResponse = {
  message: string
}

export const formSchema = z.object({
  username: z.string().optional(),
  email: z.string().email({
    message: 'Please enter a valid email address.',
  }),
  message: z
    .string()
    .max(1000, {
      message: 'The message must be less than 1000 characters.',
    })
    .min(4, {
      message: 'Your message is too short, it must be at least 4 characters.',
    }),
})

export type ContactEmailRequest = z.infer<typeof formSchema>
