import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface ExerciseState {
  wordsToInclude: string[]
  score: number
}

const initialState: ExerciseState = {
  wordsToInclude: [],
  score: 0,
}

export type SetWordsToIncludePayload = {
  wordsToInclude: string[]
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setWordsToInclude: (state, action: PayloadAction<SetWordsToIncludePayload>) => {
      state.wordsToInclude = action.payload.wordsToInclude
    },
    setScore: (state, action: PayloadAction<SetWordsToIncludePayload>) => {
      state.wordsToInclude = action.payload.wordsToInclude
    },
  },
})

export const selectWordsToInclude = createSelector(
  [(state: RootState) => state],
  (state) => state.exercise.wordsToInclude
)

export const selectScore = createSelector([(state: RootState) => state], (state) => state.exercise.score)

export const exerciseSliceActions = accountSlice.actions

export default accountSlice.reducer
