import { type AudioSrc, MediaPlayer, MediaPlayerInstance, MediaProvider } from '@vidstack/react'
import { useEffect, useState } from 'react'
import { base64ToBlob, getSupportedMimeType } from './audio-player-utils.ts'
import { AudioPlayerProps } from './types.ts'
import '@vidstack/react/player/styles/default/theme.css'
import '@vidstack/react/player/styles/default/layouts/audio.css'
import { AudioLayout } from './audio-layout.tsx'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'
import { useMutation } from '@tanstack/react-query'
import { convertAudio } from '../../../../transport/transport/our-backend/api/pronunciation/pronunciation'
import { useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../../../../state/slices/account-slice.ts'

interface AudioPlayEvent extends CustomEvent<MediaPlayerInstance> {
  detail: MediaPlayerInstance
}

export const AudioPlayer = ({
  title,
  playerTypeForAnalytics,
  audioSource,
  sourceType,
  playerRef,
  timeSliderRef,
  audioKey,
  onCanPlay,
  playbackRate,
  isHeadless,
  fileName,
}: AudioPlayerProps) => {
  const [source, setSource] = useState<AudioSrc | null>(null)
  const accessToken = useSelector(selectAccountAccessToken)

  useEffect(() => {
    const initializeAudioSource = async () => {
      if (!audioSource) {
        setSource(null)
        return
      }
      let blob: Blob
      if (sourceType === 'base64' && typeof audioSource === 'string') {
        blob = base64ToBlob(audioSource)
      } else if (sourceType === 'blob' && audioSource instanceof Blob) {
        blob = audioSource
      } else {
        setSource(null)
        return
      }
      const url: string = URL.createObjectURL(blob)
      setSource({ src: url, type: 'audio/mpeg' })
      return () => {
        URL.revokeObjectURL(url)
      }
    }
    initializeAudioSource().then()
  }, [audioSource, sourceType, audioKey])

  useEffect(() => {
    const handleAudioPlayEvent = (event: AudioPlayEvent) => {
      if (playerRef?.current && playerRef?.current !== event.detail) {
        playerRef?.current.pause()
      }
    }

    window.addEventListener('audioPlay', handleAudioPlayEvent as EventListener)

    return () => {
      window.removeEventListener('audioPlay', handleAudioPlayEvent as EventListener)
    }
  }, [playerRef])

  const handlePlay = () => {
    POSTHOG_EVENTS.playAudio(playerTypeForAnalytics)
    window.dispatchEvent(new CustomEvent('audioPlay', { detail: playerRef?.current }))
  }

  const convertAudioMutation = useMutation({
    mutationFn: ({ audio, fromFormat, toFormat }: { audio: string; fromFormat: string; toFormat: string }) =>
      convertAudio(audio, fromFormat, toFormat, accessToken),
  })

  const handleDownload = async () => {
    if (audioSource) {
      let audioToDownload: string | Blob = audioSource
      let fileExtension = 'mp3' // Default extension

      if (audioSource instanceof Blob) {
        const supportedMimeType = getSupportedMimeType()
        const fromFormat = supportedMimeType?.split('/')[1] || 'webm'

        const base64Audio = await blobToBase64(audioSource)
        const result = await convertAudioMutation.mutateAsync({
          audio: base64Audio,
          fromFormat: fromFormat,
          toFormat: 'mp3',
        })

        if (result.data) {
          audioToDownload = result.data.convertedAudio
          fileExtension = result.data.format
        }
      } else {
        audioToDownload = audioSource
        fileExtension = 'mp3'
      }

      let blob: Blob
      if (typeof audioToDownload === 'string') {
        blob = base64ToBlob(audioToDownload)
      } else {
        blob = audioToDownload
      }

      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${fileName}.${fileExtension}`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    }
  }

  const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  if (!source) {
    return null
  }

  return (
    <>
      {isHeadless ? (
        <MediaPlayer
          title={title}
          src={source}
          ref={playerRef}
          viewType='audio'
          onPlay={handlePlay}
          onCanPlay={onCanPlay}
          className='w-0'
          playbackRate={playbackRate}
        >
          <MediaProvider />
        </MediaPlayer>
      ) : (
        <MediaPlayer
          title={title}
          src={source}
          ref={playerRef}
          viewType='audio'
          className='ring-media-focus flex w-full flex-col gap-y-0 rounded-md data-[focus]:ring-4'
          onCanPlay={onCanPlay}
          onPlay={handlePlay}
          playbackRate={playbackRate}
        >
          <MediaProvider />
          <AudioLayout timeSliderRef={timeSliderRef} title={title} onDownload={handleDownload} />
        </MediaPlayer>
      )}
    </>
  )
}
