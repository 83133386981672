import { checkoutSessionUrl } from '../backend-api-urls.ts'
import { customFetch } from '../../../utils.ts'
import {
  CreateCheckoutSessionRequest,
  CreateCheckoutSessionResponse,
} from '@shared/frontend-and-backend/body-types/payments/checkout.types.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'

export const createCheckoutSession = async (
  priceId: string,
  accessToken: string,
  successPathAndHash: string,
  cancelPathAndHash: string
): Promise<ResponseWrapper<CreateCheckoutSessionResponse>> => {
  const body: CreateCheckoutSessionRequest = { priceId, successPathAndHash, cancelPathAndHash }
  const response = await customFetch(checkoutSessionUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  return response.json()
}
