export const englishDictionary = {
  'onboarding.chooseMotherLanguage.title': 'Choose your mother language:',
  'onboarding.chooseMotherLanguage.subtitle': '(You can always change it later)',
  'onboarding.chooseMotherLanguage.searchPlaceholder': 'Type your mother language',
  'onboarding.chooseMotherLanguage.other': 'Other',
  'onboarding.chooseMotherLanguage.skip': 'Skip',
  'onboarding.chooseMotherLanguage.next': 'Next',
  'onboarding.chooseMotherLanguage.loading': 'Loading...',

  'onboarding.chooseStudyLanguage.title': 'Choose the language you want to study:',
  'onboarding.chooseStudyLanguage.subtitle': '(You can always change it later)',
  'onboarding.chooseStudyLanguage.previous': 'Previous',
  'onboarding.chooseStudyLanguage.next': 'Next',
  'onboarding.chooseStudyLanguage.loading': 'Loading...',

  'onboarding.termsAndConditions.title': 'Get ready',
  'onboarding.termsAndConditions.subtitle': 'to clone your voice',
  'onboarding.termsAndConditions.removeVoiceInfo':
    "You can always remove your voice clone later. It's not used by anyone outside the app.",
  'onboarding.termsAndConditions.step1.title': 'Step 1',
  'onboarding.termsAndConditions.step1.description':
    "In the next step, you'll read a short text aloud. For best results, ensure you're in a quiet environment. You can make mistakes, they will not affect the voice cloning.",
  'onboarding.termsAndConditions.step2.title': 'Step 2',
  'onboarding.termsAndConditions.step2.description':
    'Your voice clone will be integrated into your learning experience, tailoring exercises just for you.',
  'onboarding.termsAndConditions.consent':
    'I consent to having my voice cloned for personalized learning experiences within YourBestAccent.com, and I agree to the',
  'onboarding.termsAndConditions.termsOfService': 'Terms of Service',
  'onboarding.termsAndConditions.and': 'and',
  'onboarding.termsAndConditions.privacyPolicy': 'Privacy Policy',
  'onboarding.termsAndConditions.previous': 'Previous',
  'onboarding.termsAndConditions.next': 'Go to Voice Cloning',

  'onboarding.chooseDialect.title': 'Choose the dialect you want to study:',
  'onboarding.chooseDialect.subtitle': '(You can always change it later)',
  'onboarding.chooseDialect.previous': 'Previous',
  'onboarding.chooseDialect.next': 'Next',
  'onboarding.chooseDialect.loading': 'Loading...',

  'auth.signIn.title': 'Start mastering',
  'auth.signIn.subtitle': 'your pronunciation',
  'auth.signIn.withEmail': 'Sign in with Email',
  'auth.signIn.withGoogle': 'Sign in with Google',
  'auth.signUp.withEmail': 'Sign up with Email',
  'auth.signUp.withGoogle': 'Sign up with Google',

  'auth.signInEmail.title': 'Sign in with Email',
  'auth.signUpEmail.title': 'Sign up with Email',
  'auth.email.placeholder': 'Email address',
  'auth.email.invalidError': 'Please enter a valid email address',
  'auth.email.sendingEmail': 'Sending an email',
  'auth.email.or': 'Or',
  'auth.email.noAccount': "don't have an account?",
  'auth.email.haveAccount': 'Have an account?',
  'auth.email.signUp': 'Sign up',
  'auth.email.signIn': 'Sign in',

  'auth.emailVerify.title': 'Verify Your Email',
  'auth.emailVerify.verifying': 'Verifying...',
  'auth.emailVerify.verify': 'Verify',
  'auth.emailVerify.error.title': 'Email link is invalid or has expired',
  'auth.emailVerify.error.returnToSignIn': 'Return to Sign In',

  'auth.verificationSent.title': 'Email Verification Sent',
  'auth.verificationSent.sentTo': "We've sent a verification email to:",
  'auth.verificationSent.checkInbox': 'Please check your inbox and click on the',
  'auth.verificationSent.linkToSignIn': 'link to sign in',
  'auth.verificationSent.linkToSignUp': 'link to complete your sign up',

  'languages.english': 'English',
  'languages.spanish': 'Spanish',
  'languages.german': 'German',
  'languages.french': 'French',
  'languages.italian': 'Italian',
  'languages.polish': 'Polish',
  'languages.czech': 'Czech',
  'languages.ukrainian': 'Ukrainian',
  'languages.russian': 'Russian',
  'languages.portuguese': 'Portuguese',
  'languages.vietnamese': 'Vietnamese',
  'languages.chinese': 'Chinese',
  'languages.hindi': 'Hindi',
  'languages.indonesian': 'Indonesian',
  'languages.malay': 'Malay',
  'languages.japanese': 'Japanese',
  'languages.korean': 'Korean',
  'languages.tamil': 'Tamil',
  'languages.turkish': 'Turkish',
  'languages.romanian': 'Romanian',
  'languages.swedish': 'Swedish',
  'languages.norwegian': 'Norwegian',
  'languages.danish': 'Danish',
  'languages.slovak': 'Slovak',
  'languages.dutch': 'Dutch',
  'languages.thai': 'Thai',
  'languages.hungarian': 'Hungarian',
  'languages.greek': 'Greek',
  'languages.finnish': 'Finnish',
  'languages.bulgarian': 'Bulgarian',
  'languages.catalan': 'Catalan',

  'dialects.americanEnglish': 'American English',
  'dialects.britishEnglish': 'British English',
  'dialects.australianEnglish': 'Australian English',
  'dialects.scottishEnglish': 'Scottish English',
  'dialects.castilianSpanish': 'Castilian Spanish',
  'dialects.mexicanSpanish': 'Mexican Spanish',
  'dialects.colombianSpanish': 'Colombian Spanish',
  'dialects.argentinianSpanish': 'Argentinian Spanish',
  'dialects.peruvianSpanish': 'Peruvian Spanish',
  'dialects.parisianFrench': 'Parisian French',
  'dialects.belgianFrench': 'Belgian French',
  'dialects.canadianFrench': 'Canadian French',
  'dialects.standardGerman': 'Standard German',
  'dialects.standardItalian': 'Standard Italian',
  'dialects.standardPolish': 'Standard Polish',
  'dialects.brazilianPortuguese': 'Brazilian Portuguese',
  'dialects.europeanPortuguese': 'European Portuguese',
  'dialects.standardRussian': 'Standard Russian',
  'dialects.standardUkrainian': 'Standard Ukrainian',
  'dialects.standardCzech': 'Standard Czech',
  'dialects.standardDanish': 'Standard Danish',
  'dialects.standardDutch': 'Standard Dutch',
  'dialects.standardFinnish': 'Standard Finnish',
  'dialects.standardIndonesian': 'Standard Indonesian',
  'dialects.standardMalay': 'Standard Malay',
  'dialects.standardRomanian': 'Standard Romanian',
  'dialects.standardSlovak': 'Standard Slovak',
  'dialects.standardSwedish': 'Standard Swedish',
  'dialects.standardTurkish': 'Standard Turkish',
  'dialects.standardHungarian': 'Standard Hungarian',
  'dialects.standardNorwegian': 'Standard Norwegian',

  'dashboard.exercises.title': 'Exercises',
  'dashboard.exercises.subtitle': 'Continue where you left off or explore new exercises.',
  'dashboard.streak.title': 'Your Learning Streak',
  'dashboard.streak.subtitle': 'Explore your progress',
  'dashboard.exercises.beginner.title': 'Beginner {language}',
  'dashboard.exercises.beginner.description': 'Embark on your journey with common words and expressions',
  'dashboard.exercises.levelBased.title': 'Level-based',
  'dashboard.exercises.levelBased.description': 'Improve your pronunciation with words tailored to your language level',
  'dashboard.exercises.random.title': 'Random sentences',
  'dashboard.exercises.random.description': 'Practice with randomly selected sentences',
  'dashboard.exercises.custom.title': 'Custom text',
  'dashboard.exercises.custom.description': 'Use your own text for targeted practice',
  'dashboard.savedWords.button': 'Go to saved words',
  'dashboard.feedback.button': 'Share your feedback',
  'dashboard.mobile.exercises.title': 'Our Exercises',
  'dashboard.mobile.streak.title': 'Your Learning Streak',
  'dashboard.streak.popup.info':
    "A day counts towards your streak if you learn at least one word. A word is considered learned when it's marked in green for the first time in an exercise evaluation.",
  'dashboard.streak.current.title': '{count}-day streak',
  'dashboard.streak.current.subtitle': '{count} days left to achieve {target}-day streak badge!',
  'dashboard.streak.longest.title': '{count}-day streak',
  'dashboard.streak.longest.subtitle': 'Your Longest Streak',

  'exercise.record.instructions':
    "Click the record button and read the above text aloud. When you're done, click stop and we'll analyze your pronunciation.",
  'exercise.button.tryAgain': 'Try again',
  'exercise.button.anotherExercise': 'Try another exercise',
  'exercise.button.nextSentence': 'Next sentence',
  'exercise.button.anotherText': 'Try another text',
  'exercise.button.differentText': 'Try a different text',
  'exercise.error.audioGeneration': 'Error generating audio in exercise component:',
  'exercise.error.audioTranscription': 'Error transcribing audio in exercise component:',

  'exercise.controls.translate': 'Translate',
  'exercise.controls.share': 'Share',
  'exercise.controls.settings': 'Settings',
  'exercise.controls.searchLanguage': 'Search language...',
  'exercise.controls.noLanguageFound': 'No language found.',

  'exercise.custom.startPracticing': 'Start practicing',
  'exercise.custom.title': 'Enter text to practice',
  'exercise.custom.languageSelect.help':
    "We will try to detect the language of your text automatically but it's better if you select it manually.",
  'exercise.custom.languageSelect.switchTo': 'switch to:',
  'exercise.custom.textInput.placeholder':
    'Type your text to practice here. Example: "Betty Botter bought some butter but she said the butter\'s bitter."',
  'exercise.custom.error.tooShort': 'Text to practice must be at least 2 characters long',
  'exercise.custom.error.tooLong': 'Text to practice must be shorter than 750 characters',
  'exercise.custom.success.languageUpdate': 'Study language and dialect updated successfully',
  'exercise.custom.error.languageUpdate': 'Failed to update study language',

  'exercise.skeleton.loading': 'Getting your exercise ready...',

  'exercise.cefr.title': 'CEFR Level',
  'exercise.cefr.info':
    'The Common European Framework of Reference (CEFR) defines language proficiency across six levels: A1 and A2 (Basic User), B1 and B2 (Independent User), C1 and C2 (Proficient User). Each level represents increasing language ability, from beginner to near-native fluency. These standardized levels help learners, teachers, and employers understand and compare language skills internationally.',
  'exercise.cefr.position': 'Position:',
  'exercise.cefr.beginner': 'Beginner',
  'exercise.cefr.advanced': 'Advanced',
  'exercise.cefr.frequency.info':
    'We will generate sentences with words at around this position in the frequency list. A frequency list shows which words are used most often in a language. The lower the position of the word in a frequency list the more often it appears in the language. Words at a higher position are less frequent which very often means they are more difficult to learn.',

  'exercise.settings.ipaAndTransliteration': 'IPA and transliteration',
  'exercise.settings.ipaInfo':
    'International phonetic alphabet (IPA) and transliteration will appear above the study words',

  'exercise.settings.ipa.title': 'IPA',
  'exercise.settings.ipa.info':
    'IPA (International Phonetic Alphabet) transcription is an experimental feature and might contain errors.',

  'translate.modal.originalText': 'Original Text',
  'translate.modal.translation': 'Translation',

  'exercise.settings.title': 'Exercise Settings',
  'exercise.settings.level': 'Level:',

  'navbar.contactUs': 'Contact Us',
} as const
