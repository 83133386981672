import { configureStore } from '@reduxjs/toolkit'
import { ReducerBuilder } from './reducer-builder'
import { getConfig } from '../config/environment-config.ts'
import accountSlice from './slices/account-slice.ts'
import { AppReducer } from './app-reducer.ts'
import modalSlice from './slices/modal-slice.ts'
import exerciseSlice from './slices/exercise-slice.ts'
import preferencesSlice from './slices/preferences-slice.ts'

const reducerBuilder = new ReducerBuilder()
reducerBuilder.setAccountReducer(accountSlice)
reducerBuilder.setModalReducer(modalSlice)
reducerBuilder.setPreferencesReducer(preferencesSlice)
reducerBuilder.setExerciseReducer(exerciseSlice)
const appReducer: AppReducer = reducerBuilder.build()

export const createStore = (reducer: AppReducer) =>
  configureStore({
    reducer: reducer,
    devTools: getConfig().areReduxDevToolsEnabled,
  })

export const store = createStore(appReducer)

export type RootState = ReturnType<typeof store.getState>
