import { logWithRollbar } from './analytics/rollbar/log-with-rollbar'

window.addEventListener('vite:preloadError', (event) => {
  // https://vite.dev/guide/build#load-error-handling
  // This event listener is needed to fix dynamic import errors caused by clients not having the latest version of the frontend as described in this ticket:
  // https://www.notion.so/grammarians/TypeError-Failed-to-fetch-dynamically-imported-module-122168e7b01a809f9230dc584daefc11?pvs=4
  logWithRollbar(`vite:preloadError: ${event.detail}`, 'debug')
  window.location.reload()
})

import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './app'
import './index.css'

ReactDOM.createRoot(document.getElementById('root')).render(<App />)
