import { BadgeSection } from '../badges.tsx'
import {
  useCurrentStreak,
  useGetNumberOfLanguagesLearned,
  useLongestStreak,
  useNumberOfLearnedWords,
} from '../../../../../../transport/transport/our-backend/api/learned-words/words-hooks.ts'
import { createLanguageCountBadges, createStreakBadges, createWordCountBadges } from '../badge-builders.ts'

export const AllBadgesSubTab = () => {
  const currentStreak = useCurrentStreak()
  const longestStreak = useLongestStreak()
  const wordsLearned = useNumberOfLearnedWords()
  const languagesLearned = useGetNumberOfLanguagesLearned()

  const streakBadges = createStreakBadges(currentStreak, longestStreak)
  const wordCountBadges = createWordCountBadges(wordsLearned)
  const multiLanguageBadges = createLanguageCountBadges(languagesLearned)

  return (
    <div className='flex w-full flex-col justify-center gap-x-8 gap-y-8 xl:container md:flex-row'>
      <BadgeSection title='Streak' badges={streakBadges} />
      <BadgeSection title='Word Count' badges={wordCountBadges} />
      <BadgeSection title='Multi-Language' badges={multiLanguageBadges} />
    </div>
  )
}
