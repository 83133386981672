import { BadgeSection } from '../badges.tsx'
import {
  useCurrentStreak,
  useLongestStreak,
} from '../../../../../../transport/transport/our-backend/api/learned-words/words-hooks.ts'
import { createStreakBadges } from '../badge-builders.ts'
import { BadgeData } from '../badge-card.tsx'

export const StreakBadgesSubTab = () => {
  const currentStreak = useCurrentStreak()
  const longestStreak = useLongestStreak()

  const streakBadges: BadgeData[] = createStreakBadges(currentStreak, longestStreak)

  return (
    <div className='flex w-full flex-col justify-center gap-x-8 gap-y-8 md:w-2/3 3xl:w-1/4'>
      <BadgeSection title='Streak' badges={streakBadges} />
    </div>
  )
}
